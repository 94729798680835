<template lang="pug">
  #viewReportScheduler.h-100.d-flex.flex-column(v-if="dataLoaded") 
      div.mb-1.d-none.d-lg-flex.px-1.align-items-end.w-100(v-if="!switchIsValidation")
        b-form-group.flex-fill.mr-1.align-items-end(label-for="dataFormInitializeDateRange" label="Initialiser une date ou une période")
          DateRanger(id="dataFormInitializeDateRange" @setDates="setDateRangeArray" @select="dateSelecting" :strictMode="true" :maxDays="maxDays" placeholder="Saisie par période" :dateRangeDefault="dataFormInitialize.dateRange" v-model="dataFormInitialize.dateRange" :showLastMonthButton="false" :showThisWeekButton="false" :showThisMonthButton="false" :showTodayButton="false" :showThisYearButton="false" :showLastYearButton="false" :showFromStartButton="false")
        .d-flex.align-items-end(label-for="dataFormInitializeDateRange")
          .mb-50.mr-1
            ejs-checkbox(label='Récupérer les plannings' v-model="dataFormInitialize.retrieveSchedule")
        b-form-group.flex-fill.mr-1.align-items-end(v-if="isReportsByCollaborators" label-for="dataFormInitializeCollaboratorIds" label="Pour les collaborateurs")
          ejs-multiselect(id="dataFormInitializeCollaboratorIds" name="Collaborateurs" cssClass="e-fixe-height" :dataSource='collaboratorsListFiltered' :closePopupOnSelect="false" :fields="{text:'displayLabel',value:'id'}" placeholder="Sélectionner vos collaborateurs" v-model="dataFormInitialize.collaboratorIds" sortOrder="Ascending" :allowFiltering="allowFiltering" filterType='Contains' required="")
        b-form-group.flex-fill.mr-1.align-items-end(label-for="dataFormInitializePayrollEventId" label="Avec l'évènement")
          ejs-dropdownlist(id="dataFormInitializePayrollEventId" name="Évènement de paie" :enabled="!dataFormInitialize.retrieveSchedule" :dataSource='payrollEventsList' :fields="{text:'label',value:'id'}" :showClearButton="true"  placeholder='Sélectionner un évènement' v-model="dataFormInitialize.payrollEventId" sortOrder="Ascending" filterType='Contains')
        b-form-group.flex-fill.mr-1.align-items-end(label-for="dataFormInitializeAffairId" label="Avec l'affaire")
          ejs-multiselect(id="dataFormInitializeAffairId" :enabled="!dataFormInitialize.retrieveSchedule && (!dataFormInitialize.payrollEventId || isPayrollEventValued)" name="Affaire" :dataSource='affairsList' :fields="{text:'displayLabel',value:'id'}" :showClearButton="true" :allowFiltering="allowFiltering"  placeholder='Sélectionner une affaire' v-model="dataFormInitialize.affairIds" sortOrder="Ascending" filterType='Contains')
        b-form-group.flex-fill.mr-1.align-items-end(label-for="dataFormInitializeMissionId" label="Avec la tâche")
          ejs-dropdownlist(id="dataFormInitializeMissionId" :enabled="!dataFormInitialize.retrieveSchedule && (!dataFormInitialize.payrollEventId || isPayrollEventValued)" name="Tâche" :dataSource='missionsList' :fields="{text:'label',value:'id'}" :showClearButton="true"  placeholder='Sélectionner une tâche' v-model="dataFormInitialize.missionId" sortOrder="Ascending" filterType='Contains')
        .e-btn-group(:class="groupActionList.length > 1 ? 'e-lib e-css' : 'e-css'")
          ejs-button(:key="groupButtonIndex" v-for="(groupButton, groupButtonIndex) in groupActionList" v-on:click.native="groupButton.value ? actionInitialize(groupButton.value) : ''" :content="groupButton.text" :iconCss="groupButton.iconCss" :cssClass="groupButton.css" locale='fr' :spinSettings="{ position: 'Right' }")
      ejs-schedule#Schedule(ref="ScheduleObj" cssClass="timeline-resource-grouping" dateHeaderTemplate="dateHeaderTemplate" :popupOpen="onPopupOpen" :allowResizing="false" :allowDragAndDrop="false" :cellTemplate="!isDevice() ? 'cellTemplate' : null" :selectedDate='selectedDate' :popupClose="onPopupClose" width='100%' height='100%' :firstDayOfWeek="firstDayOfWeek" :resourceHeaderTemplate="!isDevice() ? 'resourceHeaderTemplate' : null" :editorTemplate="editorTemplate" :actionBegin="onActionBegin" @navigating="navigating" :actionComplete="onActionComplete" :eventSettings='eventSettings' :group='group' :rowAutoHeight="true" :currentView='currentView' :eventRendered='onEventRendered' :renderCell='onRenderCell' :quickInfoTemplates="quickInfoTemplates" :eventClick="eventClick")
          template(v-slot:dateHeaderTemplate="{data}")
            span(class="date text-capitalize" :title="getDayDetails(data)") {{ getDayDetails(data) }}
          template(v-slot:cellTemplate="data")
            div(class="templatewrap")
              span(v-if="showDurations") {{getTotalDurationDaily(data)}}h
          template(v-slot:resourceHeaderTemplate="{data}")
            div(class='template-wrap')
              div(class="collaborator-label")
                | {{ data.resourceData.displayLabel }}
              div(class="total-duration" :class="showDurations ? 'd-block' : 'd-none'" :title="getTotalDuration(data.resourceData.id)")
                | {{ getTotalDuration(data.resourceData.id) }}
              div(:key="index" v-for="(item, index) in payAllowanceTypesList" :class="showAllowances ? 'd-block' : 'd-none'")
                | {{ getTotalAllowance(item.id, data.resourceData.id) }}
          e-header-rows
            e-header-row(option="Week" :template="'weekHeaderTemplate'")
              template(v-slot:weekHeaderTemplate="{data}")
                span(class="week text-capitalize") {{ getWeekDetails(data) }}
            e-header-row(option="Date")
          e-views
              e-view(option='TimelineWeek' :timeScale='timeScale' :eventTemplate="'eventOnWeekTemplate'")
                template(v-slot:eventOnWeekTemplate='{ data }') 
                  .template-wrapper(:style="data.isReadonly ? 'opacity: 0.5' : ''")
                    div(style='font-size: 0.75rem; font-weight: 500;' :style="'color: ' + getTextColor(data.color) + ';'")
                      | {{ (Math.round(data.duration * 100) / 100) == 0 ? 'À compléter' : Math.round(data.duration * 100) / 100 }}
                    div(style='font-size: .75rem;' :style="'color: ' + getTextColor(data.color) + ';'")
                      | {{ data.name }}
                    div(:class="data.status <= 1 ? 'bg-purple' : data.status == '2' ? 'bg-warning' : data.status == '3' ? 'bg-success' : 'bg-danger'" :style="data.status > 2 || !switchIsValidation ? 'right: 3px' : 'right: 20px'" style='border-radius: 50%; border:1px solid #ffffff; position: absolute; top:4px;width: 12px; height: 12px; display: flex; align-content: center; align-items: center; align-content: center; justify-content: center;')
                      span.material-icons.text-white(style='font-size: .7rem;') {{ data.status <=1 ? 'edit' :  data.status == '2' ? 'schedule' : data.status == '3' ? 'done' : 'close' }}
                    div(v-if="data.status <= 2 && switchIsValidation" style="position: absolute; top:0; right: 3px" )
                      ejs-checkbox(@change="$value => selectEvent(data, $value)")
                    div(v-if="hasAllowance(data)" style="position: absolute; top:2px; right: 18px" title="Contient des indémnités")
                      span.material-icons.text-white(style='font-size: 1rem;') payments
              e-view(option='TimelineMonth' :eventTemplate="'eventOnMonthTemplate'")
                template(v-slot:eventOnMonthTemplate='{ data }')
                  .template-wrapper(:style="data.isReadonly ? 'opacity: 0.5' : ''")
                    div(style='font-size: 1rem; font-weight: 500;' :style="'color: ' + getTextColor(data.color) + ';'")
                      | {{ data.duration == 0 ? 'À compléter': data.duration }}
                    div(style='font-size: .75rem;' :style="'color: ' + getTextColor(data.color) + ';'")
                      | {{ data.name }}
                    div(:class="data.status <= 1 ? 'bg-purple' : data.status == '2' ? 'bg-warning' : data.status == '3' ? 'bg-success' : 'bg-danger'" :style="data.status > 2 || !switchIsValidation ? 'right: 3px' : 'right: 20px'" style='border-radius: 50%; border:1px solid #ffffff; position: absolute; top:3px; right:3px; width: 12px; height: 12px; display: flex; align-content: center; align-items: center; align-content: center; justify-content: center;')
                      span.material-icons.text-white(style='font-size: .7rem;') {{ data.status <=1 ? 'edit' :  data.status == '2' ? 'schedule' : data.status == '3' ? 'done' : 'close' }}
                    div(v-if="data.status <= 2 && switchIsValidation" style="position: absolute; top:0; right: 3px" )
                      ejs-checkbox(@change="$value => selectEvent(data, $value)")
                    div(v-if="hasAllowance(data)" style="position: absolute; top:2px; left: 3px" title="Contient des indémnités")
                      span.material-icons.text-white(style='font-size: 1rem;') payments
          e-resources
              e-resource(field='collaboratorId' title='Collaborateurs' name='Collaborators' :allowMultiple='allowMultiple' :dataSource='collaboratorsListFiltered' textField='displayLabel' idField='id')
              //- e-resource(field='collaboratorId' title='Collaborateurs' name='Collaborators' :allowMultiple='allowMultiple' :dataSource='collaboratorsListFiltered' textField='displayLabel' idField='id')
  </template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { Timezone } from "@syncfusion/ej2-schedule";
import DateRanger from "@/components/global/date-ranger/DateRanger.vue";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import { extend, Internationalization } from "@syncfusion/ej2-base";
var instance = new Internationalization();

import {
  SchedulePlugin,
  Week,
  Month,
  Agenda,
  TimelineViews,
  TimelineMonth,
  Day,
  WorkWeek,
  Resize,
  DragAndDrop,
  getWeekNumber,
  getWeekLastDate,
} from "@syncfusion/ej2-vue-schedule";
Vue.use(SchedulePlugin);

import { reportingStatus } from "@/types/api-orisis/enums/enums";
import quickInfoTemplateHeader from "@/components/report/scheduler/QuickInfoTemplateHeader.vue";
import quickInfoTemplateContent from "@/components/report/scheduler/QuickInfoTemplateContent.vue";

import popupCreateReportDetail from "@/components/report/scheduler/PopupCreateReportDetail.vue";

export default {
  props: {
    collaboratorIds: {
      type: Array,
      default: () => [],
    },
    tabActive: {
      type: String,
      required: true,
    },
    switchIsValidation: {
      type: Boolean,
      default: false,
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    isReportsByCollaborators: {
      type: Boolean,
      default: false,
    },
    showDurations: {
      type: Boolean,
      default: true,
    },
    showAllowances: {
      type: Boolean,
      default: true,
    },
    schedulerMode: {
      type: String,
      default: "global",
    },
    searchText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      collaborator: null,
      totalHoursWeek: 0,
      maxDays: 31,
      allowFiltering: true,
      // Formulaire
      dataFormInitialize: {
        dateRange: [
          new Date(dayjs().format("YYYY-MM-DD")),
          new Date(dayjs().format("YYYY-MM-DD")),
        ],
        dateRangeArray: [new Date().toISOString().slice(0, 10)],
        collaboratorIds: [],
        payrollEventId: 0,
        affairIds: [],
        missionId: 0,
        retrieveSchedule: false,
        // retrieveReportings: true,
      },
      dataFormReportsOrigine: [],
      dataFormReports: [],
      dataFormReportsLoading: false,
      statusList: reportingStatus.filter((el) => !el.notUse),
      title: "",
      name: "dashboard",
      key: 0,
      selectedDate: new Date(),
      currentView: "TimelineWeek",
      dataLoaded: false,
      timezoneSchedule: new Timezone(),
      width: "100%",
      height: "100%",
      timeScale: { enable: false },
      firstDayOfWeek: 1,
      group: {
        byGroupID: false,
        resources: ["Collaborators"],
      },
      allowMultiple: true,
      eventSettings: {
        dataSource: this.dataScheduler,
        fields: {
          id: "id",
          recurrenceRule: null,
          status: { name: "status", title: "Statut" },
          subject: { name: "name", title: "Évènement" },
          duration: { name: "duration", title: "Durée" },
          collaboratorId: { name: "collaboratorId", title: "Collaborateur" },
          affairId: { name: "affairId", title: "Affaire" },
          eventTypeId: { name: "payrollEventId", title: "Type d'évènement" },
          startTime: { name: "startTime", title: "Début" },
          endTime: { name: "endTime", title: "Fin" },
          isAllDay: { name: "isAllDay", title: "Toute la journée" },
          payAllowanceTypes: { name: "payAllowanceTypes", title: "Indemnités" },
        },
        //order by status
        sortComparer: (array) => {
          return array.sort((a, b) => a.status - b.status);
        },
      },
      quickInfoTemplates: {
        header:
          !/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(
            navigator.userAgent
          )
            ? function (e) {
                return {
                  template: quickInfoTemplateHeader,
                };
              }
            : undefined,
        content: function (e) {
          return {
            template: quickInfoTemplateContent,
          };
        },
        footer: function (e) {
          return {
            template: undefined,
          };
        },
      },
    };
  },
  components: {
    DateRanger,
  },
  provide: {
    schedule: [
      Week,
      Month,
      Agenda,
      TimelineViews,
      TimelineMonth,
      Day,
      WorkWeek,
      Resize,
      DragAndDrop,
    ],
  },
  computed: {
    ...mapGetters([
      "institutionSelected",
      "payAllowanceTypesList",
      "collaboratorsList",
      "eventTypesList",
      "companiesList",
      "affairsList",
      "missionsList",
      "payrollEventsList",
      "userCollaboratorId",
      "userCollaborator",
      "reportDetailsMinifiedList",
      "reportDetailsList",
      "isLoadingReportDetailsMinifiedList",
      "userParametersList",
    ]),
    isPayrollEventValued() {
      const res = this.payrollEventsList.find(
        (el) => el.id == this.dataFormInitialize.payrollEventId
      ).isValued;
      if (!res) {
        this.dataFormInitialize.affairIds = [];
        this.dataFormInitialize.missionId = null;
      }
      return res;
    },
    groupActionList() {
      let groupAction = [];
      if (this.selectedRows.length === 0 && this.dataFormReports.length == 0) {
        groupAction.push({
          text: "Prévisualiser",
          css: "e-primary",
          iconCss: "e-icons e-eye",
          value: "view",
        });
        groupAction.push({
          text: "Créer",
          css: "e-primary",
          iconCss: "e-icons e-save",
          value: "create",
        });
      } else if (this.dataFormReports.length > 0) {
        groupAction.push({
          text: "Prévisualiser",
          iconCss: "e-icons e-eye",
          css: "e-primary",
          value: "view",
        });
        groupAction.push({
          text: "Créer",
          css: "e-primary",
          iconCss: "e-icons e-save",
          value: "create",
        });
        groupAction.push({
          text: "Annuler",
          css: "e-secondary e-outline",
          value: "cancel",
          iconCss: "e-icons e-close",
        });
      }
      return groupAction;
    },
    selectedEvents: {
      get() {
        return this.selectedRows;
      },
      set(value) {
        this.$emit("setSelectedRows", value);
      },
    },
    collaboratorsListFiltered() {
      if (this.isReportsByCollaborators) {
        if (this.collaboratorIds.length > 0) {
          return this.collaboratorsList.filter((el) =>
            this.collaboratorIds.includes(el.id)
          );
        } else {
          return this.collaboratorsList;
        }
      } else {
        return [this.userCollaborator];
      }
    },
    dataScheduler() {
      let res = this.reportDetailsMinifiedList.map((el) => {
        return {
          status: el.status,
          id: el.id,
          duration: el.duration,
          name: el.payrollEventLabel ? el.payrollEventLabel : "À compléter",
          payrollEventId: el.payrollEventId,
          startTime: el.startDate,
          endTime: el.startDate,
          isReadonly: el.isReadonly ? el.isReadonly : false,
          allowances: el.payAllowanceTypes,
          color: el.payrollEventColor ? el.payrollEventColor : "#0c3571",
          collaboratorId: el.collaboratorId,
          collaboratorFullName: el.collaboratorFullName,
          isAllDay: true,
          detailIds: el.detailIds ? el.detailIds : [],
        };
      });
      res = res.filter((el) => {
        if (this.searchText) {
          if (this.schedulerMode == "global") {
            const message = this.reportDetailsList
              .filter((elem) => el.detailIds.includes(elem.id))
              .map((objet) => objet.message)
              .join();
            return (
              this.stringContains(el.name, this.searchText) ||
              this.stringContains(message, this.searchText)
            );
          } else {
            if (el.id) {
              const fullReport = this.reportDetailsList.find(
                (elem) => elem.id == el.id
              );
              return (
                this.stringContains(el.name, this.searchText) ||
                this.stringContains(
                  fullReport.message ? fullReport.message : "",
                  this.searchText
                )
              );
            } else {
              return this.stringContains(el.name, this.searchText);
            }
          }
        } else {
          return true;
        }
      });
      if (!this.switchIsValidation) {
        return res.concat(
          this.dataFormReports.map((res) => {
            return {
              status: res.status,
              id: res.id,
              duration: res.duration,
              name: res.payrollEventId
                ? this.payrollEventsList.find(
                    (el) => el.id == res.payrollEventId
                  ).label
                : res.status == -2
                ? "Suggéré"
                : "À compléter",
              affairId: res.affairId,
              missionId: res.missionId ? [res.missionId.toString()] : [],
              payrollEventId: res.payrollEventId,
              startTime: res.startDate,
              endTime: res.startDate,
              isReadonly: res.isReadonly ? res.isReadonly : false,
              allowances: [],
              color:
                res.payrollEventId &&
                this.payrollEventsList.find((el) => el.id == res.payrollEventId)
                  .color
                  ? this.payrollEventsList.find(
                      (el) => el.id == res.payrollEventId
                    ).color
                  : "grey",
              collaboratorId: res.collaboratorId,
              collaboratorFullName: this.collaboratorsList.find(
                (el) => el.id == res.collaboratorId
              ).displayLabel,
              isAllDay: true,
              detailIds: res.detailIds ? res.detailIds : [],
            };
          })
        );
      } else {
        if (this.tabActive == "all") {
          return res;
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          return res.filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status == this.tabActive
          );
        }
      }
    },
  },
  watch: {
    dataScheduler: function (newVal, oldVal) {
      this.eventSettings = { ...this.eventSettings, dataSource: newVal };
      this.$refs.ScheduleObj ? this.$refs.ScheduleObj.refreshEvents() : null;
    },
    collaboratorIds: function (newVal, oldVal) {
      this.$refs.ScheduleObj.refreshLayout();
    },
    showAllowances() {
      this.$refs.ScheduleObj.refreshTemplates();
    },
    showDurations() {
      this.$refs.ScheduleObj.refreshTemplates();
    },
    schedulerMode(newVal, oldVal) {
      let scheduleObj = this.$refs.ScheduleObj.ej2Instances;
      let currentDatesView = scheduleObj.getCurrentViewDates();
      let startDate = dayjs(currentDatesView[0]).format("YYYY-MM-DD");
      let endDate = dayjs(currentDatesView[currentDatesView.length - 1]).format(
        "YYYY-MM-DD"
      );
      this.setDates(
        {
          startDate: startDate,
          endDate: endDate,
        },
        newVal == "global" ? true : false
      );
    },
    "dataFormInitialize.retrieveSchedule": function (val) {
      if (val) {
        this.dataFormInitialize.payrollEventId = null;
        this.dataFormInitialize.affairIds = [];
        this.dataFormInitialize.missionId = null;
      }
    },
  },
  async mounted() {
    this.getAffairs({});
    this.getMissions({});
    this.getCollaborators({});
    this.getPayrollEvents({});
    this.eventSettings.dataSource = this.dataScheduler;
    await this.getPayAllowanceTypes({});
    this.currentView = this.userParametersList.find(
      (el) =>
        el.key ==
        (this.isReportsByCollaborators
          ? "reportsSchedulerCurrentView"
          : "myReportsSchedulerCurrentView")
    )
      ? this.userParametersList.find(
          (el) =>
            el.key ==
            (this.isReportsByCollaborators
              ? "reportsSchedulerCurrentView"
              : "myReportsSchedulerCurrentView")
        ).value
      : "TimelineWeek";
    let period = this.currentView == "TimelineWeek" ? "week" : "month";
    let filter = this.userParametersList.find(
      (el) =>
        el.key ==
        (this.isReportsByCollaborators
          ? "planningReportDateRange"
          : "myPlanningReportDateRange")
    );
    if (filter) {
      this.selectedDate = new Date(JSON.parse(filter.value).startDate);
      let startDate = dayjs(JSON.parse(filter.value).startDate)
        // .startOf(period)
        .format("YYYY-MM-DD");
      let endDate = dayjs(JSON.parse(filter.value).endDate)
        // .endOf(period)
        .format("YYYY-MM-DD");
      this.setDates(
        {
          startDate: startDate,
          endDate: endDate,
        },
        this.schedulerMode == "global" ? true : false
      );
      this.dataFormInitialize.dateRange = [
        new Date(startDate),
        new Date(endDate),
      ];
    } else {
      this.setDates(
        {
          startDate: dayjs().startOf(period).format("YYYY-MM-DD"),
          endDate: dayjs().endOf(period).format("YYYY-MM-DD"),
        },
        this.schedulerMode == "global" ? true : false
      );
      this.dataFormInitialize.dateRange = [
        new Date(dayjs().startOf(period).format("YYYY-MM-DD")),
        new Date(dayjs().endOf(period).format("YYYY-MM-DD")),
      ];
    }
    this.dataLoaded = true;
  },
  methods: {
    ...mapActions([
      "getEventsByCollaboratorId",
      "getAffairs",
      "updateReportDetail",
      "createReportDetail",
      "deleteReportDetail",
      "updateActivity",
      "getPayAllowanceTypes",
      "getReportDetailsMinified",
      "getReportDetails",
      "getCollaborators",
      "getPayrollEvents",
      "getMissions",
      "getReportDetailById",
      "createOrUpdateUserParameter",
      "updateReportDetailsPayAllowanceType",
    ]),
    editorTemplate: function (e) {
      let self = this;
      return {
        template: {
          extends: popupCreateReportDetail,
          propsData: {
            dataValue: self.isReportsByCollaborators
              ? "ReportCollaborators"
              : "Reports",
          },
        },
      };
    },
    getColorByTotalHours(totalHours, userId) {
      let collaborator = this.collaboratorsList.find((el) => el.id == userId);
      if (collaborator) {
        if (totalHours >= collaborator.contract.hoursDayLimit) {
          return "red";
        } else if (totalHours > collaborator.contract.hoursDayWarning) {
          return "orange";
        } else {
          return "black";
        }
      }
    },
    navigating(args) {
      if (args.action === "date" && args.currentDate !== args.previousDate) {
        let scheduleObj = this.$refs.ScheduleObj.ej2Instances;
        let period =
          scheduleObj.currentView === "TimelineWeek" ? "week" : "month";
        let startDate = dayjs(args.currentDate)
          .startOf(period)
          .format("YYYY-MM-DD");
        let endDate = dayjs(args.currentDate)
          .endOf(period)
          .format("YYYY-MM-DD");

        this.createOrUpdateUserParameter({
          label: this.isReportsByCollaborators
            ? "Dates du planning de reporting"
            : "Dates de mon planning de reporting",
          key: this.isReportsByCollaborators
            ? "planningReportDateRange"
            : "myPlanningReportDateRange",
          value: JSON.stringify({ startDate, endDate }),
        });
        this.setDates(
          {
            startDate: startDate,
            endDate: endDate,
          },
          this.schedulerMode == "global" ? true : false
        );
        this.maxDays = null;
        this.dataFormInitialize.dateRange = [
          new Date(startDate),
          new Date(endDate),
        ];
      }
    },
    dateSelecting(args) {
      if (args.startDate) {
        this.maxDays =
          dayjs(args.startDate)
            .endOf("month")
            .diff(dayjs(args.startDate), "day") + 1;
        if (
          dayjs(args.startDate).isAfter(
            dayjs(structuredClone(args.startDate))
              .endOf("month")
              .subtract(7, "days")
          )
        ) {
          this.maxDays =
            dayjs(args.startDate)
              .endOf("week")
              .diff(dayjs(args.startDate), "day") + 1;
        }
      }
    },
    isDevice() {
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(
        navigator.userAgent
      );
    },
    stringContains(source, search) {
      return source
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, "")
        .toLowerCase()
        .includes(
          search
            .normalize("NFD")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s/g, "")
            .toLowerCase()
        );
    },
    eventClick(args) {
      if (args.event.status > -1 && args.event.id) {
        this.getReportDetailById({ reportDetailId: args.event.id });
      }
    },
    hasAllowance(data) {
      return data.allowances.find(
        (elem) => elem.checkbox || elem.quantity > 0 || elem.amount > 0
      )
        ? true
        : false;
    },
    //Get text color (white or black) depending on parameter which is a string as hex color
    getTextColor(color) {
      if (!color) return "white";
      let c = color.substring(1, 7); // strip #
      let rgb = parseInt(c, 16); // convert rrggbb to decimal
      let r = (rgb >> 16) & 0xff; // extract red
      let g = (rgb >> 8) & 0xff; // extract green
      let b = (rgb >> 0) & 0xff; // extract blue
      let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      return luma < 128 ? "white" : "black";
    },
    setDateRangeArray(args) {
      this.maxDays = null;
      let currentDate = dayjs(args.startDate);
      if (currentDate.isSame(args.endDate, "week")) {
        this.currentView = "TimelineWeek";
      } else {
        this.currentView = "TimelineMonth";
      }
      this.setDates(
        {
          startDate: dayjs(args.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(args.endDate).format("YYYY-MM-DD"),
        },
        this.schedulerMode == "global" ? true : false
      );
      this.selectedDate = new Date(dayjs(args.startDate).format("YYYY-MM-DD"));
      this.createOrUpdateUserParameter({
        label: this.isReportsByCollaborators
          ? "Dates du planning de reporting"
          : "Dates de mon planning de reporting",
        key: this.isReportsByCollaborators
          ? "planningReportDateRange"
          : "myPlanningReportDateRange",
        value: JSON.stringify({
          startDate: args.startDate,
          endDate: args.endDate,
        }),
      });
    },
    async actionInitialize(action) {
      this.dataFormReports = [];
      if (action !== "cancel") {
        this.dataFormReportsLoading = true;
        const { retrieveSchedule } = this.dataFormInitialize;
        let allDates = this.dataFormInitialize.dateRangeArray;
        let collaboratorIds =
          this.dataFormInitialize.collaboratorIds.length > 0
            ? this.dataFormInitialize.collaboratorIds
            : !this.isReportsByCollaborators
            ? [this.userCollaboratorId]
            : this.collaboratorsListFiltered.map((elem) => {
                return elem.id;
              });

        // this.isReportsByCollaborators
        //   ? this.dataFormInitialize.collaboratorIds
        //   : [this.userCollaboratorId];
        let affairIds =
          this.dataFormInitialize.affairIds.length > 0
            ? this.dataFormInitialize.affairIds
            : [null];

        this.collaboratorFieldInvalid = false;
        try {
          let eventsPromise = retrieveSchedule
            ? this.getEventsByCollaboratorId({
                startDate: allDates[0],
                endDate: allDates[allDates.length - 1],
                collaboratorIds: collaboratorIds,
              })
            : Promise.resolve([]);

          let events = await eventsPromise;
          let coveredCombinations = new Array();
          events.forEach((event) => {
            if (dayjs(event.endDate).isAfter(allDates[allDates.length - 1])) {
              event.endDate = new Date(allDates[allDates.length - 1]);
            }
            if (dayjs(event.startDate).isBefore(allDates[0])) {
              event.startDate = new Date(allDates[0]);
            }
            //for loop on event.collaboratorIds
            let collaboratorIdsFiltered = event.collaboratorIds.filter((elem) =>
              collaboratorIds.includes(elem)
            );
            for (let i = 0; i < collaboratorIdsFiltered.length; i++) {
              //For loop on all days between start and end date
              let currentDate = dayjs(event.startDate);
              while (currentDate.isBefore(event.endDate)) {
                const date = currentDate.format("YYYY-MM-DD");
                const comboKey = `${date}-${event.collaboratorIds[i]}`; //-${event.affairId}`; //-${event.id}`;
                if (
                  this.eventTypesList.find(
                    (el) => el.id == event.eventTypeId
                  ) &&
                  this.eventTypesList.find((el) => el.id == event.eventTypeId)
                    .payrollEventId
                ) {
                  // On remonde que les événements de planning associés à un événement lié à une variable de paie
                  coveredCombinations.push(comboKey);
                  if (action == "create") {
                    const selectedCollab = this.collaboratorsList.find(
                      (elem) => elem.id == event.collaboratorIds[i]
                    );
                    this.createReportDetail({
                      reportDetail: {
                        collaboratorId: event.collaboratorIds[i],
                        duration: event.hoursDay
                          ? event.hoursDay
                          : Number(
                              (
                                dayjs(event.endDate).diff(
                                  dayjs(event.startDate),
                                  "minutes"
                                ) / 60
                              ).toFixed(2)
                            ) < 24
                          ? Number(
                              (
                                dayjs(event.endDate).diff(
                                  dayjs(event.startDate),
                                  "minutes"
                                ) / 60
                              ).toFixed(2)
                            )
                          : selectedCollab && selectedCollab.hoursByDay
                          ? selectedCollab.hoursByDay
                          : 0,
                        durationValue: event.hoursDay
                          ? event.hoursDay
                          : Number(
                              (
                                dayjs(event.endDate).diff(
                                  dayjs(event.startDate),
                                  "minutes"
                                ) / 60
                              ).toFixed(2)
                            ) < 24
                          ? Number(
                              (
                                dayjs(event.endDate).diff(
                                  dayjs(event.startDate),
                                  "minutes"
                                ) / 60
                              ).toFixed(2)
                            )
                          : selectedCollab && selectedCollab.hoursByDay
                          ? selectedCollab.hoursByDay
                          : 0,
                        // duration: event.hoursDay ? event.hoursDay : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
                        // durationValue: event.hoursDay ? event.hoursDay : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
                        payrollEventId: this.eventTypesList.find(
                          (el) => el.id == event.eventTypeId
                        ).payrollEventId,
                        affairId: event.affairId,
                        missionId: event.missionId,
                        startDate: this.timezoneSchedule.removeLocalOffset(
                          new Date(dayjs(date).format("YYYY-MM-DDTHH:mm:ss"))
                        ),
                        endDate: this.timezoneSchedule.removeLocalOffset(
                          new Date(dayjs(date).format("YYYY-MM-DDTHH:mm:ss"))
                        ),
                        message: event.comments || "",
                        institutionId: this.institutionSelected.id,
                      },
                    });
                  } else {
                    this.dataFormReports.push(
                      this.createEventAsReportObject(
                        event,
                        collaboratorIdsFiltered[i],
                        date
                      )
                    );
                  }
                }
                currentDate = currentDate.add(1, "day");
              }
            }
          });
          if (!retrieveSchedule) {
            allDates.forEach((date) => {
              collaboratorIds.forEach((collabId) => {
                affairIds.forEach((affairId) => {
                  const comboKey = `${date}-${collabId}`; //-${affairId}`;
                  if (!coveredCombinations.includes(comboKey)) {
                    if (action == "create") {
                      const selectedCollab = this.collaboratorsList.find(
                        (elem) => elem.id == collabId
                      );
                      if (this.dataFormInitialize.payrollEventId) {
                        this.createReportDetail({
                          reportDetail: {
                            collaboratorId: collabId,
                            duration:
                              selectedCollab && selectedCollab.hoursByDay
                                ? selectedCollab.hoursByDay
                                : 0,
                            durationValue:
                              selectedCollab && selectedCollab.hoursByDay
                                ? selectedCollab.hoursByDay
                                : 0,
                            payrollEventId:
                              this.dataFormInitialize.payrollEventId,
                            affairId: affairId,
                            missionId: this.dataFormInitialize.missionId,
                            startDate: this.timezoneSchedule.removeLocalOffset(
                              new Date(
                                dayjs(date).format("YYYY-MM-DDTHH:mm:ss")
                              )
                            ),
                            endDate: this.timezoneSchedule.removeLocalOffset(
                              new Date(
                                dayjs(date).format("YYYY-MM-DDTHH:mm:ss")
                              )
                            ),
                            message: "",
                            institutionId: this.institutionSelected.id,
                          },
                        });
                      }
                    } else {
                      this.dataFormReports.push(
                        this.createDefaultReportObject(date, collabId, affairId)
                      );
                    }
                  }
                });
              });
            });
          }
        } catch (error) {
          console.error("Error initializing reports: ", error);
        }
        this.dataFormReportsLoading = false;
      }
    },
    createEventAsReportObject(event, collaboratorId, date) {
      const selectedCollab = this.collaboratorsList.find(
        (elem) => elem.id == collaboratorId
      );
      return {
        id: Date.now() + Math.floor(Math.random() * 10000),
        date: dayjs(date).format("YYYY-MM-DD"),
        startDate: dayjs(date).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(date).format("YYYY-MM-DDTHH:mm:ss"),
        duration: event.hoursDay
          ? event.hoursDay
          : Number(
              (
                dayjs(event.endDate).diff(dayjs(event.startDate), "minutes") /
                60
              ).toFixed(2)
            ) < 24
          ? Number(
              (
                dayjs(event.endDate).diff(dayjs(event.startDate), "minutes") /
                60
              ).toFixed(2)
            )
          : selectedCollab && selectedCollab.hoursByDay
          ? selectedCollab.hoursByDay
          : 0,
        durationValue: event.hoursDay
          ? event.hoursDay
          : Number(
              (
                dayjs(event.endDate).diff(dayjs(event.startDate), "minutes") /
                60
              ).toFixed(2)
            ) < 24
          ? Number(
              (
                dayjs(event.endDate).diff(dayjs(event.startDate), "minutes") /
                60
              ).toFixed(2)
            )
          : selectedCollab && selectedCollab.hoursByDay
          ? selectedCollab.hoursByDay
          : 0,
        // duration: event.hoursDay ? event.hoursDay : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
        // durationValue: event.hoursDay ? event.hoursDay : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
        // duration: event.hoursDay ? event.hoursDay : (dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") / 60).toFixed(2) <= 24 ? (dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") / 60).toFixed(2) : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
        // durationValue: event.hoursDay ? event.hoursDay : (dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") / 60).toFixed(2) <= 24 ? (dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") / 60).toFixed(2) : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
        status: -2, // Un planning est par defaut suggéré
        collaboratorId: collaboratorId,
        payrollEventId: this.eventTypesList.find(
          (el) => el.id == event.eventTypeId
        ).payrollEventId,
        affairId: event.affairId,
        missionId: event.missionId,
        message: event.comments || "",
        isFacturable: false,
        eventId: event.id,
        createdBy: event.createdBy,
      };
    },
    createDefaultReportObject(date, collaboratorId, affairId) {
      const selectedCollab = this.collaboratorsList.find(
        (elem) => elem.id == collaboratorId
      );

      return {
        id: Date.now() + Math.floor(Math.random() * 100),
        createdBy: 0,
        status: -1,
        date: date,
        duration:
          selectedCollab && selectedCollab.hoursByDay
            ? selectedCollab.hoursByDay
            : 0,
        durationValue:
          selectedCollab && selectedCollab.hoursByDay
            ? selectedCollab.hoursByDay
            : 0,
        startDate: dayjs(date).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(date).format("YYYY-MM-DDTHH:mm:ss"),
        collaboratorId: collaboratorId,
        payrollEventId: this.dataFormInitialize.payrollEventId,
        affairId: affairId, // Utilisation directe de l'ID d'affaire
        missionId: this.dataFormInitialize.missionId,
        message: null,
      };
    },
    selectEvent(data, value) {
      setTimeout(() => {
        this.$refs.ScheduleObj.closeQuickInfoPopup();
      }, 1);
      if (value.checked) {
        this.selectedEvents.push(
          //this.reportDetailsList.find((el) => el.id == data.id)
          this.dataScheduler.find((el) => el.id == data.id)
        );
      } else {
        this.selectedEvents = this.selectedEvents.filter(
          (elem) => elem.id != data.id
        );
      }
    },
    async setDates(dates, isSchedulerGlobal = false) {
      if (dates.startDate) {
        if (dates.startDate && dates.endDate) {
          this.dataFormInitialize.dateRangeArray = [];
          let currentDate = dayjs(dates.startDate);
          let endDate = dayjs(dates.endDate);
          while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
            this.dataFormInitialize.dateRangeArray.push(
              currentDate.format("YYYY-MM-DD")
            );
            currentDate = currentDate.add(1, "day");
          }
        }

        let dateRange = {
          startDate: new Date(dayjs(dates.startDate).format("YYYY-MM-DD")),
          endDate: dates.endDate
            ? new Date(dayjs(dates.endDate).format("YYYY-MM-DD"))
            : new Date(dayjs().format("YYYY-MM-DD")),
        };
        const period = this.currentView == "TimelineWeek" ? "week" : "month";
        await this.getReportDetails({
          startDate: dayjs(dateRange.startDate)
            .startOf(period)
            .format("YYYY-MM-DD"),
          endDate: dayjs(dateRange.endDate).endOf(period).format("YYYY-MM-DD"),
          collaboratorIds: this.isReportsByCollaborators
            ? []
            : [this.userCollaboratorId],
        });
        await this.getReportDetailsMinified({
          startDate: dayjs(dateRange.startDate)
            .startOf(period)
            .format("YYYY-MM-DD"),
          endDate: dayjs(dateRange.endDate).endOf(period).format("YYYY-MM-DD"),
          globalReport: isSchedulerGlobal,
          collaboratorIds: this.isReportsByCollaborators
            ? []
            : [this.userCollaboratorId],
        });
      }
    },
    getTotalDurationDaily(data) {
      if (this.collaboratorsListFiltered.length == 0) {
        return 0;
      } else {
        let res = this.eventSettings.dataSource
          .filter((elem) => {
            return (
              dayjs(elem.startTime).format("YYYY-MM-DD") ==
                dayjs(data.data.date).format("YYYY-MM-DD") &&
              this.collaboratorsListFiltered.find(
                (el) => el.id == elem.collaboratorId
              ) &&
              elem.collaboratorId ==
                (this.isReportsByCollaborators
                  ? this.collaboratorsListFiltered[data.data.groupIndex].id
                  : this.userCollaboratorId)
            );
          })
          .reduce((total, objet) => total + objet.duration, 0);
        return res ? Math.round(res * 100) / 100 : 0;
      }
    },
    onPopupOpen: function (args) {
      if (
        (typeof args.data.id === "undefined" &&
          (args.type == "QuickInfo" || this.switchIsValidation)) ||
        (args.data.isReadonly && args.type == "Editor")
      ) {
        args.cancel = true;
      }
      if (args.type == "DeleteAlert") {
        let deleteButton = document.querySelector(".e-quick-dialog-delete");
        let parentElement = deleteButton.parentElement;
        deleteButton.classList.add("bg-danger");
        deleteButton.classList.add("border-danger");
        parentElement.appendChild(deleteButton);
      }
      if (!this.isDevice()) {
        if (args.type === "Editor") {
          //Set le collaborateur sur lequel on a cliqué
          const selectedCollab = this.collaboratorsList.find(
            (elem) => elem.id == args.data.collaboratorId
          );
          //Change le titre du popup
          const date = dayjs(args.data.startTime).format("dddd D MMMM YYYY");
          const titlePopup = document.querySelector(
            ".e-schedule-dialog .e-title-text"
          );
          document
            .querySelector("#Schedule_dialog_wrapper_title")
            .classList.add("w-100");
          const h4TitleCollaborator = document.createElement("h4");
          h4TitleCollaborator.classList.add("d-flex");
          h4TitleCollaborator.classList.add("align-items-center");
          h4TitleCollaborator.classList.add("mb-50");
          const spanTitleCollaborator = document.createElement("span");
          spanTitleCollaborator.classList.add("material-icons-outlined");
          spanTitleCollaborator.classList.add("mr-50");
          spanTitleCollaborator.innerHTML = "person";
          h4TitleCollaborator.appendChild(spanTitleCollaborator);
          h4TitleCollaborator.innerHTML += selectedCollab.displayLabel;
          const h4TitleDate = document.createElement("h4");
          h4TitleDate.classList.add("d-flex");
          h4TitleDate.classList.add("align-items-center");
          h4TitleDate.classList.add("mb-0");
          const spanTitleDate = document.createElement("span");
          spanTitleDate.classList.add("material-icons-outlined");
          spanTitleDate.classList.add("mr-50");
          spanTitleDate.innerHTML = "today";
          h4TitleDate.appendChild(spanTitleDate);
          h4TitleDate.innerHTML += date.charAt(0).toUpperCase() + date.slice(1);
          const spanTitleDuration = document.createElement("span");
          spanTitleDuration.setAttribute("id", "timer");
          spanTitleDuration.classList.add("material-icons-outlined");
          spanTitleDuration.classList.add("ml-50");
          spanTitleDuration.classList.add("mr-50");
          spanTitleDuration.innerHTML = "timer";
          const spanTitleTimer = document.createElement("span");
          spanTitleTimer.setAttribute("id", "popupTimer");
          spanTitleTimer.innerHTML += "0h";
          const spanTitleTimeRanger = document.createElement("span");
          spanTitleTimeRanger.classList.add("ml-50");
          spanTitleTimeRanger.setAttribute("id", "popupTimeRanger");
          spanTitleTimeRanger.innerHTML += "";
          h4TitleDate.innerHTML += "&nbsp;&nbsp;|";
          h4TitleDate.appendChild(spanTitleDuration);
          h4TitleDate.appendChild(spanTitleTimer);
          h4TitleDate.appendChild(spanTitleTimeRanger);
          titlePopup.innerHTML = "";
          titlePopup.appendChild(h4TitleCollaborator);
          titlePopup.appendChild(h4TitleDate);
          //changer les boutons en fonction du status
          let buttonSaveElement = document.querySelector(
            ".e-schedule-dialog .e-event-save"
          );
          let buttonDeleteElement = document.querySelector(
            ".e-schedule-dialog .e-event-delete"
          );
          let buttonCancelElement = document.querySelector(
            ".e-schedule-dialog .e-event-cancel"
          );
          let parentElement = buttonCancelElement.parentElement;
          if (args.data.status == 3) {
            buttonCancelElement.innerHTML = "Fermer";
            buttonSaveElement.disabled = true;
            buttonSaveElement.style.visibility = "hidden";
            buttonDeleteElement.disabled = true;
            buttonDeleteElement.style.visibility = "hidden";
            buttonDeleteElement.classList.remove("e-flat");
            buttonDeleteElement.classList.remove("e-outline");
            buttonDeleteElement.classList.remove("e-danger");
            buttonDeleteElement.classList.remove("m-0");
            buttonDeleteElement.classList.remove("e-icon-btn");
            buttonSaveElement.classList.remove("e-flat");
            parentElement.appendChild(buttonCancelElement);
          } else {
            buttonCancelElement.innerHTML = "Annuler";
            buttonSaveElement.disabled = false;
            buttonSaveElement.style.visibility = "visible";
            buttonDeleteElement.disabled = false;
            buttonDeleteElement.style.visibility =
              args.data.status < 0 ? "hidden" : "visible";
            buttonDeleteElement.classList.remove("e-flat");
            buttonDeleteElement.classList.add("e-outline");
            buttonDeleteElement.classList.add("e-danger");
            buttonDeleteElement.classList.add("m-0");
            buttonDeleteElement.classList.add("e-icon-btn");
            const spanDeleteIcon = document.createElement("span");
            spanDeleteIcon.classList.add("e-btn-sb-icons");
            spanDeleteIcon.classList.add("e-icon-trash");
            buttonDeleteElement.innerHTML = "";
            buttonDeleteElement.appendChild(spanDeleteIcon);
            buttonSaveElement.classList.remove("e-flat");
            const spanAddIcon = document.createElement("span");
            spanAddIcon.classList.add("e-btn-icon");
            spanAddIcon.classList.add("e-btn-sb-icons");
            spanAddIcon.classList.add("e-icons");
            spanAddIcon.classList.add("e-save");
            spanAddIcon.classList.add("e-icon-right");
            buttonSaveElement.innerHTML = "Enregistrer";
            buttonSaveElement.appendChild(spanAddIcon);
            parentElement.appendChild(buttonSaveElement);
          }
          if (this.schedulerMode == "global" && args.data.id == 0) {
            args.cancel = true;
            return;
          }
          //Set les validateurs du formulaire
          // let formElement = args.element.querySelector(".e-schedule-form");
          // let validator = formElement.ej2_instances[0];
          // if (selectedCollab && selectedCollab.reportEntryMode == 1) {
          //   validator.addRules("endDate", { required: true });
          //   validator.addRules("startDate", { required: true });
          // }
          // if (
          //   !args.data.payrollEventId ||
          //   this.payrollEventsList.find((el) => el.id == args.data.payrollEventId)
          //     .isValued
          // ) {
          //   validator.addRules("missionId", { required: true });
          //   validator.addRules("affairId", { required: true });
          // }
          // validator.addRules("duration", { required: true });
          // validator.addRules("durationValue", { required: true });
          // validator.addRules("payrollEventId", { required: true });
        }
      }
    },
    onPopupClose: function (args) {
      if (
        args.type === "Editor" &&
        args.data &&
        !args.event.srcElement.className.includes("e-icon-trash") &&
        !args.event.srcElement.className.includes("e-event-delete") &&
        !args.event.srcElement.className.includes("e-event-cancel") &&
        !args.event.srcElement.className.includes("e-icon-dlg-close") &&
        !args.event.srcElement.className.includes("e-dlg-closeicon-btn")
      ) {
        args.data.duration =
          args.element.querySelector("#duration").ej2_instances[0].value;
        args.data.durationValue =
          args.element.querySelector("#durationValue").ej2_instances[0].value;
        args.data.collaboratorId =
          args.element.querySelector("#collaboratorId").ej2_instances[0].value;
        args.data.payrollEventId =
          args.element.querySelector("#payrollEventId").ej2_instances[0].value;
        args.data.affairId =
          args.element.querySelector("#eventAffairId").ej2_instances[0].value;
        args.data.allowances = [
          ...document.querySelectorAll(
            '[id^="allowance' +
              (this.isReportsByCollaborators
                ? 'ReportCollaborators_"]'
                : 'Reports_"]')
          ),
        ].map((elem) => {
          return {
            id: elem.id.split("_")[1],
            value:
              elem.type == "checkbox"
                ? elem.checked
                : elem.ej2_instances[0].value,
          };
        });
        args.data.missionId =
          Array.isArray(
            args.element.querySelector("#missionId").ej2_instances[0].value
          ) &&
          args.element.querySelector("#missionId").ej2_instances[0].value
            .length > 0
            ? args.element.querySelector("#missionId").ej2_instances[0].value[0]
            : null;
        args.data.startDate =
          args.element.querySelector("#startDate").ej2_instances[0].value;
        args.data.endDate = args.element.querySelector("#endDate")
          ? args.element.querySelector("#endDate").ej2_instances[0].value
          : args.element.querySelector("#startDate").ej2_instances[0].value;
        args.data.message =
          args.element.querySelector("#message").ej2_instances[0].value;
      }
    },
    getTotalDuration(collaboratorId) {
      let res = this.eventSettings.dataSource
        .filter((elem) => elem.collaboratorId == collaboratorId)
        .reduce((total, objet) => total + objet.duration, 0);
      return res ? Math.round(res * 100) / 100 + "h" : "";
    },
    onRenderCell: function (args) {
      if (
        args.elementType === "emptyCells" &&
        args.element.classList.contains("e-resource-left-td")
      ) {
        let target = args.element.querySelector(".e-resource-text");
        target.innerHTML = "<div>Collaborateur</div>";
        target.innerHTML += `<div class="${
          this.showDurations ? "d-block" : "d-none"
        }" title="${"Total Heures"}">Total Heures</div>`;
        for (let i = 0; i < this.payAllowanceTypesList.length; i++) {
          const allowanceType = this.payAllowanceTypesList[i];
          target.innerHTML += `<div class="${
            this.showAllowances ? "d-block" : "d-none"
          }" title="${allowanceType.label}">${
            allowanceType.shortLabel
              ? allowanceType.shortLabel
              : allowanceType.label
          }</div>`;
        }
        this.setResourcesColumnWidth();
      }
    },
    getDayDetails(value) {
      if (this.currentView == "TimelineWeek") {
        return dayjs(value.date).format("dddd D MMMM");
      } else {
        return (
          dayjs(value.date).format("ddd D").charAt(0).toUpperCase() +
          dayjs(value.date).format("ddd D").slice(1)
        );
      }
    },
    getMonthDetails: function (value) {
      return instance.formatDate(value.date, { skeleton: "yMMMM" });
    },
    getWeekDetails: function (value) {
      return "Semaine " + getWeekNumber(getWeekLastDate(value.date, 0));
    },
    getTotalAllowance(allowanceId, collaboratorId) {
      let total = 0;
      for (let i = 0; i < this.eventSettings.dataSource.length; i++) {
        const report = this.eventSettings.dataSource[i];
        const allowance = report.allowances.find(
          (elem) => elem.id == allowanceId
        );
        if (report.collaboratorId == collaboratorId && allowance) {
          if (allowance.quantificationType == 0 && allowance.checkbox) {
            total++;
          } else if (allowance.quantificationType == 1 && allowance.quantity) {
            total += allowance.quantity;
          } else if (allowance.quantificationType == 2 && allowance.amount) {
            total += allowance.amount;
          }
        }
      }
      return total ? total : "";
    },
    setResourcesColumnWidth() {
      var style = document.createElement("style");
      // style.type = 'text/css';
      document.getElementsByTagName("head")[0].appendChild(style);
      var width = 180;
      if (this.showDurations) {
        width += 110;
      }
      if (this.showAllowances) {
        width += this.payAllowanceTypesList.length * 50;
      }
      if (!(style.sheet || {}).insertRule) {
        (style.styleSheet || style.sheet).addRule(
          ".timeline-resource-grouping.e-schedule .e-timeline-month-view .e-resource-left-td",
          "width: " + width + "px;"
        );
        (style.styleSheet || style.sheet).addRule(
          ".timeline-resource-grouping.e-schedule .e-timeline-week-view .e-resource-left-td",
          "width: " + width + "px;"
        );
      } else {
        style.sheet.insertRule(
          ".timeline-resource-grouping.e-schedule .e-timeline-month-view .e-resource-left-td { width: " +
            width +
            "px;}",
          0
        );
        style.sheet.insertRule(
          ".timeline-resource-grouping.e-schedule .e-timeline-week-view .e-resource-left-td { width: " +
            width +
            "px;}",
          0
        );
      }
    },
    onActionBegin(args) {
      if (args.requestType == "eventRemove") {
        if (args.deletedRecords.length > 0) {
          let eventsRemove = args.deletedRecords.map((elem) => {
            return elem.id;
          });
          args.cancel = true;
          this.deleteReportDetail({
            reportDetailIds: eventsRemove,
          });
        }
      } else if (args.requestType == "eventCreate") {
        args.cancel = true;
        args.data.forEach((element) => {
          element.createdBy = this.userCollaboratorId;
          this.createReportDetail({
            reportDetail: {
              collaboratorId: element.collaboratorId,
              duration: element.duration,
              durationValue: element.durationValue,
              payrollEventId: element.payrollEventId,
              affairId: element.affairId,
              missionId: element.missionId,
              // startDate: element.startDate, => FIX saisie par durée, durée horaire peut etre cassée
              // endDate: element.endDate,  => FIX saisie par durée, durée horaire peut etre cassée
              startDate: this.timezoneSchedule.removeLocalOffset(
                new Date(element.startDate)
              ),
              endDate: this.timezoneSchedule.removeLocalOffset(
                new Date(element.endDate)
              ),
              message: element.message,
              institutionId: this.institutionSelected.id,
            },
          }).then((res) => {
            this.updateReportDetailsPayAllowanceType({
              reportDetailId: res.id,
              reportAllowanceTypes: element.allowances.map((elem) => {
                let fullAllowance = this.payAllowanceTypesList.find(
                  (allowance) => allowance.id == elem.id
                );
                return {
                  payAllowanceTypeId: elem.id,
                  checkbox:
                    fullAllowance.quantificationType == 0 ? elem.value : null,
                  quantity:
                    fullAllowance.quantificationType == 1 ? elem.value : null,
                  amount:
                    fullAllowance.quantificationType == 2 ? elem.value : null,
                };
              }),
            });
          });
        });
      } else if (args.requestType == "eventChange") {
        if (args.data.id && args.data.status > -1) {
          this.updateReportDetail({
            reportDetail: {
              id: args.data.id,
              collaboratorId: args.data.collaboratorId,
              duration: args.data.duration,
              durationValue: args.data.durationValue,
              payrollEventId: args.data.payrollEventId,
              affairId: args.data.affairId,
              missionId: args.data.missionId,
              // startDate: args.data.startDate, => FIX saisie par durée, durée horaire peut etre cassée
              // endDate: args.data.endDate, => FIX saisie par durée, durée horaire peut etre cassée
              startDate: this.timezoneSchedule.removeLocalOffset(
                new Date(args.data.startDate)
              ),
              endDate: this.timezoneSchedule.removeLocalOffset(
                new Date(args.data.endDate)
              ),
              message: args.data.message,
              institutionId: this.institutionSelected.id,
            },
          });
          this.updateReportDetailsPayAllowanceType({
            reportDetailId: args.data.id,
            reportAllowanceTypes: args.data.allowances.map((elem) => {
              let fullAllowance = this.payAllowanceTypesList.find(
                (allowance) => allowance.id == elem.id
              );
              return {
                payAllowanceTypeId: elem.id,
                checkbox:
                  fullAllowance.quantificationType == 0 ? elem.value : null,
                quantity:
                  fullAllowance.quantificationType == 1 ? elem.value : null,
                amount:
                  fullAllowance.quantificationType == 2 ? elem.value : null,
              };
            }),
          });
        } else {
          args.cancel = true;
          this.createReportDetail({
            reportDetail: {
              collaboratorId: args.data.collaboratorId,
              duration: args.data.duration,
              durationValue: args.data.durationValue,
              payrollEventId: args.data.payrollEventId,
              affairId: args.data.affairId,
              missionId: args.data.missionId,
              // startDate: args.data.startDate, => FIX saisie par durée, durée horaire peut etre cassée
              // endDate: args.data.endDate, => FIX saisie par durée, durée horaire peut etre cassée
              startDate: this.timezoneSchedule.removeLocalOffset(
                new Date(args.data.startDate)
              ),
              endDate: this.timezoneSchedule.removeLocalOffset(
                new Date(args.data.endDate)
              ),
              message: args.data.message,
              institutionId: this.institutionSelected.id,
            },
          }).then((res) => {
            this.dataFormReports = this.dataFormReports.filter(
              (elem) => elem.id != args.data.id
            );
            this.updateReportDetailsPayAllowanceType({
              reportDetailId: res.id,
              reportAllowanceTypes: args.data.allowances.map((elem) => {
                let fullAllowance = this.payAllowanceTypesList.find(
                  (allowance) => allowance.id == elem.id
                );
                return {
                  payAllowanceTypeId: elem.id,
                  checkbox:
                    fullAllowance.quantificationType == 0 ? elem.value : null,
                  quantity:
                    fullAllowance.quantificationType == 1 ? elem.value : null,
                  amount:
                    fullAllowance.quantificationType == 2 ? elem.value : null,
                };
              }),
            });
          });
        }
      }
    },
    onActionComplete(args) {
      if (args.requestType == "viewNavigate") {
        let scheduleObj = this.$refs.ScheduleObj.ej2Instances;
        this.currentView = scheduleObj.currentView;
        this.createOrUpdateUserParameter({
          label: this.isReportsByCollaborators
            ? "Vue du planning de reporting"
            : "Vue de mon planning de reporting",
          key: this.isReportsByCollaborators
            ? "reportsSchedulerCurrentView"
            : "myReportsSchedulerCurrentView",
          value: scheduleObj.currentView,
        });
      }
    },
    onEventRendered: function (args) {
      let scheduleObj = this.$refs.ScheduleObj;
      if (!args.element) {
        return;
      }
      args.element.style.backgroundColor = args.data.color;
      if (args.data.status < 0) {
        args.element.style.backgroundImage = `repeating-linear-gradient(45deg, ${args.data.color}, ${args.data.color} 10px, rgba(255,255,255,0.5) 10px, rgba(255,255,255,0.5) 20px)`;
      }
    },
  },
};
</script>
<style>
.timeline-resource-grouping.e-schedule
  .e-timeline-month-view
  .e-resource-left-td,
.timeline-resource-grouping.e-schedule
  .e-timeline-week-view
  .e-resource-left-td {
  border-right: 3px #ccc solid !important;
}
.templatewrap {
  /* Align content to the bottom of the cell */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  padding: 0 5px;
}
.e-schedule .e-timeline-view .e-resource-cells,
.e-schedule .e-timeline-month-view .e-resource-cells {
  border-right: 3px #ccc solid !important;
}
.e-schedule .e-resource-left-td .e-schedule .e-resource-cells {
  border-right: 3px #ccc solid !important;
}
#viewReportScheduler .e-appointment-details {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.e-schedule .e-timeline-month-view .e-resource-left-td .e-resource-text,
.e-schedule .e-timeline-week-view .e-resource-left-td .e-resource-text {
  display: flex;
  padding: 0;
}

.e-schedule .e-timeline-month-view .e-resource-left-td .e-resource-text > div,
.e-schedule .e-timeline-week-view .e-resource-left-td .e-resource-text > div {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  height: 74px;
  line-height: 34px;
  padding-left: 5px;
  text-align: center;
  /* font-family: "Montserrat", Helvetica, Arial, serif; */
  font-size: 14px;
  border-top: 0px;
}

.e-schedule
  .e-timeline-month-view
  .e-resource-left-td
  .e-resource-text
  > div:nth-child(2),
.e-schedule
  .e-timeline-week-view
  .e-resource-left-td
  .e-resource-text
  > div:nth-child(2) {
  flex: 1.5;
  min-width: 110px;
}

.e-schedule
  .e-timeline-month-view
  .e-resource-left-td
  .e-resource-text
  > div:first-child,
.e-schedule
  .e-timeline-week-view
  .e-resource-left-td
  .e-resource-text
  > div:first-child {
  flex: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  /* font-family: "Montserrat", Helvetica, Arial, serif; */
  font-size: 14px;
  font-weight: 600;
  min-width: 180px;
  border-top: 0px;
}

.e-schedule .e-quick-popup-wrapper .e-event-popup .e-popup-footer {
  display: none;
}

#viewReportScheduler
  .e-schedule
  .e-timeline-view
  .e-resource-left-td
  .e-resource-text
  > div:first-child {
  padding-left: 1rem;
}

.e-schedule
  .e-timeline-month-view
  .e-resource-left-td
  .e-resource-text
  > div:last-child,
.e-schedule
  .e-timeline-week-view
  .e-resource-left-td
  .e-resource-text
  > div:last-child {
  border-right: 0;
}

.e-schedule
  .e-timeline-view
  .e-resource-left-td
  .e-resource-text
  > div:last-child {
  border-right: 0;
}

.e-schedule .template-wrap {
  display: flex;
  height: 100%;
  text-align: left;
}

.e-schedule .template-wrap > div {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex: 1;
  font-weight: 500;
  line-height: 58px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
  text-overflow: ellipsis;
  text-align: center;
}
.e-schedule .template-wrap > div:first-child {
  flex: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  min-width: 180px;
  padding-left: 1rem;
}
.e-schedule .template-wrap > div:nth-child(2) {
  flex: 1.5;
  min-width: 110px;
}

.e-schedule .template-wrap > div:last-child {
  border-right: 0;
}

.e-schedule .e-timeline-view .e-resource-cells,
.e-schedule .e-timeline-month-view .e-resource-cells {
  padding-left: 0;
}

@media (max-width: 550px) {
  .e-schedule .e-timeline-view .e-resource-left-td {
    width: 100px;
  }
  .e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div,
  .e-schedule .template-wrap > div {
    flex: 0 0 100%;
  }
  .e-schedule .template-wrap > div:first-child {
    border-right: 0;
  }
  .e-schedule
    .e-timeline-view
    .e-resource-left-td
    .e-resource-text
    > div:first-child {
    border-right: 0;
  }
  .e-schedule .room-type,
  .e-schedule .room-capacity {
    display: none;
  }
}
</style>
