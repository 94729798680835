<template lang="pug">
div
  div(v-if="data.detailIds && data.detailIds.length>0")
    .carousel(v-if="data.detailIds && data.detailIds.length>0")
      input(:id="'carousel-css-slide-'+(indexReportInput + 1)" type='radio' name='carousel-css' :value="'slide-'+(indexReportInput + 1)" v-for="(report, indexReportInput) in reportsDetailMapped" :key="'reportInput'+(indexReportInput + 1)" :checked='indexReportInput == 0 ? true : false')
      label(:for="'carousel-css-slide-'+(indexReportLabel + 1)" :data-value="'slide-'+(indexReportLabel + 1)" v-for="(report, indexReportLabel) in reportsDetailMapped" :key="'reportLabel'+(indexReportLabel + 1)")
      .carousel-wrapper
        .carousel-slide(v-for="(report, indexReport) in reportsDetailMapped" :key="'report'+(indexReport + 1)")
          .e-dlg-header.w-100
            v-style
              | .e-quick-popup-wrapper .e-dlg-header {  border-top-right-radius:5px; border-top-left-radius:5px; {{(data.color ? 'border-bottom:'+data.color+' 1px solid !important; background:#f8f8f8 !important;' : 'border-bottom:#f8f8f8 1px solid !important; background:#f8f8f8 !important;')}} }
            div.e-title-text.p-1(:style="'background:#f8f8f8 !important; border-top-right-radius:5px; border-top-left-radius:5px;'")
              .d-flex.align-items-center.justify-content-between.mb-50 
                h4.d-flex.align-items-center.mb-0
                  span.material-icons-outlined.mr-50 person
                  | {{ data.collaboratorFullName ? data.collaboratorFullName : 'Collaborateur non défini' }} 
                .d-flex.align-items-center(style="margin-right:40px")
                  span.e-chips.text-white(:class="report.status <= 1 ? 'bg-purple' : report.status == '2' ? 'bg-warning' : 'bg-success'" :style="'padding: 0 4px 0 4px; border-radius: 4px; font-size:12px'") 
                    | {{ report.status <= 1 ? 'Brouillon' : report.status == '2' ? 'A valider' : 'Validé' }}
                  span.link_icon.material-icons-outlined.text-primary.m-0.ml-50(v-if="report.status <= 2" style='font-size:19px; cursor: pointer;' @click="editReport(report)")
                    | edit
              .d-flex.align-items-center.justify-content-between.mb-0 
                h4.d-flex.align-items-center.mb-0 
                  span.material-icons-outlined.mr-50 today
                  | {{ date.charAt(0).toUpperCase() + date.slice(1) }} 
                  span.ml-1(v-show="report.duration") |
                  span.material-icons-outlined.ml-50.mr-50(v-show="report.duration") timer
                  span#quickTimer(v-show="report.duration") {{ report.duration + 'h'}}
                .d-flex.align-items-center.justify-content-center(style="width:34px;")
                  span(style="font-weight:500; font-size:14px") {{ (indexReport+ 1) + "/" + reportsDetailMapped.length }}
          .p-1
            div(v-if="report.payrollEventId")
              label.mb-0 Type d'évènement
              .d-flex.align-items-center.mb-50
                div.pin-color.small.mr-50(:style="'background-color:'+( report.payrollEvent && report.payrollEvent.color!=='' ? report.payrollEvent.color : '#0c3571')+' !important'")
                div {{ report.payrollEvent ? report.payrollEvent.label : "n.d" }}
            div(v-if="report.affairId")
              label.mb-0 Affaire 
              .d-flex.align-items-center.mb-50
                  div.pin-color.small.mr-50(:style="'background-color:'+( report.affair && report.affair.color!=='' ? report.affair.color : '#0c3571')+' !important'")
                  div {{ report.affair ? report.affair.displayLabel : "n.d" }}
            div
              label.mb-0 Durée
              p.mb-50.d-flex.align-items-center(style="font-weight:600")  
                span.material-icons-outlined.mr-50.small timer
                | {{ report.duration }} h
            div(v-if="report.message")
              label.mb-0 Commentaire
              p.mb-50(style="font-weight:500" v-html="formatMessage(report.message)")
  div(v-else)
    .e-dlg-header
      v-style
        | .e-quick-popup-wrapper .e-dlg-header {  border-top-right-radius:5px; border-top-left-radius:5px; {{(data.color ? 'border-bottom:'+data.color+' 1px solid !important; background:#f8f8f8 !important;' : 'border-bottom:#f8f8f8 1px solid !important; background:#f8f8f8 !important;')}} }
      div.e-title-text.p-1(:style="'background:#f8f8f8 !important; border-top-right-radius:5px; border-top-left-radius:5px;'")
        .d-flex.align-items-center.justify-content-between.mb-50 
          h4.d-flex.align-items-center.mb-0
            span.material-icons-outlined.mr-50 person
            | {{ data.collaboratorFullName ? data.collaboratorFullName : 'Collaborateur non défini' }} 
          .d-flex.align-items-center
            span.e-chips.text-white(:class="data.status <= 1 ? 'bg-purple' : data.status == '2' ? 'bg-warning' : 'bg-success'" :style="'padding: 0 4px 0 4px; border-radius: 4px; font-size:12px'") 
              | {{ data.status <= 1 ? 'Brouillon' : data.status == '2' ? 'A valider' : 'Validé' }}
            span.link_icon.material-icons-outlined.text-primary.m-0.ml-50(v-if="data.status <= 2" style='font-size:19px; cursor: pointer;' @click="editReport()")
              | edit
        .d-flex.align-items-center.mb-0 
          h4.d-flex.align-items-center.mb-0 
            span.material-icons-outlined.mr-50 today
            | {{ date.charAt(0).toUpperCase() + date.slice(1) }}   
            span.ml-1(v-show="data.duration") |
            span.material-icons-outlined.ml-50.mr-50(v-show="data.duration") timer
            span#quickTimer(v-show="data.duration") {{ this.data.duration + 'h'}}
            span.ml-50#quickTimeRanger(v-show="data.duration")
    div.p-1
      div(v-if="reportDetailOrigine.payrollEventId")
        label.mb-0 Type d'évènement
        .d-flex.align-items-center.mb-50
          div.pin-color.small(:style="'background-color:'+( payrollEvent && payrollEvent.color!=='' ? payrollEvent.color : '#0c3571')+' !important'")
          div {{ payrollEvent ? payrollEvent.label : "n.d" }}
      div(v-if="reportDetailOrigine.companyId")
        label.mb-0() Client
        p.mb-50(style="font-weight:500")  {{ company ? company.name : "n.d" }}
      div(v-if="reportDetailOrigine.affairId")
        label.mb-0 Affaire 
        .d-flex.align-items-center.mb-50
            div.pin-color.small(:style="'background-color:'+( affair && affair.color!=='' ? affair.color : '#0c3571')+' !important'")
            div {{ affair ? affair.displayLabel : "n.d" }}
      div(v-if="reportDetailOrigine.missionId && mission")
        label.mb-0 Mission
        p.mb-50(style="font-weight:500") {{ mission ? mission.label : "n.d" }}
      div(v-if="reportDetailOrigine.message")
          label.mb-0 Commentaire
          p.mb-50(style="font-weight:500" v-html="formatMessage(reportDetailOrigine.message)")
      vs-divider(v-if="allowances.length>0" color="#cccccc" class="m-0 ml-0 pl-0 mt-0 mb-0" position="left")
        label Indemnités 
      b-row.mb-0.mt-0
        b-col.py-0(v-for="(allowance, indexAllowance) in allowances" :key="indexAllowance" md="4" style='align-self: center; padding-bottom:0px !important')
          label.mb-0 {{ allowance.shortLabel ?  allowance.shortLabel : allowance.label }} 
          p.mb-50(style="font-weight:600")  {{ allowance.value && allowance.value === true ? 1 : allowance.value }} {{ allowance.quantificationType==2 ? '€' : '' }}
</template>

<script>
import { startDate } from "@/store/modules/purchase/invoiceProvider/actions";
import { mapGetters, mapActions } from "vuex";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      data: null,
      dataAllowances: [],
    };
  },
  computed: {
    ...mapGetters([
      "collaboratorsList",
      "payAllowanceTypesList",
      "payrollEventsList",
      "companiesList",
      "affairsList",
      "missionsList",
      "reportDetail",
      "reportsDetail",
    ]),
    reportDetailOrigine() {
      if (this.data.status < 0) {
        return this.data;
      } else {
        return this.reportDetail;
      }
    },
    selectedCollaborator() {
      return this.data.collaboratorId
        ? this.collaboratorsList.find(
            (collaborator) => collaborator.id == this.data.collaboratorId
          )
        : null;
    },
    payrollEvent() {
      return this.payrollEventsList.find(
        (payrollEvent) => payrollEvent.id == this.data.payrollEventId
      )
        ? this.payrollEventsList.find(
            (payrollEvent) => payrollEvent.id == this.data.payrollEventId
          )
        : "";
    },
    company() {
      return this.companiesList.find(
        (company) => company.id == this.reportDetailOrigine.companyId
      )
        ? this.companiesList.find(
            (company) => company.id == this.reportDetailOrigine.companyId
          )
        : "";
    },
    affair() {
      return this.affairsList.find(
        (affair) => affair.id == this.reportDetailOrigine.affairId
      )
        ? this.affairsList.find(
            (affair) => affair.id == this.reportDetailOrigine.affairId
          )
        : "";
    },
    mission() {
      return this.reportDetailOrigine.missionId.length == 0
        ? ""
        : this.missionsList.find(
            (mission) => mission.id == this.reportDetailOrigine.missionId
          )
        ? this.missionsList.find(
            (mission) => mission.id == this.reportDetailOrigine.missionId
          )
        : "";
    },
    allowances() {
      return this.dataAllowances.filter(
        (el) => el.value !== 0 && el.value !== "" && el.value !== false
      );
    },
    reportsDetailMapped() {
      return this.reportsDetail.map((report) => {
        return {
          ...report,
          affair: this.affairsList.find(
            (affair) => affair.id == report.affairId
          )
            ? this.affairsList.find((affair) => affair.id == report.affairId)
            : null,
          payrollEvent: this.payrollEventsList.find(
            (payrollEvent) => payrollEvent.id == report.payrollEventId
          )
            ? this.payrollEventsList.find(
                (payrollEvent) => payrollEvent.id == report.payrollEventId
              )
            : null,
        };
      });
    },
  },
  beforeMount() {
    this.dataAllowances = [];
    this.date = this.data.startTime
      ? dayjs(this.data.startTime).format("dddd D MMMM YYYY")
      : "";
    if (this.data.color) {
      this.data.color = this.data.color.slice(0, 7);
    }

    if (this.data.detailIds && this.data.detailIds.length > 0) {
      this.getReportDetails({
        reportDetailIds: this.data.detailIds,
        route: "quickInfo",
        groupedByMission: true,
      });
    } else {
      this.dataAllowances = this.payAllowanceTypesList.map((allowanceType) => {
        let allowanceValue = 0;
        if (
          this.data.allowances &&
          this.data.allowances.length > 0 &&
          this.data.allowances.find(
            (allowance) => allowance.id == allowanceType.id
          )
        ) {
          let dataAllowance = this.data.allowances.find(
            (allowance) => allowance.id == allowanceType.id
          );
          allowanceValue =
            allowanceType.quantificationType == 0
              ? dataAllowance.checkbox
              : allowanceType.quantificationType == 1
              ? dataAllowance.quantity
              : dataAllowance.amount;
        }
        return {
          ...allowanceType,
          value: allowanceValue,
        };
      });
    }
  },
  mounted() {
    if (this.data.duration) {
      if (this.selectedCollaborator.reportEntryMode == 1) {
        //this.changeTimeRange()
      } else {
        this.$nextTick(() => {
          const popupTimeRanger = document.querySelector("#quickTimeRanger");
          if (popupTimeRanger) {
            popupTimeRanger.innerHTML = "";
          }
        });
      }
    }
  },
  methods: {
    ...mapActions(["getReportDetails"]),
    formatMessage(str) {
      return str ? str.replace(/\r?\n|\r/g, "<br>") : "";
    },
    editReport(report) {
      const scheduleObj = this.$parent;

      if (report) {
        const newData = {
          ...this.data,
          id: report.id,
        };
        scheduleObj.openEditor(newData, "EditOccurrence", true);
      } else {
        const newData = {
          ...this.data,
          startDate: this.data.startTime,
          endDate: this.data.endTime,
        };
        scheduleObj.openEditor(newData, "EditOccurrence", true);
      }
    },
    changeTimeRange() {
      let diffOrigine = (
        dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") /
        60
      ).toFixed(2);
      let newDiff = diffOrigine;

      if (diffOrigine < 0) {
        this.data.endDate = new Date(dayjs(this.data.endDate).add(1, "day"));
        let newDiff = (
          dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") /
          60
        ).toFixed(2);
      } else if (diffOrigine > 24) {
        this.data.endDate = new Date(
          dayjs(this.data.endDate).subtract(1, "day")
        );
        let newDiff = (
          dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") /
          60
        ).toFixed(2);
      }

      this.$nextTick(() => {
        const popupTimer = document.querySelector("#quickTimer");
        popupTimer.innerHTML = newDiff + "h";
        const popupTimeRanger = document.querySelector("#quickTimeRanger");
        if (this.selectedCollaborator.reportEntryMode == 1) {
          popupTimeRanger.innerHTML =
            "(" +
            dayjs(this.data.startDate).format("HH:mm") +
            " - " +
            dayjs(this.data.endDate).format("HH:mm") +
            ")";
        } else {
          popupTimeRanger.innerHTML = "";
        }
      });
      this.data.duration = newDiff;
      this.data.durationValue = newDiff;
    },
  },
};
</script>
<style lang="css">
.e-quick-popup-wrapper .e-popup-content {
  padding: 0px !important;
}

.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  min-height: 15rem;
  /* padding: 0.5rem; */
}

.carousel * {
  margin: 0;
  padding: 0;
}

.carousel-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.carousel-slide {
  background: #f6f6f6;
  display: none;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  transform: translate(100%, 0);
  opacity: 0;
}

input[name="carousel-css"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;

  border: 1px solid rgb(150, 150, 150);
  background-color: rgb(250, 250, 250);

  width: 1rem;
  height: 1rem;

  cursor: pointer;
  border-radius: 50%;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 3;

  margin: 0.125rem;
}

input[name="carousel-css"]:checked {
  display: inline-flex;
}

input[name="carousel-css"]:checked::before {
  content: "";
  background-color: #6495ed;
  border-radius: 50%;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

/* Hide unnecessary radio buttons */

input[name="carousel-css"][value="slide-20"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(19),
input[name="carousel-css"][value="slide-19"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(18),
input[name="carousel-css"][value="slide-18"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(17),
input[name="carousel-css"][value="slide-17"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(16),
input[name="carousel-css"][value="slide-16"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(15),
input[name="carousel-css"][value="slide-15"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(14),
input[name="carousel-css"][value="slide-14"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(13),
input[name="carousel-css"][value="slide-13"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(12),
input[name="carousel-css"][value="slide-12"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(11),
input[name="carousel-css"][value="slide-11"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(10),
input[name="carousel-css"][value="slide-10"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(9),
input[name="carousel-css"][value="slide-9"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(8),
input[name="carousel-css"][value="slide-8"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(7),
input[name="carousel-css"][value="slide-7"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(6),
input[name="carousel-css"][value="slide-6"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(5),
input[name="carousel-css"][value="slide-5"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(4),
input[name="carousel-css"][value="slide-4"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(3),
input[name="carousel-css"][value="slide-3"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(2),
input[name="carousel-css"][value="slide-2"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(1),
input[name="carousel-css"][value="slide-1"]:checked
  ~ .carousel-wrapper
  .carousel-slide:last-of-type {
  display: flex;
  opacity: 1;
  transform: translate(-100%, 0);
  transition: 0.5s transform, 0.1s z-index 0.4s;
}

input[name="carousel-css"][value="slide-1"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(2),
input[name="carousel-css"][value="slide-2"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(3),
input[name="carousel-css"][value="slide-3"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(4),
input[name="carousel-css"][value="slide-4"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(5),
input[name="carousel-css"][value="slide-5"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(6),
input[name="carousel-css"][value="slide-6"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(7),
input[name="carousel-css"][value="slide-7"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(8),
input[name="carousel-css"][value="slide-8"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(9),
input[name="carousel-css"][value="slide-9"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(10),
input[name="carousel-css"][value="slide-10"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(11),
input[name="carousel-css"][value="slide-11"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(12),
input[name="carousel-css"][value="slide-12"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(13),
input[name="carousel-css"][value="slide-13"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(14),
input[name="carousel-css"][value="slide-14"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(15),
input[name="carousel-css"][value="slide-15"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(16),
input[name="carousel-css"][value="slide-16"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(17),
input[name="carousel-css"][value="slide-17"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(18),
input[name="carousel-css"][value="slide-18"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(19),
input[name="carousel-css"][value="slide-19"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(20),
input[name="carousel-css"]:last-of-type:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(1) {
  display: flex;
  opacity: 1;
  transform: translate(100%, 0);
  transition: 0.5s transform, 0.1s z-index 0.4s;
}

/* Actions from the radio buttons */

input[name="carousel-css"][value="slide-1"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(1),
input[name="carousel-css"][value="slide-2"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(2),
input[name="carousel-css"][value="slide-3"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(3),
input[name="carousel-css"][value="slide-4"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(4),
input[name="carousel-css"][value="slide-5"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(5),
input[name="carousel-css"][value="slide-6"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(6),
input[name="carousel-css"][value="slide-7"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(7),
input[name="carousel-css"][value="slide-8"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(8),
input[name="carousel-css"][value="slide-9"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(9),
input[name="carousel-css"][value="slide-10"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(10),
input[name="carousel-css"][value="slide-11"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(11),
input[name="carousel-css"][value="slide-12"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(12),
input[name="carousel-css"][value="slide-13"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(13),
input[name="carousel-css"][value="slide-14"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(14),
input[name="carousel-css"][value="slide-15"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(15),
input[name="carousel-css"][value="slide-16"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(16),
input[name="carousel-css"][value="slide-17"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(17),
input[name="carousel-css"][value="slide-18"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(18),
input[name="carousel-css"][value="slide-19"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(19),
input[name="carousel-css"][value="slide-20"]:checked
  ~ .carousel-wrapper
  .carousel-slide:nth-child(20) {
  display: flex;
  transition: 0.5s transform;
  transform: translate(0, 0);
  opacity: 1;
  z-index: 2;
}

/* Next and prev buttons */

.carousel label[data-value|="slide"] {
  width: 0 !important;
  height: 0 !important;
}

.carousel label[data-value|="slide"]::before,
.carousel label[data-value|="slide"]::after {
  content: "";
  display: none;
  position: absolute;
  z-index: 3;

  width: 18px;
  height: 18px;

  background-color: rgba(10, 10, 10, 0.5);
  border-radius: 0.1rem;
  opacity: 1;

  cursor: pointer;

  top: 1rem;
  bottom: 0;
  margin: 0;

  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAtOTYwIDk2MCA5NjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Im02NDAtODAtNDAwLTQwMCA0MDAtNDAwIDU2IDU3LTM0MyAzNDMgMzQzIDM0M3oiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==");
  background-size: contain;
}

.carousel label[data-value|="slide"]::after {
  transform: rotate(180deg);
}

.carousel label[data-value|="slide"]::before:hover,
.carousel label[data-value|="slide"]::before:after {
  background-color: rgba(10, 10, 10, 0.8);
}

input[name="carousel-css"][value="slide-1"]:checked
  ~ label[data-value|="slide"]:last-of-type::before,
input[name="carousel-css"][value="slide-2"]:checked
  ~ label[data-value="slide-1"]::before,
input[name="carousel-css"][value="slide-3"]:checked
  ~ label[data-value="slide-2"]::before,
input[name="carousel-css"][value="slide-4"]:checked
  ~ label[data-value="slide-3"]::before,
input[name="carousel-css"][value="slide-5"]:checked
  ~ label[data-value="slide-4"]::before,
input[name="carousel-css"][value="slide-6"]:checked
  ~ label[data-value="slide-5"]::before,
input[name="carousel-css"][value="slide-7"]:checked
  ~ label[data-value="slide-6"]::before,
input[name="carousel-css"][value="slide-8"]:checked
  ~ label[data-value="slide-7"]::before,
input[name="carousel-css"][value="slide-9"]:checked
  ~ label[data-value="slide-8"]::before,
input[name="carousel-css"][value="slide-10"]:checked
  ~ label[data-value="slide-9"]::before,
input[name="carousel-css"][value="slide-11"]:checked
  ~ label[data-value="slide-10"]::before,
input[name="carousel-css"][value="slide-12"]:checked
  ~ label[data-value="slide-11"]::before,
input[name="carousel-css"][value="slide-13"]:checked
  ~ label[data-value="slide-12"]::before,
input[name="carousel-css"][value="slide-14"]:checked
  ~ label[data-value="slide-13"]::before,
input[name="carousel-css"][value="slide-15"]:checked
  ~ label[data-value="slide-14"]::before,
input[name="carousel-css"][value="slide-16"]:checked
  ~ label[data-value="slide-15"]::before,
input[name="carousel-css"][value="slide-17"]:checked
  ~ label[data-value="slide-16"]::before,
input[name="carousel-css"][value="slide-18"]:checked
  ~ label[data-value="slide-17"]::before,
input[name="carousel-css"][value="slide-19"]:checked
  ~ label[data-value="slide-18"]::before,
input[name="carousel-css"][value="slide-20"]:checked
  ~ label[data-value="slide-19"]::before {
  display: inline-block;
  right: 32px;
}

input[name="carousel-css"][value|="slide"]:last-of-type:checked
  ~ label[data-value="slide-1"]::after,
input[name="carousel-css"][value="slide-1"]:checked
  ~ label[data-value="slide-2"]::after,
input[name="carousel-css"][value="slide-2"]:checked
  ~ label[data-value="slide-3"]::after,
input[name="carousel-css"][value="slide-3"]:checked
  ~ label[data-value="slide-4"]::after,
input[name="carousel-css"][value="slide-4"]:checked
  ~ label[data-value="slide-5"]::after,
input[name="carousel-css"][value="slide-5"]:checked
  ~ label[data-value="slide-6"]::after,
input[name="carousel-css"][value="slide-6"]:checked
  ~ label[data-value="slide-7"]::after,
input[name="carousel-css"][value="slide-7"]:checked
  ~ label[data-value="slide-8"]::after,
input[name="carousel-css"][value="slide-8"]:checked
  ~ label[data-value="slide-9"]::after,
input[name="carousel-css"][value="slide-9"]:checked
  ~ label[data-value="slide-10"]::after,
input[name="carousel-css"][value="slide-10"]:checked
  ~ label[data-value="slide-11"]::after,
input[name="carousel-css"][value="slide-11"]:checked
  ~ label[data-value="slide-12"]::after,
input[name="carousel-css"][value="slide-12"]:checked
  ~ label[data-value="slide-13"]::after,
input[name="carousel-css"][value="slide-13"]:checked
  ~ label[data-value="slide-14"]::after,
input[name="carousel-css"][value="slide-14"]:checked
  ~ label[data-value="slide-15"]::after,
input[name="carousel-css"][value="slide-15"]:checked
  ~ label[data-value="slide-16"]::after,
input[name="carousel-css"][value="slide-16"]:checked
  ~ label[data-value="slide-17"]::after,
input[name="carousel-css"][value="slide-17"]:checked
  ~ label[data-value="slide-18"]::after,
input[name="carousel-css"][value="slide-18"]:checked
  ~ label[data-value="slide-19"]::after,
input[name="carousel-css"][value="slide-19"]:checked
  ~ label[data-value="slide-20"]::after {
  display: inline-block;
  right: 12px;
}

/* Special functions for the carousel */

.carousel-no-bullets input[name="carousel-css"][value|="slide"] {
  display: none;
}

.carousel-non-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.carousel {
  width: 100%;
}
</style>
<style>
.e-quick-popup-wrapper .e-cell-popup .e-popup-content {
  padding: 16px 18px 0px;
}
</style>
