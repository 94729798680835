<template lang="pug">
  ejs-tooltip.chip(v-if="data.column.nature == 'quoteStatus' && item.value==3" :cssClass="'d-flex align-items-center'" ref="tooltip" :content='"Signé le : "+signedDate')
    span(class="point" :class="'bg-' + item.class")
    span(class="label" :class="'text-' + item.class" style="font-size: 14px;") {{ item.label }}
  div(class="chip" v-else-if="item && item.class")
    span(v-if="item.value !== -1 && item.value !== -2" class="point" :class="'bg-' + item.class")
    span.material-icons-outlined.text-primary(v-else style="margin-right: 0.3rem; font-size: 16px;") {{ item.icon }}
    span(class="label" :class="'text-' + item.class") {{ item.label }}
  </template>

<script>
import {
  reportingStatus,
  interventionStatus,
  quoteStatus,
  invoiceStatus,
  holdbackStatus,
  creditStatus,
  orderFormProviderStatus,
  receiptFormProviderStatus,
  deliveryFormStatus,
  inventoryFormStatus,
  invoiceProviderStatus,
  creditProviderStatus,
  orderFormCustomerStatus,
  affairStatus,
  smsStatus,
} from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      item: null,
      data: null,
      signedDate: null,
    };
  },
  beforeMount() {
    this.signedDate = dayjs(
      this.data.signedDate ? this.data.signedDate : this.data.documentDate
    ).format("DD/MM/YYYY");
    switch (this.data.column.nature) {
      case "reportingStatus":
        this.item = reportingStatus.find(
          (elem) => elem.value == this.data.status
        );
        break;
      case "quoteStatus":
        this.item = quoteStatus.find((elem) => elem.value == this.data.status);
        break;
      case "invoiceStatus":
        this.item = invoiceStatus.find(
          (elem) => elem.value == this.data.status
        );
        break;
      case "holdbackStatus":
        this.item = holdbackStatus.find(
          (elem) => elem.value == this.data.status
        );
        break;
      case "creditStatus":
        this.item = creditStatus.find((elem) => elem.value == this.data.status);
        break;
      case "orderFormProviderStatus":
        this.item = orderFormProviderStatus.find(
          (elem) => elem.value == this.data.status
        );
        break;
      case "receiptFormProviderStatus":
        this.item = receiptFormProviderStatus.find(
          (elem) => elem.value == this.data.status
        );
        break;
      case "deliveryFormStatus":
        this.item = deliveryFormStatus.find(
          (elem) => elem.value == this.data.status
        );
        break;
      case "inventoryFormStatus":
        this.item = inventoryFormStatus.find(
          (elem) => elem.value == this.data.status
        );
        break;
      case "invoiceProviderStatus":
        this.item = invoiceProviderStatus.find(
          (elem) => elem.value == this.data.invoiceProviderStatus
        );
        break;
      case "creditProviderStatus":
        this.item = creditProviderStatus.find(
          (elem) => elem.value == this.data.creditProviderStatus
        );
        break;
      case "orderFormCustomerStatus":
        this.item = orderFormCustomerStatus.find(
          (elem) => elem.value == this.data.status
        );
        break;
      case "affairStatus":
        this.item = affairStatus.find((elem) => elem.value == this.data.status);
        break;
      case "interventionStatus":
        this.item = interventionStatus.find(
          (elem) => elem.value == this.data.status
        );
        break;
      case "smsStatus":
        this.item = smsStatus.find((elem) => elem.value == this.data.status);
        break;
      default:
        this.item = null;
        break;
    }
  },
};
</script>
