<template lang="pug">

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { reportingStatus } from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      date: null,
      data: null,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
