<template lang="pug">
  div.w-100.d-flex.flex-column
      div.mb-1.d-none.d-lg-flex.px-1.align-items-end.w-100
        b-form-group.flex-fill.mr-1.align-items-end(label-for="dataFormInitializeDateRange" label="Initialiser une date ou une période")
          DateRanger(id="dataFormInitializeDateRange" @setDates="setDates($event, true)" placeholder="Saisie par période" :dateRangeDefault="dataFormInitialize.dateRange" v-model="dataFormInitialize.dateRange" :showTodayButton="true" :showThisYearButton="false" :showLastYearButton="false" :showFromStartButton="false" :maxDays="31")
        .d-flex.align-items-end(label-for="dataFormInitializeDateRange")
          .mb-50.mr-1
            ejs-checkbox(label='Récupérer les plannings' v-model="dataFormInitialize.retrieveSchedule")
        b-form-group.flex-fill.mr-1.align-items-end(v-if="isReportsByCollaborators" label-for="dataFormInitializeCollaboratorIds" label="Pour un ou plusieurs collaborateurs")
          ejs-multiselect(id="dataFormInitializeCollaboratorIds" name="Collaborateurs" cssClass="e-fixe-height" :dataSource='collaboratorsListFiltered' :closePopupOnSelect="false" :fields="{text:'displayLabel',value:'id'}" placeholder="Sélectionner vos collaborateurs" v-model="dataFormInitialize.collaboratorIds" sortOrder="Ascending" :allowFiltering="allowFiltering" filterType='Contains' required="" @change="changeDataHeader")
        b-form-group.flex-fill.mr-1.align-items-end(label-for="dataFormInitializePayrollEventId" label="Évènement par défault")
          ejs-dropdownlist(id="dataFormInitializePayrollEventId" :enabled="!dataFormInitialize.retrieveSchedule" name="Évènement de paie" :dataSource='payrollEventsList' :fields="{text:'label',value:'id'}" :showClearButton="true"  placeholder='Sélectionner un évènement' v-model="dataFormInitialize.payrollEventId" sortOrder="Ascending" filterType='Contains')
        b-form-group.flex-fill.mr-1.align-items-end(label-for="dataFormInitializeAffairId" label="Affaire")
          ejs-multiselect(id="dataFormInitializeAffairId" name="Affaire" :dataSource='affairsList' :fields="{text:'displayLabel',value:'id'}" :closePopupOnSelect="false" :showClearButton="true" :allowFiltering="allowFiltering"  placeholder='Sélectionner des affaires' v-model="dataFormInitialize.affairIds" sortOrder="Ascending" filterType='Contains' :enabled="!dataFormInitialize.retrieveSchedule && (!dataFormInitialize.payrollEventId || isPayrollEventValued)")
        b-form-group.flex-fill.mr-1.align-items-end(label-for="dataFormInitializeMissionId" label="Tâche")
          ejs-dropdownlist(id="dataFormInitializeMissionId" name="Tâche" :dataSource='missionsList' :fields="{text:'label',value:'id'}" :showClearButton="true"  placeholder='Sélectionner une tâche' v-model="dataFormInitialize.missionId" sortOrder="Ascending" filterType='Contains' :enabled="!dataFormInitialize.retrieveSchedule && (!dataFormInitialize.payrollEventId || isPayrollEventValued)")
        .e-btn-group(:class="groupActionList.length > 1 ? 'e-lib e-css' : 'e-css'")
          ejs-button(:key="groupButtonIndex" v-for="(groupButton, groupButtonIndex) in groupActionList" v-on:click.native="groupButton.value == 1 ? actionInitialize() : groupButton.value == 2 ? dataFormReports = dataFormReports.filter((elem) => elem.id) : groupButton.value == 3 ? actionInitialize() : ''" :content="groupButton.text" :iconCss="groupButton.iconCss" :cssClass="groupButton.css" locale='fr' :spinSettings="{ position: 'Right' }")
      .d-none.d-lg-flex.w-100.h-100.content-grid(style="overflow: hidden;")
          .text-center.flex-center.w-100(v-if='!headerLoaded || dataFormReportsLoading')
              .loading-bg-inner
                  .loader
                      .outer
                      .middle
                      .inner
              .mt-5
                  br
                  br
                  br
                  | Initialisation de vos reportings en cours...
          sync-grid(v-else ref="overviewgrid" :uid="isReportsByCollaborators ? 'reports' : 'myReports'" :gridData="dataFormReportsFiltered" :name="name" :allowPaging="allowPaging" :allowSorting='true' :headerData="headerData" :editSettings="editSettings" :allowGrouping="allowGrouping"  @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :commandClick='commandClick' :groupSettings="groupSettings" :sortSettings="sortOptions" @setSelectedRowsIndexes="setSelectedRowsIndexes" @actionBegin="actionBegin")
      .d-block.d-lg-none.h-100.px-1
          b-row.mb-50.mt-0
              b-col.py-0.mb-50(cols='12')
                  //- b-form-group(label-for="reportDateMobile", label="Initialiser à la date")
                  h5 Initialiser à la date
                  ejs-datepicker(id="reportDateMobile" :placeholder="'Sélectionner une date'" v-model="dataFormEvent.date" format='dd/MM/yyyy' @change="setReportDetailsMobile" :firstDayOfWeek="1")
                  //b-form-group.flex-fill.align-items-end(label-for="dataFormInitializeDateRange" label="Initialiser une date ou une période")
                      DateRanger(id="dataFormInitializeDateRange" @setDates="setDateRangeArray" :strictMode="true" placeholder="Saisie par période" :dateRangeDefault="dataFormInitialize.dateRange" v-model="dataFormInitialize.dateRange" :showLastMonthButton="false" :showThisWeekButton="false" :showThisMonthButton="false" :showTodayButton="false" :showThisYearButton="false" :showLastYearButton="false" :showFromStartButton="false")
          b-row.mb-1
              b-col.py-0(cols='12')
                  vs-divider(color="#cccccc" class="m-0 ml-0 pl-0 mt-0 mb-0" position="left")
                      h5 Reportings existants
                  ejs-dialog(ref="dialogDeleteReportMobile" :key="dialogDeleteReportMobile.key" :isModal="dialogDeleteReportMobile.isModal" :header="dialogDeleteReportMobile.header" :content="dialogDeleteReportMobile.content" :showCloseIcon="dialogDeleteReportMobile.showCloseIcon" :visible="dialogDeleteReportMobile.visible" :buttons="dialogDeleteReportMobile.buttons" :target="dialogDeleteReportMobile.target" :height="dialogDeleteReportMobile.height" :width="dialogDeleteReportMobile.width" :animationSettings="dialogDeleteReportMobile.animationSettings" :closeOnEscape="dialogDeleteReportMobile.closeOnEscape")
              b-col.py-0(cols='12' v-show="reportListByDay.length>0")
                  div.mb-50(v-for="(report, reportIndex) of reportListByDay" :key="reportIndex")
                      div.e-title-text.p-1(:style="'background:#f8f8f8 !important; border-radius:5px; border-top-right-radius:5px; border-top-left-radius:5px;'")
                          .d-flex.align-items-center.justify-content-between.mb-50 
                              h5.d-flex.align-items-center.mb-0.text-capitalize 
                                  span.material-icons-outlined.mr-50(style="font-size: 18px;") today
                                  | {{  formatDateStr(report.startDate) }} 
                                  span.ml-50(v-if="report.duration") |
                                  span.material-icons-outlined.ml-50.mr-50(v-if="report.duration" style="font-size: 18px;") timer
                                  span#quickTimer(v-if="report.duration") {{ report.duration + 'h'}}
                                  // span.ml-50#quickTimeRanger(v-show="report.duration")
                              .d-flex.align-items-center
                                  span.e-chips.text-white(:class="report.status <= 1 ? 'bg-purple' : report.status == '2' ? 'bg-warning' : 'bg-success'" :style="'padding: 0 4px 0 4px; border-radius: 4px; font-size:12px'") 
                                      | {{ report.status <= 1 ? 'Brouillon' : report.status == '2' ? 'A valider' : 'Validé' }}
                                  span.link_icon.material-icons-outlined.text-primary.m-0.ml-50(v-if="report.status <= 2" style='font-size:19px; cursor: pointer;' @click="")
                                      | edit
                          .d-flex.flex-column.align-items-start.mb-0 
                              .w-100.d-flex.align-items-center.mb-50.justify-content-between
                                  .d-flex.align-items-center
                                      div.pin-color.small.mr-50(:style="'margin-left: 2px; margin-right: 2px; background-color:'+( report.payrollEvent && report.payrollEvent.color!=='' ? report.payrollEvent.color : '#0c3571')+' !important'")
                                      div {{ report.payrollEvent ? report.payrollEvent.label : "n.d" }}
                                  span.link_icon.material-icons-outlined.text-danger.m-0.ml-50(v-if="report.status <= 2" style='font-size:19px; cursor: pointer;' @click="deleteReportMobile(report)")
                                      | delete
                              .w-100.d-flex.align-items-center.mb-50(v-if="report.affairId")
                                  div.pin-color.small.mr-50(:style="'margin-left: 2px; margin-right: 2px; background-color:'+( report.affair && report.affair.color!=='' ? report.affair.color : '#0c3571')+' !important'")
                                  div {{ report.affair ? report.affair.displayLabel : "n.d" }}    
              b-col.py-0(cols='12' v-show="!reportListByDay || reportListByDay.length<=0")
                  .w-100.text-center.px-2.py-1.small Vous n'avez actuellement aucun reporting pour le 
                      span(style="font-weight:600") {{  formatDateStr(dataFormEvent.date) }} 
          b-row.mb-50
              b-col.py-0(cols='12')
                  vs-divider(color="#cccccc" class="m-0 ml-0 pl-0 mt-0 mb-0" position="left")
                      h5 Nouveau reporting 
              b-col.py-0(cols='12')
                  b-form-group(label='Type d\'évènement *' label-for='payrollEvent')
                      ejs-dropdownlist(id="payrollEventId" name="payrollEvent" placeholder="Selectionnez un événement de paie" :itemTemplate="'itemTemplate'" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="payrollEventsList" :fields="{ text: 'label', value: 'id' }" v-model="dataFormEvent.payrollEventId" @change='changePayrollEvent')
                          template(v-slot:itemTemplate='{data}')
                              .d-flex
                                  div.pin-color(:style="'background-color:'+(data.color!=='' ? data.color : '#0c3571')+' !important'")
                                  div {{data.label}}
                          template(v-slot:footerTemplate)
                              div.p-50
                                  ejs-button(v-on:click.native="newEventType()" :content="'+ Nouveau type d’évènement'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right") 
                  div(id="payrollEventIdError")
          b-row.mb-50(v-show="(dataFormEvent.payrollEvent && dataFormEvent.payrollEvent.isValued)")
              b-col.py-0(cols='12')
                  b-form-group(label='Client' label-for='companyId')
                      ejs-dropdownlist( id="companyId" name="company" placeholder="Selectionnez un client" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="companiesList" :fields="{ text: 'name', value: 'id' }" v-model="dataFormEvent.companyId" :showClearButton="true" @change='changeCompany')
                          template(v-slot:footerTemplate)
                              div.p-50
                                  ejs-button(v-on:click.native="newCompany()" :content="'+ Nouveau client'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
          b-row.mb-50(v-show="(dataFormEvent.payrollEvent && dataFormEvent.payrollEvent.isValued)")
              b-col.py-0(cols='12')
                  b-form-group(label='Affaire *' label-for='eventAffairId')
                      ejs-dropdownlist(ref="dropdownlistAffairs" id="eventAffairId" name="affair" placeholder="Selectionnez une affaire" :itemTemplate="'itemTemplate'" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="affairsList" :fields="{ text: 'displayLabel', value: 'id' }" v-model="dataFormEvent.affairId" :showClearButton="true" @change='changeAffair' @open="filterAffairsList")
                          template(v-slot:itemTemplate='{data}')
                              .d-flex(:id="'affair_'+data.id")
                                  div.pin-color(:style="'background-color:'+(data.color!=='' ? data.color : '#0c3571')+' !important'")
                                  div {{data.displayLabel}}
                          template(v-slot:footerTemplate)
                              div.p-50
                                  ejs-button(v-on:click.native="newAffair()" :content="'+ Nouvelle affaire'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
                      div(id="affairIdError")
          b-row.mb-50(v-show="(dataFormEvent.payrollEvent && dataFormEvent.payrollEvent.isValued) || dataFormEvent.companyId")
              b-col.py-0(cols='12')
                  b-form-group(label='Mission' label-for='missionId')
                      ejs-dropdowntree(v-if="loaded" id='missionId' popupHeight='300px' filterType='Contains' :allowFiltering='true' :fields="missionFields" v-model='dataFormEvent.missionId' @change='changeMission')
          b-row.mb-50(v-if="selectedCollaborator && selectedCollaborator.reportEntryMode == 1")
              b-col.py-0
                  b-form-group(label='Heure de début *' label-for='startDate' style='flex:1')
                      ejs-timepicker(v-if="loaded" id="startDate" :step="5" name="startDate" v-model="dataFormEvent.startDate" placeholder='Choisir une date' :format="'HH:mm'" :timeFormat="'HH:mm'" data-required-message="Renseigner la date" data-msg-containerid="startDateError" @change="changeTimeRange")
                      div(id="startDateError")
              b-col.py-0
                  b-form-group(label='Heure de fin *' label-for='endDate' style='flex:1')
                      ejs-timepicker(v-if="loaded" id="endDate" :step="5" name="endDate" v-model="dataFormEvent.endDate" placeholder='Choisir une date' :format="'HH:mm'" :timeFormat="'HH:mm'" data-required-message="Renseigner la date" data-msg-containerid="endDateError"  @change="changeTimeRange")
                      div(id="endDateError")
          b-row.mb-50()
              b-col.py-0
                  b-form-group(label='Durée réelle *' label-for='duration' style='flex:1')
                      ejs-numerictextbox(id="duration" :min="0" :max="24" format="##.## h" v-model="dataFormEvent.duration" name="duration" @change="changeDuration" :disabled="selectedCollaborator && selectedCollaborator.reportEntryMode == 1" data-required-message="Renseigner la durée" data-msg-containerid="durationError")
                      div(id="durationError")
              b-col.py-0
                  b-form-group(label='Durée valorisée' label-for='durationValue' style='flex:1')
                      ejs-numerictextbox(id="durationValue" :min="0" format="##.## h" v-model="dataFormEvent.durationValue" name="durationValue" data-required-message="Renseigner la durée valorisée" data-msg-containerid="durationValueError")
                      div(id="durationValueError")
          b-row.mb-50  
              b-col.py-0(md="12" style='padding-bottom:0px !important')
                  b-form-group(label='Commentaire' label-for='message' style='flex:1')
                      ejs-textbox(id="message" v-model="dataFormEvent.message" name="message" :multiline="true")
          b-row.mb-50(v-for="(allowance, indexAllowance) in dataFormEventAllowances" :key="indexAllowance")
              b-col.py-0(md="12" style='align-self: center; padding-bottom:0px !important')
                  b-form-group(v-if="allowance.quantificationType == 0")
                      b-form-checkbox.custom-control-primary(:id="'allowance_'+allowance.id" v-model="allowance.value") {{ allowance.label ? allowance.label : allowance.shortLabel }}
                  b-form-group(v-else :label-for="'allowance_'+allowance.id" :label="allowance.label ? allowance.label : allowance.shortLabel")
                      ejs-numerictextbox(:id="'allowance_'+allowance.id" :label="allowance.label" :placeholder="0" :format="allowance.quantificationType==2 ? '########.## €' : null" :showSpinButton="true" v-model="allowance.value")    
          b-row.mb-3  
              b-col.py-0(md="12" style='padding-bottom:0px !important')
                  .w-100.d-flex.justify-content-between.align-items-center
                      div
                          ejs-button(v-on:click.native="" v-if="dataFormEvent.id" content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-trash'  :isPrimary="true" locale='fr')
                      ejs-button(v-on:click.native="createReport" :disabled="!dataFormEvent.payrollEventId" :content="dataFormEvent.id ? 'Enregistrer' : 'Ajouter'" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")
  </template>
<script>
import { mapGetters, mapActions } from "vuex";

import { FormValidator } from "@syncfusion/ej2-vue-inputs";
import { required } from "@validations";
import { Timezone } from "@syncfusion/ej2-schedule";

import { isMobile as checkIfMobile } from "@/types/api-orisis/library/GlobalOperations.ts";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";
import {
  payrollEventTranslate,
  affairTranslate,
  reportStatusTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

import CellRendererEditStatus from "@/components/global/ag-grid/CellRendererEditStatus.vue";
import CellRendererEditOrigine from "@/components/global/ag-grid/CellRendererEditOrigine.vue";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import DateRanger from "@/components/global/date-ranger/DateRanger.vue";
import { DialogUtility } from "@syncfusion/ej2-popups";

import { reportingStatus } from "@/types/api-orisis/enums/enums";

export default {
  components: {
    DateRanger,
    CellRendererEditStatus,
    CellRendererEditOrigine,
    SyncGrid: () => import("@/components/global/grid/Grid.vue"),
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
      default: "single",
    },
    isReportsByCollaborators: {
      type: Boolean,
      default: false,
    },
    collaboratorIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Header
      reportIndex: 0,
      indexSearch: 0,
      name: "editReport",
      uid: "editReports",
      selectedRows: [],
      selectedRowsIndexes: [],
      dateRanger: false,
      tagguerButton: false,
      allowFiltering: true,

      collaboratorFieldInvalid: false,
      reportAllowanceTypes: [],

      // DataForm mobile
      dataForm: {
        id: null,
        reportDate: new Date(),
        collaboratorId: null,
        reportDetails: [],
      },

      // DataForm
      dataFormInitialize: {
        dateRange: [
          new Date(dayjs().startOf("month").format("YYYY-MM-DD")),
          new Date(dayjs().endOf("month").format("YYYY-MM-DD")),
        ],
        dateRangeArray: [],
        collaboratorIds: [],
        payrollEventId: 0,
        affairIds: [],
        missionId: 0,
        retrieveSchedule: false,
      },

      // Formulaire
      totalHoursDay: 0,
      totalHoursWeek: 0,
      collaborator: null,
      dataFormReports: [],
      dataFormReportsLoading: false,

      formObj: "",
      headerData: [],
      headerLoaded: false,
      allowGrouping: true,
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showDeleteConfirmDialog: true,
        mode: "Normal",
        newRowPosition: "Bottom",
        allowNextRowEdit: true,
        titleAdd: "Nouvelle mission",
        titleEdit: "Modifier la mission",
        titleField: "",
      },
      allowPaging: false,
      commands: [
        {
          type: "Ajouter",
          buttonOption: {
            cssClass: "e-flat e-addbutton",
            iconCss: "e-plus e-icons",
          },
        },
        {
          type: "Dupliquer",
          buttonOption: {
            cssClass: "e-flat e-duplicatebutton",
            iconCss: "e-copy e-icons",
          },
        },
        {
          type: "Delete",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
        {
          type: "Save",
          buttonOption: { cssClass: "e-flat", iconCss: "e-save e-icons" },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
      groupSettings: {
        captionTemplate: "captionTemplate",
        columns: [],
      },
      sortOptions: {
        columns: [{ field: "status", direction: "Descending" }],
      },

      isMobile: checkIfMobile(),

      required,
      formObj: "",
      options: {
        customPlacement: function (inputElement, errorElement) {
          inputElement = inputElement
            .closest(".form-group-validator")
            .querySelector(".error");
          inputElement.parentElement.appendChild(errorElement);
        },
      },

      // VERSION MOBILE
      timezoneSchedule: new Timezone(),
      reportListByDay: [],
      dataFormEvent: {
        id: 0,
        createdBy: 0,
        status: -1,
        date: new Date(dayjs()),
        duration: 0,
        durationValue: 0,
        startDate: new Date(dayjs()),
        endDate: new Date(dayjs()),
        payrollEventId: null,
        payrollEvent: null,
        affairId: null, // Utilisation directe de l'ID d'affaire
        copyAffairId: null,
        affair: null,
        missionId: null,
        mission: null,
        message: null,
      },
      dataFormEventAllowances: [],
      loaded: true,
      dialogDeleteReportMobile: {
        key: 0,
        isModal: false,
        header: " Supprimer le reporting",
        content: "Êtes-vous sûr de vouloir supprimer ce reporting ?",
        showCloseIcon: true,
        visible: false,
        // buttons: [{ buttonModel: { isPrimary: false, content: 'Supprimer définitivement', cssClass:'e-outline e-danger e-small' }, click: this.deleteReportMobileConfirm }, { buttonModel: { content: 'Annuler' }, click: this.deleteReportMobileCancel }],
        target: document.body,
        height: "auto",
        width: "300px",
        animationSettings: { effect: "Zoom" },
        closeOnEscape: true,
      },
    };
  },
  watch: {
    "dataFormInitialize.retrieveSchedule": function (val) {
      if (val) {
        this.dataFormInitialize.payrollEventId = null;
        this.dataFormInitialize.affairIds = [];
        this.dataFormInitialize.missionId = null;
      }
    },
  },
  computed: {
    ...mapGetters([
      "affairsList",
      "missionsList",
      "payAllowanceTypesList",
      "payrollEventsList",
      "institutionSelected",
      "userParametersList",
      "userCollaboratorId",
      "eventTypesList",
      "isCreatingReportDetail",
      "isUpdatingReportDetail",
      "missionsList",
      "companiesList",
    ]),
    collaboratorsList() {
      return this.$store.getters["collaboratorsList"].map((el) => {
        return { ...el, displayLabel: el.firstName + " " + el.lastName };
      });
    },
    missionsListFiltered() {
      return this.$store.getters["missionsList"].map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsList.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    hideDurationValue() {
      return this.userParametersList.find(
        (userParameter) => userParameter.key == "notUseValuatedHours"
      )?.value == "true"
        ? true
        : false;
    },
    dataFormReportsFiltered() {
      if (this.collaboratorIds.length > 0) {
        return this.dataFormReports.filter((elem) =>
          this.collaboratorIds.includes(elem.collaboratorId)
        );
      } else {
        return this.dataFormReports;
      }
    },
    groupActionList() {
      let groupAction = [];
      if (
        this.selectedRows.length === 0 &&
        this.dataFormReports.filter((elem) => !elem.id).length == 0
      ) {
        groupAction.push({
          text: "Prévisualiser",
          css: "e-primary",
          iconCss: "e-icons e-eye",
          value: 1,
        });
      } else if (this.dataFormReports.filter((elem) => !elem.id).length > 0) {
        groupAction.push({
          text: "Réinitialiser",
          css: "e-primary",
          iconCss: "e-icons e-eye",
          value: 3,
        });
        groupAction.push({
          text: "Annuler",
          css: "e-secondary e-outline",
          value: 2,
          iconCss: "e-icons e-close",
        });
      }
      return groupAction;
    },
    isPayrollEventValued() {
      const res = this.payrollEventsList.find(
        (el) => el.id == this.dataFormInitialize.payrollEventId
      ).isValued;
      if (!res) {
        this.dataFormInitialize.affairIds = [];
        this.dataFormInitialize.missionId = null;
      }
      return res;
    },
    collaboratorsListFiltered() {
      if (this.isReportsByCollaborators) {
        if (this.collaboratorIds.length > 0) {
          return this.collaboratorsList.filter((el) =>
            this.collaboratorIds.includes(el.id)
          );
        } else {
          return this.collaboratorsList;
        }
      } else {
        return [this.userCollaborator];
      }
    },
    // VERSION MOBILE
    selectedCollaborator() {
      return this.collaboratorsList.find(
        (el) => el.id == this.$store.getters["userCollaborator"]?.id
      );
    },
    missionFields() {
      return {
        dataSource: this.missionsList.map((mission) => {
          return {
            ...mission,
            parentId: mission.parentId || null,
            hasChildren: this.$store.getters.missionsList.some(
              (m) => m.parentId == mission.id
            ),
          };
        }),
        value: "id",
        text: "label",
        parentValue: "parentId",
        hasChildren: "hasChildren",
      };
    },
  },
  async created() {
    await this.getEventTypes({});
    await this.getCollaborators({});
    await this.getPayAllowanceTypes({});
    await this.getPayrollEvents({});
    await this.getAffairs({});
    await this.getMissions({});
    this.isReportsByCollaborators
      ? (this.dataForm.collaboratorId = null)
      : (this.dataForm.collaboratorId = this.userCollaboratorId);
    this.setHeaderData();
    this.changeDataHeader();

    // VERSION MOBILE
    this.dataFormEventInitialize();
    this.dataFormEventAllowances = this.payAllowanceTypesList.map(
      (allowanceType) => {
        let allowanceValue = 0;
        if (
          this.dataFormEvent.allowances &&
          this.dataFormEvent.allowances.length > 0 &&
          this.dataFormEvent.allowances.find(
            (allowance) => allowance.id == allowanceType.id
          )
        ) {
          let dataAllowance = this.dataFormEvent.allowances.find(
            (allowance) => allowance.id == allowanceType.id
          );
          allowanceValue =
            allowanceType.quantificationType == 0
              ? dataAllowance.checkbox
              : allowanceType.quantificationType == 1
              ? dataAllowance.quantity
              : dataAllowance.amount;
        }
        return {
          ...allowanceType,
          value: allowanceValue,
        };
      }
    );
    this.setReportDetailsMobile();
    // END VERSION MOBILE
  },
  mounted() {
    if (this.$route.path === "/report/my-reports") {
      this.dataFormInitialize.collaboratorIds = this.userCollaboratorId
        ? [this.userCollaboratorId]
        : [];
    }
    if (
      this.$route.meta.mode == "single" &&
      localStorage.getItem("userCollaboratorId")
    ) {
      this.dataFormInitialize.collaboratorIds = [
        parseInt(localStorage.getItem("userCollaboratorId")),
      ];
      this.dataForm.collaboratorIds = [
        parseInt(localStorage.getItem("userCollaboratorId")),
      ];
    }
    this.formObj = new FormValidator("#form-report-details", this.options);
    let filter = this.userParametersList.find(
      (el) =>
        el.key ==
        (this.isReportsByCollaborators
          ? "editListReportDateRange"
          : "editMyListReportDateRange")
    );
    if (filter) {
      let startDate = dayjs(JSON.parse(filter.value).startDate)
        // .startOf(period)
        .format("YYYY-MM-DD");
      let endDate = dayjs(JSON.parse(filter.value).endDate)
        // .endOf(period)
        .format("YYYY-MM-DD");
      this.setDates({
        startDate: startDate,
        endDate: endDate,
      });
    } else {
      this.setDates({
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
    if (!this.isReportsByCollaborators) this.actionInitialize();
    window.addEventListener("resize", this.updateIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  methods: {
    // GLOBAL
    ...mapActions([
      "getEventTypes",
      "getCollaborators",
      "getCollaboratorById",
      "getAffairs",
      "getMissions",
      "getMissionsByAffairId",
      "getPayrollEvents",
      "getReportDetails",
      "getPayAllowanceTypes",
      "createReportDetail",
      "updateReportDetail",
      "deleteReportDetail",
      "updateReportDetailsPayAllowanceType",
      "getEventsByCollaboratorId",
      "createOrUpdateUserParameter",
      "getReportDetailsMinified",
    ]),
    formatDate,
    payrollEventTranslate,
    affairTranslate,
    reportStatusTranslate,
    changeTimeRange() {
      let diffOrigine = (
        dayjs(this.dataFormEvent.endDate).diff(
          dayjs(this.dataFormEvent.startDate),
          "minutes"
        ) / 60
      ).toFixed(2);
      let newDiff = diffOrigine;

      if (diffOrigine < 0) {
        this.dataFormEvent.endDate = new Date(
          dayjs(this.dataFormEvent.endDate).add(1, "day")
        );
        let newDiff = (
          dayjs(this.dataFormEvent.endDate).diff(
            dayjs(this.dataFormEvent.startDate),
            "minutes"
          ) / 60
        ).toFixed(2);
      } else if (diffOrigine > 24) {
        this.dataFormEvent.endDate = new Date(
          dayjs(this.dataFormEvent.endDate).subtract(1, "day")
        );
        let newDiff = (
          dayjs(this.dataFormEvent.endDate).diff(
            dayjs(this.dataFormEvent.startDate),
            "minutes"
          ) / 60
        ).toFixed(2);
      }

      this.$nextTick(() => {
        const popupTimer = document.querySelector("#quickTimer");
        popupTimer.innerHTML = newDiff + "h";
        const popupTimeRanger = document.querySelector("#quickTimeRanger");
        if (this.selectedCollaborator.reportEntryMode == 1) {
          popupTimeRanger.innerHTML =
            "(" +
            dayjs(this.dataFormEvent.startDate).format("HH:mm") +
            " - " +
            dayjs(this.dataFormEvent.endDate).format("HH:mm") +
            ")";
        } else {
          popupTimeRanger.innerHTML = "";
        }
      });
      this.dataFormEvent.duration = newDiff;
      this.dataFormEvent.durationValue = newDiff;
    },
    // DESKTOP
    setHeaderData(reportEntryModeHours = false) {
      const staticColumns = [
        {
          type: "string",
          field: "id",
          headerText: "Id",
          width: 50,
          maxWidth: 50,
          visible: false,
        },
        {
          type: "number",
          field: "keyIndex",
          headerText: "keyIndex",
          width: 100,
          maxWidth: 100,
          visible: false,
          isPrimaryKey: true,
        },
        {
          type: "string",
          field: "status",
          headerText: "Statut",
          direction: "Descending",
          width: 120,
          maxWidth: 120,
          allowFiltering: false,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          template: "statusTemplate",
          editTemplate: () => {
            return {
              template: CellRendererEditStatus,
            };
          },
          nature: "reportingStatus",
        },
        {
          type: "number",
          field: "createdBy",
          headerText: "Origine",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          width: 75,
          template: "origineTemplate",
          editTemplate: () => {
            return {
              template: CellRendererEditOrigine,
            };
          },
          textAlign: "center",
          edit: {
            source: this.usersList,
            fields: { text: "firstName", value: "id" },
            type: "dropdown",
            name: "reportDropdownCollaboratorsList",
          },
        },
        {
          type: "date",
          field: "reportDate",
          headerText: "Date",
          width: 110,
          maxWidth: 110,
          template: "dateYmdTemplate",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          defaultValue: new Date(),
          edit: { type: "datepicker", name: "reportDate" },
          validationRules: {
            required: true,
          },
        },
        {
          type: "number",
          field: "collaboratorId",
          headerText: "Collaborateur",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: this.isReportsByCollaborators,
          valueAccessor: "dropdown",
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown",
            name: "reportDropdownCollaboratorsList",
          },
        },
        {
          type: "number",
          field: "payrollEventId",
          headerText: "Évènement",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          showFooterSum: false,
          edit: {
            source: this.payrollEventsList,
            fields: { text: "label", value: "id" },
            type: "dropdown",
            name: "dropdownPayrollEventsList",
          },
        },
        {
          type: "number",
          field: "affairId",
          headerText: "Affaire",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.affairsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdowndependencies",
            name: "dropdownaffair",
            target: "dropdownmission",
            uid: "viewEditReportAffair",
          },
        },
        {
          type: "number",
          field: "copyAffairId",
          headerText: "Zone de l'affaire",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: false,
          showInColumnChooser: true,
          visible: false,
          valueAccessor: "dropdown",
          template: "affairZoneTemplate",
          availableExport: true,
          edit: {
            source: this.affairsList,
            fields: { text: "zone", value: "id" },
            type: "dropdowndependencies",
            name: "dropdownaffair",
            target: "dropdownmission",
            uid: "viewEditReportAffair",
          },
        },
        {
          type: "number",
          field: "missionId",
          headerText: "Tâche",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.missionsListFiltered,
            fields: {
              text: "label",
              value: "id",
              parentValue: "parentId",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.missionsListFiltered,
            },
            type: "dropdowntree",
            name: "dropdownmission",
            origine: "dropdownaffair",
            uid: "viewEditReportMission",
          },
        },
        {
          type: "date",
          field: "startDate",
          headerText: "Début",
          template: "heureHmTemplate",
          width: 95,
          maxWidth: 95,
          textAlign: "center",
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: reportEntryModeHours,
          defaultValue: new Date(),
          edit: {
            type: "time",
            name: "reportStartDate",
          },
        },
        {
          type: "date",
          field: "endDate",
          headerText: "Fin",
          template: "heureHmTemplate",
          width: 95,
          maxWidth: 95,
          textAlign: "center",
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: reportEntryModeHours,
          defaultValue: new Date(),
          edit: {
            type: "time",
            name: "reportEndDate",
          },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "duration",
          headerText: "Durée réelle",
          width: 90,
          validationRules: {
            required: true,
            min: [
              (args) => {
                return args["value"] > 0;
              },
              "Renseigner une durée supérieure à 0",
            ],
          },
          maxWidth: 90,
          textAlign: "center",
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: 1,
          edit: {
            type: "numericTextBox",
            name: "numericTextBoxDuration",
            target: "numericTextBoxDurationValue",
          },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "durationValue",
          headerText: "Durée valorisée",
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: 1,
          edit: {
            type: "numericTextBox",
            name: "numericTextBoxDurationValue",
          },
        },
        {
          type: "string",
          field: "message",
          headerText: "Commentaire",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          edit: { type: "textarea", name: "message" },
        },
      ];

      const allowanceTypeColumns = this.payAllowanceTypesList.map(
        (allowanceType) => {
          let columnConfig = {
            field: `allowance_${allowanceType.id}`,
            headerText: allowanceType.shortLabel,
            allowGrouping: false,
            showInColumnChooser: true,
            textAlign: "center",
            visible: true,
            width: 50,
            maxWidth: 100,
          };

          if (allowanceType.quantificationType === 1) {
            columnConfig = {
              ...columnConfig,
              type: "number",
              allowFiltering: false,
              allowSorting: false,
              allowEditing: true,
              edit: {
                source: null,
                params: {
                  decimals: 1,
                  min: 0,
                  max: null,
                  placeholder: allowanceType.label,
                  format: "##.##",
                  showSpinButton: false,
                  step: 0.1,
                },
                type: "numericTextBox",
                name: "numericTextBoxAllowance_" + allowanceType.id,
              },
            };
          } else if (allowanceType.quantificationType === 0) {
            columnConfig = {
              ...columnConfig,
              type: "boolean",
              allowFiltering: false,
              allowSorting: false,
              allowEditing: true,
              valueAccessor: "boolean",
              editType: "booleanedit",
            };
          }
          return columnConfig;
        }
      );

      const actionsColumn = {
        type: "commands",
        commands: this.commands,
        headerText: "Actions",
        width: 210,
      };

      this.headerData = [
        ...staticColumns,
        ...allowanceTypeColumns,
        actionsColumn,
      ];

      if (this.hideDurationValue) {
        this.headerData.splice(
          this.headerData.findIndex((el) => el.field == "durationValue"),
          1
        );
      }
      this.headerLoaded = true;
    },
    async actionInitialize(action = "initialize") {
      this.dataFormReports = this.dataFormReports.filter((elem) => elem.id);
      this.dataFormReportsLoading = true;
      const { retrieveSchedule } = this.dataFormInitialize;
      let allDates = this.dataFormInitialize.dateRangeArray;
      let collaboratorIds =
        this.dataFormInitialize.collaboratorIds.length > 0
          ? this.dataFormInitialize.collaboratorIds
          : !this.isReportsByCollaborators
          ? [this.userCollaboratorId]
          : this.collaboratorsListFiltered.map((elem) => {
              return elem.id;
            });
      let affairIds =
        this.dataFormInitialize.affairIds.length > 0
          ? this.dataFormInitialize.affairIds
          : [null];
      this.collaboratorFieldInvalid = false;

      try {
        let eventsPromise = retrieveSchedule
          ? this.getEventsByCollaboratorId({
              startDate: allDates[0],
              endDate: allDates[allDates.length - 1],
              collaboratorIds: collaboratorIds,
            })
          : Promise.resolve([]);
        let [events] = await Promise.all([eventsPromise]);

        let coveredCombinations = new Array();
        // reports.forEach((report) => {
        //   const date = dayjs(report.startDate).format("YYYY-MM-DD");
        //   const comboKey = `${date}-${report.collaboratorId}-${report.affairId}`;
        //   coveredCombinations.push(comboKey);
        //   this.dataFormReports.push(this.createReportObject(report, true));
        // });
        events.forEach((event) => {
          if (dayjs(event.endDate).isAfter(allDates[allDates.length - 1])) {
            event.endDate = new Date(allDates[allDates.length - 1]);
          }
          if (dayjs(event.startDate).isBefore(allDates[0])) {
            event.startDate = new Date(allDates[0]);
          }
          //for loop on event.collaboratorIds
          let collaboratorIdsFiltered = event.collaboratorIds.filter((elem) =>
            collaboratorIds.includes(elem)
          );
          for (let i = 0; i < collaboratorIdsFiltered.length; i++) {
            //For loop on all days between start and end date
            let currentDate = dayjs(event.startDate);
            while (currentDate.isBefore(event.endDate)) {
              const date = currentDate.format("YYYY-MM-DD");
              const comboKey = `${date}-${collaboratorIdsFiltered[i]}-${event.affairId}-${event.id}`; //-${event.affairId}`; //-${event.id}`;
              if (
                this.eventTypesList.find((el) => el.id == event.eventTypeId) &&
                this.eventTypesList.find((el) => el.id == event.eventTypeId)
                  .payrollEventId
              ) {
                // On remonde que les événements de planning associés à un événement lié à une variable de paie
                coveredCombinations.push(comboKey);
                if (action == "create") {
                  const selectedCollab = this.collaboratorsList.find(
                    (elem) => elem.id == collaboratorIdsFiltered[i]
                  );
                  this.createReportDetail({
                    reportDetail: {
                      collaboratorId: collaboratorIdsFiltered[i],
                      duration: event.hoursDay
                        ? event.hoursDay
                        : Number(
                            (
                              dayjs(event.endDate).diff(
                                dayjs(event.startDate),
                                "minutes"
                              ) / 60
                            ).toFixed(2)
                          ) < 24
                        ? Number(
                            (
                              dayjs(event.endDate).diff(
                                dayjs(event.startDate),
                                "minutes"
                              ) / 60
                            ).toFixed(2)
                          )
                        : selectedCollab && selectedCollab.hoursByDay
                        ? selectedCollab.hoursByDay
                        : 0,
                      durationValue: event.hoursDay
                        ? event.hoursDay
                        : Number(
                            (
                              dayjs(event.endDate).diff(
                                dayjs(event.startDate),
                                "minutes"
                              ) / 60
                            ).toFixed(2)
                          ) < 24
                        ? Number(
                            (
                              dayjs(event.endDate).diff(
                                dayjs(event.startDate),
                                "minutes"
                              ) / 60
                            ).toFixed(2)
                          )
                        : selectedCollab && selectedCollab.hoursByDay
                        ? selectedCollab.hoursByDay
                        : 0,
                      // duration: event.hoursDay ? event.hoursDay : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
                      // durationValue: event.hoursDay ? event.hoursDay : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
                      payrollEventId: this.eventTypesList.find(
                        (el) => el.id == event.eventTypeId
                      ).payrollEventId,
                      affairId: event.affairId,
                      copyAffairId: event.affairId,
                      missionId: event.missionId,
                      startDate: this.timezoneSchedule.removeLocalOffset(
                        new Date(dayjs(date).format("YYYY-MM-DDTHH:mm:ss"))
                      ),
                      endDate: this.timezoneSchedule.removeLocalOffset(
                        new Date(dayjs(date).format("YYYY-MM-DDTHH:mm:ss"))
                      ),
                      message: event.comments || "",
                      institutionId: this.institutionSelected.id,
                    },
                  });
                } else {
                  this.dataFormReports.push(
                    this.createEventAsReportObject(
                      event,
                      collaboratorIdsFiltered[i],
                      date
                    )
                  );
                }
              }
              currentDate = currentDate.add(1, "day");
            }
          }
        });
        if (!retrieveSchedule) {
          allDates.forEach((date) => {
            collaboratorIds.forEach((collabId) => {
              affairIds.forEach((affairId) => {
                const comboKey = `${date}-${collabId}-${affairId}`;
                // if (!coveredCombinations.has(comboKey)) {
                this.dataFormReports.push(
                  this.createDefaultReportObject(date, collabId, affairId)
                );
                // }
              });
            });
          });
        }
      } catch (error) {
        console.error("Error initializing reports: ", error);
      }
      this.dataFormReportsLoading = false;
    },
    changeDataHeader() {
      let result = this.dataFormInitialize.collaboratorIds.some((id) => {
        const collaborator = this.collaboratorsList.find((c) => c.id === id);
        return collaborator ? collaborator.reportEntryMode !== 0 : false;
      });
      if (
        !this.dataFormInitialize.collaboratorIds.length &&
        this.collaboratorsList.some((c) => c.reportEntryMode !== 0)
      ) {
        result = true;
      }
      this.headerLoaded = false;
      this.setHeaderData(result);
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    createReportObject(detail, isReport) {
      return {
        keyIndex: this.genererChaineAleatoire(),
        id: isReport ? detail.id : 0,
        createdBy: isReport ? detail.createdBy : 0,
        status: isReport ? detail.status : 0,
        reportDate: dayjs(detail.startDate).format("YYYY-MM-DD"),
        startDate: dayjs(detail.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(detail.endDate).format("YYYY-MM-DDTHH:mm:ss"),
        collaboratorId: detail.collaboratorId,
        payrollEventId: isReport ? detail.payrollEventId : detail.eventTypeId,
        affairId: detail.affairId,
        copyAffairId: detail.affairId,
        missionId: detail.missionId,
        duration: detail.duration,
        durationValue: detail.durationValue,
        message: detail.message,
        eventId: detail.eventId,
        interventionId: detail.interventionId,
      };
    },
    createEventAsReportObject(event, collaboratorId, date) {
      const selectedCollab = this.collaboratorsList.find(
        (elem) => elem.id == collaboratorId
      );
      return {
        id: 0,
        keyIndex: this.genererChaineAleatoire(),
        reportDate: dayjs(date).format("YYYY-MM-DD"),
        startDate: dayjs(date).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(date).format("YYYY-MM-DDTHH:mm:ss"),
        duration: event.hoursDay
          ? event.hoursDay
          : Number(
              (
                dayjs(event.endDate).diff(dayjs(event.startDate), "minutes") /
                60
              ).toFixed(2)
            ) < 24
          ? Number(
              (
                dayjs(event.endDate).diff(dayjs(event.startDate), "minutes") /
                60
              ).toFixed(2)
            )
          : selectedCollab && selectedCollab.hoursByDay
          ? selectedCollab.hoursByDay
          : 0,
        durationValue: event.hoursDay
          ? event.hoursDay
          : Number(
              (
                dayjs(event.endDate).diff(dayjs(event.startDate), "minutes") /
                60
              ).toFixed(2)
            ) < 24
          ? Number(
              (
                dayjs(event.endDate).diff(dayjs(event.startDate), "minutes") /
                60
              ).toFixed(2)
            )
          : selectedCollab && selectedCollab.hoursByDay
          ? selectedCollab.hoursByDay
          : 0,
        // duration: event.hoursDay ? event.hoursDay : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
        // durationValue: event.hoursDay ? event.hoursDay : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
        // duration: event.hoursDay ? event.hoursDay : (dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") / 60).toFixed(2) <= 24 ? (dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") / 60).toFixed(2) : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
        // durationValue: event.hoursDay ? event.hoursDay : (dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") / 60).toFixed(2) <= 24 ? (dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") / 60).toFixed(2) : selectedCollab && selectedCollab.hoursByDay ? selectedCollab.hoursByDay : 0,
        status: -2, // Un planning est par defaut suggéré
        collaboratorId: collaboratorId,
        payrollEventId: this.eventTypesList.find(
          (el) => el.id == event.eventTypeId
        ).payrollEventId,
        affairId: event.affairId,
        copyAffairId: event.affairId,
        missionId: event.missionId,
        message: event.comments || "",
        isFacturable: false,
        eventId: event.id,
        createdBy: event.createdBy,
      };
    },
    createDefaultReportObject(date, collaboratorId, affairId) {
      return {
        keyIndex: this.genererChaineAleatoire(),
        id: 0,
        createdBy: 0,
        status: -1,
        reportDate: date,
        startDate: dayjs(date).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(date).format("YYYY-MM-DDTHH:mm:ss"),
        collaboratorId: collaboratorId,
        payrollEventId: this.dataFormInitialize.payrollEventId,
        affairId: affairId, // Utilisation directe de l'ID d'affaire
        copyAffairId: affairId, // Utilisation directe de l'ID d'affaire
        missionId: this.dataFormInitialize.missionId,
        duration: 0,
        durationValue: 0,
        message: null,
        eventId: null,
        interventionId: null,
      };
    },
    payrollEventIsValued(payrollEventId) {
      if (!payrollEventId) {
        return true;
      } else {
        return this.payrollEventsList.find((el) => el.id == payrollEventId)
          .isValued;
      }
    },
    async commandClick(args) {
      if (args.commandColumn.type === "Cancel") {
        this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      } else if (args.commandColumn.type === "Dupliquer") {
        const selectedRow =
          this.$refs.overviewgrid.$refs.overviewgrid.getSelectedRecords();
        const newReport = { ...selectedRow[0] };
        newReport.id = 0;
        newReport.status = -1;
        newReport.startDate = dayjs(newReport.startDate);
        newReport.endDate = dayjs(newReport.endDate);
        newReport.keyIndex = this.genererChaineAleatoire();
        newReport.eventId = null;
        newReport.interventionId = null;
        this.dataFormReports.push(newReport);
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      } else if (args.commandColumn.type === "Ajouter") {
        const selectedRow =
          this.$refs.overviewgrid.$refs.overviewgrid.getSelectedRecords();
        const newReport = { ...selectedRow[0] };
        newReport.id = 0;
        newReport.status = -1;
        newReport.startDate = dayjs(newReport.startDate);
        newReport.endDate = dayjs(newReport.endDate);
        newReport.keyIndex = this.genererChaineAleatoire();
        newReport.missionId = null;
        newReport.affairId = null;
        newReport.copyAffairId = null;
        newReport.payrollEventId = null;
        newReport.duration = 0;
        newReport.durationValue = 0;
        newReport.message = null;
        newReport.eventId = null;
        newReport.interventionId = null;
        this.dataFormReports.push(newReport);
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
    },
    genererChaineAleatoire() {
      const dateActuelle = new Date();
      const timestamp = dateActuelle.getTime().toString();
      let chaineAleatoire = "";

      for (let i = 0; i < timestamp.length; i++) {
        const chiffreAleatoire = Math.floor(Math.random() * 10);
        chaineAleatoire += (parseInt(timestamp[i]) + chiffreAleatoire) % 10;
      }

      chaineAleatoire += "-" + this.reportIndex++;
      return chaineAleatoire;
    },
    prepareReportData(args) {
      const collaborator = this.collaboratorsList.find(
        (c) => c.id === args.data.collaboratorId
      );
      return {
        id: args.data.id,
        startDate:
          collaborator.reportEntryMode === 0
            ? dayjs(args.data.reportDate).format("YYYY-MM-DDTHH:mm:ss")
            : dayjs(args.data.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate:
          collaborator.reportEntryMode === 0
            ? dayjs(args.data.reportDate).format("YYYY-MM-DDTHH:mm:ss")
            : dayjs(args.data.endDate).format("YYYY-MM-DDTHH:mm:ss"),
        reportDate: dayjs(args.data.startDate).format("YYYY-MM-DD"),
        collaboratorId: args.data.collaboratorId,
        payrollEventId: args.data.payrollEventId,
        affairId: args.data.affairId,
        copyAffairId: args.data.affairId,
        missionId:
          Array.isArray(args.data.missionId) && args.data.missionId[0]
            ? args.data.missionId[0].toString()
            : args.data.missionId
            ? args.data.missionId
            : null,
        duration: args.data.duration,
        durationValue: args.data.durationValue,
        message: args.data.message || null,
        isFacturable: true,
        status: args.data.status,
        institutionId: this.institutionSelected.id,
        keyIndex: args.data.keyIndex,
        eventId: args.data.eventId ? args.data.eventId : null,
        interventionId: args.data.interventionId
          ? args.data.interventionId
          : null,
      };
    },
    setSelectedRowsIndexes(val) {
      this.selectedRowsIndexes = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    setDates(args, saveUserParameters = false) {
      if (saveUserParameters) {
        this.createOrUpdateUserParameter({
          label: this.isReportsByCollaborators
            ? "Dates de la liste d'édition des reportings"
            : "Dates de ma liste d'édition des reportings",
          key: this.isReportsByCollaborators
            ? "editListReportDateRange"
            : "editMyListReportDateRange",
          value: JSON.stringify({
            startDate: args.startDate,
            endDate: args.endDate,
          }),
        });
      }
      this.dataFormInitialize.dateRangeArray = [];
      this.dataFormInitialize.dateRange = [
        new Date(args.startDate).toISOString(),
        new Date(args.endDate).toISOString(),
      ];
      let currentDate = dayjs(args.startDate);
      while (
        currentDate.isBefore(args.endDate) ||
        currentDate.isSame(args.endDate)
      ) {
        this.dataFormInitialize.dateRangeArray.push(
          currentDate.format("YYYY-MM-DD")
        );
        currentDate = currentDate.add(1, "day");
      }
      let allDates = this.dataFormInitialize.dateRangeArray;
      let collaboratorIds =
        this.dataFormInitialize.collaboratorIds.length > 0
          ? this.dataFormInitialize.collaboratorIds
          : !this.isReportsByCollaborators
          ? this.userCollaboratorId
            ? [this.userCollaboratorId]
            : []
          : this.collaboratorsListFiltered.map((elem) => {
              return elem.id;
            });

      this.getReportDetails({
        startDate: allDates[0],
        endDate: allDates[allDates.length - 1],
        collaboratorIds: collaboratorIds ? collaboratorIds : [],
        route: "collaborator",
      }).then((res) => {
        this.dataFormReports = [];
        res.forEach((report) => {
          this.dataFormReports.push(this.createReportObject(report, true));
        });
      });
    },
    async deleteReport(args) {
      if (args.data[0].id > 0 && args.data[0].status >= 0) {
        return this.deleteReportDetail({ reportDetailIds: [args.data[0].id] });
      }
      this.$refs.overviewgrid.$refs.overviewgrid.clearSelection();
    },
    async actionBegin(args) {
      if (args.rowData && args.rowData.status === 3) {
        args.cancel = true;
        this.$bvToast.toast(
          "Ce reporting est déjà validé et ne peut pas être modifié.",
          {
            title: "Modification interdite",
            variant: "warning",
            solid: true,
          }
        );
        return;
      }
      if (
        args.rowData &&
        (args.rowData.status === -2 ||
          args.rowData.status === -1 ||
          args.rowData.status === 0 ||
          args.rowData.status === 1 ||
          args.rowData.status === 2)
      ) {
        args.row.querySelectorAll(".e-duplicatebutton")[0].style.display =
          "none";
        args.row.querySelectorAll(".e-addbutton")[0].style.display = "none";
      }

      try {
        if (args.requestType === "delete") {
          if (args.data.status === 3) {
            this.$bvToast.toast(
              "Vous ne pouvez pas supprimer un reporting validé.",
              {
                title: "Attention",
                variant: "Warning",
                solid: true,
              }
            );
          } else {
            await this.deleteReport(args);
          }
        } else if (args.requestType === "save") {
          const reportDetailData = this.prepareReportData(args);
          const reportAllowanceTypes = this.payAllowanceTypesList
            .map((reportAllowanceTypes) => {
              const allowanceValue =
                args.data[`allowance_${reportAllowanceTypes.id}`];
              return {
                quantity:
                  typeof allowanceValue === "number" ? allowanceValue : 0,
                amount: typeof allowanceValue === "number" ? allowanceValue : 0,
                checkbox:
                  typeof allowanceValue === "boolean" ? allowanceValue : false,
                payAllowanceTypeId: reportAllowanceTypes.id,
              };
            })
            .filter(
              (allowance) =>
                allowance.checkbox ||
                allowance.amount !== 0 ||
                allowance.quantity !== 0
            );

          if (args.data.status == -1 || args.data.status == -2) {
            reportDetailData.status = 0;
          }
          if (args.action === "edit") {
            if (reportDetailData.startDate && reportDetailData.endDate) {
              this.getCollaboratorById({
                collaboratorId: reportDetailData.collaboratorId,
              }).then((res) => {
                this.collaborator = res;

                this.getReportDetailsMinified({
                  startDate: dayjs(reportDetailData.startDate)
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss"),
                  endDate: dayjs(reportDetailData.endDate)
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm:ss"),
                  collaboratorIds: [this.collaborator.id],
                  globalReport: false,
                  updateState: false,
                }).then((res1) => {
                  this.totalHoursDay = res1.reduce((total, objet) => {
                    return (
                      total +
                      (reportDetailData.id == objet.id ? 0 : objet.duration)
                    );
                  }, 0);

                  this.getReportDetailsMinified({
                    startDate: dayjs(reportDetailData.startDate)
                      .startOf("week")
                      .format("YYYY-MM-DD HH:mm:ss"),
                    endDate: dayjs(reportDetailData.endDate)
                      .endOf("week")
                      .format("YYYY-MM-DD HH:mm:ss"),
                    collaboratorIds: [this.collaborator.id],
                    globalReport: false,
                    updateState: false,
                  }).then((res2) => {
                    this.totalHoursWeek = res2.reduce(
                      (total, objet) =>
                        total +
                        (reportDetailData.id == objet.id ? 0 : objet.duration),
                      0
                    );

                    const outOfLimitWeek =
                      reportDetailData.duration + this.totalHoursWeek >
                      this.collaborator.contract.hoursWeekLimit;
                    const outOfLimitDay =
                      reportDetailData.duration + this.totalHoursDay >
                      this.collaborator.contract.hoursDayLimit;
                    const warningDay =
                      reportDetailData.duration + this.totalHoursDay >
                        this.collaborator.contract.hoursDayWarning &&
                      reportDetailData.duration + this.totalHoursDay <=
                        this.collaborator.contract.hoursDayLimit;
                    const warningWeek =
                      reportDetailData.duration + this.totalHoursWeek >
                        this.collaborator.contract.hoursWeekWarning &&
                      reportDetailData.duration + this.totalHoursWeek <=
                        this.collaborator.contract.hoursWeekLimit;

                    if (!args.data.id && args.action === "edit") {
                      this.createReportDetail({
                        reportDetail: {
                          ...reportDetailData,
                          duration: outOfLimitWeek
                            ? this.collaborator.contract.hoursWeekLimit -
                              this.totalHoursWeek
                            : outOfLimitDay
                            ? this.collaborator.contract.hoursDayLimit -
                              this.totalHoursDay
                            : reportDetailData.duration,
                          durationValue: outOfLimitWeek
                            ? this.collaborator.contract.hoursWeekLimit -
                              this.totalHoursWeek
                            : outOfLimitDay
                            ? this.collaborator.contract.hoursDayLimit -
                              this.totalHoursDay
                            : reportDetailData.durationValue,
                        },
                      }).then((res) => {
                        this.updateReportDetailsPayAllowanceType({
                          reportDetailId: res.id,
                          reportAllowanceTypes: reportAllowanceTypes,
                        }).then((resUpdateReportDetailsPayAllowanceType) => {
                          this.$refs.overviewgrid.$refs.overviewgrid.setRowData(
                            args.data.keyIndex,
                            {
                              ...resUpdateReportDetailsPayAllowanceType,
                              id: res.id,
                              keyIndex: args.data.keyIndex,
                              reportDate:
                                resUpdateReportDetailsPayAllowanceType.startDate
                                  ? dayjs(
                                      resUpdateReportDetailsPayAllowanceType.startDate
                                    ).format("YYYY-MM-DD")
                                  : null,
                            }
                          );

                          this.dataFormReports = this.dataFormReports.map(
                            (report) => {
                              if (report.keyIndex === args.data.keyIndex) {
                                return {
                                  ...resUpdateReportDetailsPayAllowanceType,
                                  keyIndex: report.keyIndex,
                                  reportDate:
                                    resUpdateReportDetailsPayAllowanceType.startDate
                                      ? dayjs(
                                          resUpdateReportDetailsPayAllowanceType.startDate
                                        ).format("YYYY-MM-DD")
                                      : null,
                                };
                              }
                              return report;
                            }
                          );
                          // send notification
                          if (outOfLimitDay || outOfLimitWeek) {
                            this.$bvToast.toast(
                              `La durée saisie a été ajustée pour respecter les limites de votre contrat.`,
                              {
                                title: outOfLimitWeek
                                  ? "Nombre d'heures maximum par semaine dépassé"
                                  : "Nombre d'heures maximum par jour dépassé",
                                variant: "danger",
                                solid: true,
                              }
                            );
                          }
                          if (warningDay || warningWeek) {
                            this.$bvToast.toast(`Seuil d'alerte dépassé.`, {
                              title: `Vous avez dépassé le seuil d'alerte pour cette ${
                                warningWeek ? "semaine" : "journée"
                              }.`,
                              variant: "warning",
                              solid: true,
                            });
                          }
                        });
                      });
                    } else if (args.data.id && args.action === "edit") {
                      this.updateReportDetail({
                        reportDetail: {
                          ...reportDetailData,
                          status: parseInt(reportDetailData.status),
                          missionId: parseInt(reportDetailData.missionId),
                          duration: outOfLimitWeek
                            ? this.collaborator.contract.hoursWeekLimit -
                              this.totalHoursWeek
                            : outOfLimitDay
                            ? this.collaborator.contract.hoursDayLimit -
                              this.totalHoursDay
                            : reportDetailData.duration,
                          durationValue: outOfLimitWeek
                            ? this.collaborator.contract.hoursWeekLimit -
                              this.totalHoursWeek
                            : outOfLimitDay
                            ? this.collaborator.contract.hoursDayLimit -
                              this.totalHoursDay
                            : reportDetailData.durationValue,
                        },
                      }).then((res) => {
                        this.updateReportDetailsPayAllowanceType({
                          reportDetailId: res.id,
                          reportAllowanceTypes: reportAllowanceTypes,
                        }).then((resUpdateReportDetailsPayAllowanceType) => {
                          this.$refs.overviewgrid.$refs.overviewgrid.setRowData(
                            args.data.keyIndex,
                            {
                              ...resUpdateReportDetailsPayAllowanceType,
                              keyIndex: args.data.keyIndex,
                              reportDate:
                                resUpdateReportDetailsPayAllowanceType.startDate
                                  ? dayjs(
                                      resUpdateReportDetailsPayAllowanceType.startDate
                                    ).format("YYYY-MM-DD")
                                  : null,
                            }
                          );
                          this.dataFormReports = this.dataFormReports.map(
                            (report) => {
                              if (report.keyIndex === args.data.keyIndex) {
                                return {
                                  ...resUpdateReportDetailsPayAllowanceType,
                                  keyIndex: report.keyIndex,
                                  reportDate:
                                    resUpdateReportDetailsPayAllowanceType.startDate
                                      ? dayjs(
                                          resUpdateReportDetailsPayAllowanceType.startDate
                                        ).format("YYYY-MM-DD")
                                      : null,
                                };
                              }
                              return report;
                            }
                          );
                          // send notification
                          if (outOfLimitDay || outOfLimitWeek) {
                            this.$bvToast.toast(
                              `La durée saisie a été ajustée pour respecter les limites de votre contrat.`,
                              {
                                title: outOfLimitWeek
                                  ? "Nombre d'heures maximum par semaine dépassé"
                                  : "Nombre d'heures maximum par jour dépassé",
                                variant: "danger",
                                solid: true,
                              }
                            );
                          }
                          if (warningDay || warningWeek) {
                            this.$bvToast.toast(`Seuil d'alerte dépassé.`, {
                              title: `Vous avez dépassé le seuil d'alerte pour cette ${
                                warningWeek ? "semaine" : "journée"
                              }.`,
                              variant: "warning",
                              solid: true,
                            });
                          }
                        });
                      });
                    }
                  });
                });
              });
            }
          }
        }
      } catch (error) {
        console.error("Erreur lors de l'action sur le reporting:", error);
        this.$bvToast.toast(
          "Une erreur s'est produite lors de l'exécution de l'action.",
          {
            title: "Erreur",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    // VERSION MOBILE
    dataFormEventInitialize() {
      this.dataFormEvent = {
        ...this.dataFormEvent,
        id: 0,
        createdBy: 0,
        status: -1,
        duration: 0,
        durationValue: 0,
        payrollEventId: null,
        payrollEvent: null,
        affairId: null, // Utilisation directe de l'ID d'affaire
        copyAffairId: null,
        affair: null,
        missionId: null,
        mission: null,
        message: null,
      };
    },
    async setReportDetailsMobile(args) {
      if (args && args.value) {
        this.dataFormEvent.date = new Date(
          dayjs(args.value).format("YYYY-MM-DD")
        );
        this.dataFormEvent.startDate = new Date(
          dayjs(args.value).format("YYYY-MM-DD")
        );
        this.dataFormEvent.endDate = new Date(
          dayjs(args.value).format("YYYY-MM-DD")
        );
      }
      this.dataFormEventInitialize();
      const collaborator = this.collaboratorsList.find(
        (el) => el.id == this.$store.getters["userCollaborator"]?.id
      );
      if (this.dataFormEvent.date && collaborator?.id) {
        this.reportListByDay = [];
        try {
          // let eventsPromise = this.fetchEventsListByCollaboratorId({
          //   startDate: this.dataFormEvent.date,
          //   endDate: this.dataFormEvent.date,
          //   collaboratorIds: collaborator ? [collaborator.id] : [],
          // });
          // let [reports, events] = await Promise.all([
          //   reportsPromise,
          //   eventsPromise,
          // ]);

          let reportsPromise = this.getReportDetails({
            startDate: this.dataFormEvent.date,
            endDate: this.dataFormEvent.date,
            collaboratorIds: collaborator ? [collaborator.id] : [],
            route: "collaborator",
          });

          let [reports] = await Promise.all([reportsPromise]);
          reports.forEach((report) => {
            this.reportListByDay.push({
              ...report,
              payrollEvent: this.payrollEventsList.find(
                (payrollEvent) => payrollEvent.id == report.payrollEventId
              )
                ? this.payrollEventsList.find(
                    (payrollEvent) => payrollEvent.id == report.payrollEventId
                  )
                : "",
              // company: this.companiesList.find((company) => company.id == report.companyId) ? this.companiesList.find((company) => company.id == report.companyId) : "",
              affair: this.affairsList.find(
                (affair) => affair.id == report.affairId
              )
                ? this.affairsList.find(
                    (affair) => affair.id == report.affairId
                  )
                : "",
              mission:
                !report.missionId || report.missionId.length == 0
                  ? ""
                  : this.missionsList.find(
                      (mission) => mission.id == report.missionId
                    )
                  ? this.missionsList.find(
                      (mission) => mission.id == report.missionId
                    )
                  : "",
            });
          });

          //   let eventIdsIntoReports = reports.map((report) => report.eventId);

          //   events = events.filter(
          //     (event) => !eventIdsIntoReports.includes(event.id)
          //   );

          //   events.forEach((event) => {
          //     this.reportListByDay.push(
          //       this.createEventAsReportObjectMobile(
          //         {
          //           ...event,
          //           startDate: dayjs(this.dataFormEvent.date).format(
          //             "YYYY-MM-DD"
          //           ),
          //           endDate: dayjs(this.dataFormEvent.date).format("YYYY-MM-DD"),
          //           collaboratorId: collaborator.id,
          //         },
          //         false
          //       )
          //     );
          //   });
        } catch (error) {
          console.error("Error initializing reports: ", error);
        }
      }
    },
    filterAffairsList(args) {
      for (let index = 0; index < this.affairsList.length; index++) {
        const element = this.affairsList[index];
        if (
          !element.company ||
          (element.company.id !== this.dataFormEvent.companyId &&
            this.dataFormEvent.companyId)
        ) {
          document.querySelector(
            "#affair_" + element.id
          ).parentElement.style.display = "none";
        } else {
          document.querySelector(
            "#affair_" + element.id
          ).parentElement.style.display = "flex";
        }
      }
    },
    changePayrollEvent(event) {
      if (event.itemData) {
        this.dataFormEvent.payrollEvent = event.itemData;
        this.dataFormEvent.payrollEventId = event.itemData.id;
        this.dataFormEvent.color = event.itemData.color.slice(0, 7);
        if (!event.itemData.isValued) {
          this.dataFormEvent.company = null;
          this.dataFormEvent.companyId = null;
          this.dataFormEvent.affair = null;
          this.dataFormEvent.affairId = null;
          this.dataFormEvent.copyAffairId = null;
          this.dataFormEvent.mission = null;
          this.dataFormEvent.missionId = null;
        }
      }
      this.$forceUpdate();
    },
    changeCompany(event) {
      if (event.itemData) {
        if (this.dataFormEvent.company !== event.itemData && event.e) {
          this.dataFormEvent.mission = null;
          this.dataFormEvent.missionId = null;
        }
        let listFiltered = this.affairsList.filter(
          (el) => el.company && el.company.id == this.dataFormEvent.companyId
        );

        if (listFiltered.length == 1) {
          this.dataFormEvent.affair = listFiltered[0];
          this.dataFormEvent.affairId = listFiltered[0].id;
          this.dataFormEvent.copyAffairId = listFiltered[0].id;
        } else if (
          !listFiltered.find((el) => el.id == this.dataFormEvent.affairId) ||
          !listFiltered.length > 1
        ) {
          this.dataFormEvent.affair = null;
          this.dataFormEvent.affairId = null;
          this.dataFormEvent.copyAffairId = null;
        }

        this.dataFormEvent.company = event.itemData ? event.itemData : null;
      } else if (event.e) {
        this.dataFormEvent.company = null;
        this.dataFormEvent.companyId = null;
        this.dataFormEvent.affair = null;
        this.dataFormEvent.affairId = null;
        this.dataFormEvent.copyAffairId = null;
        this.dataFormEvent.mission = null;
        this.dataFormEvent.missionId = null;
      }
    },
    changeAffair(event) {
      if (event.itemData) {
        this.dataFormEvent.affair = event.itemData ? event.itemData : null;
        this.dataFormEvent.companyId =
          event.itemData && event.itemData.company
            ? event.itemData.company.id
            : this.dataFormEvent.companyId
            ? this.dataFormEvent.companyId
            : null;
      } else if (event.e) {
        this.dataFormEvent.affair = null;
        this.dataFormEvent.affairId = null;
        this.dataFormEvent.copyAffairId = null;
        this.dataFormEvent.mission = null;
        this.dataFormEvent.missionId = null;
      }
    },
    changeMission(event) {
      this.$forceUpdate();
    },
    changeDuration(event) {
      this.dataFormEvent.durationValue = event.value;
    },
    formatDateStr(str) {
      if (str) {
        return (
          dayjs(str).format("dddd D MMMM").charAt(0).toUpperCase() +
          dayjs(str).format("dddd D MMMM").slice(1)
        );
      } else {
        return "";
      }
    },
    async createReport() {
      let allowances = [...document.querySelectorAll('[id^="allowance_"]')].map(
        (elem) => {
          return {
            id: elem.id.split("_")[1],
            value:
              elem.type == "checkbox"
                ? elem.checked
                : elem.ej2_instances[0].value,
          };
        }
      );
      this.createReportDetail({
        reportDetail: {
          collaboratorId: this.collaboratorsList.find(
            (el) => el.id == this.$store.getters["userCollaborator"].id
          ).id,
          duration: this.dataFormEvent.duration,
          durationValue: this.dataFormEvent.durationValue,
          payrollEventId: this.dataFormEvent.payrollEventId,
          affairId: this.dataFormEvent.affairId,
          copyAffairId: this.dataFormEvent.affairId,
          missionId:
            this.dataFormEvent.missionId &&
            this.dataFormEvent.missionId.length > 0
              ? this.dataFormEvent.missionId[0].toString()
              : null,
          startDate: this.timezoneSchedule.removeLocalOffset(
            new Date(this.dataFormEvent.startDate)
          ),
          endDate: this.timezoneSchedule.removeLocalOffset(
            new Date(this.dataFormEvent.endDate)
          ),
          message: this.dataFormEvent.message,
          institutionId: this.institutionSelected.id,
        },
      }).then((res) => {
        this.updateReportDetailsPayAllowanceType({
          reportDetailId: res.id,
          reportAllowanceTypes: allowances.map((elem) => {
            let fullAllowance = this.payAllowanceTypesList.find(
              (allowance) => allowance.id == elem.id
            );
            return {
              payAllowanceTypeId: elem.id,
              checkbox:
                fullAllowance.quantificationType == 0 ? elem.value : null,
              quantity:
                fullAllowance.quantificationType == 1 ? elem.value : null,
              amount: fullAllowance.quantificationType == 2 ? elem.value : null,
            };
          }),
        });
        let titleToast = this.formatDateStr(this.dataFormEvent.startDate);
        titleToast += this.dataFormEvent.payrollEvent
          ? "\n" + this.dataFormEvent.payrollEvent.label
          : "";

        this.$bvToast.toast(titleToast, {
          title: "Reporting créé",
          variant: "success",
          solid: true,
        });
        this.setReportDetailsMobile();
        var element = document.getElementById("scrollContent"); // Remplacez 'votreId' par l'ID de votre élément
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          element.scrollTo({ top: 0, behavior: "smooth" });
        }
      });
    },
    deleteReportMobile(report) {
      let _this = this;

      this.dialogDeleteReportMobile.visible = true;
      this.dialogDeleteReportMobile.buttons = [
        {
          buttonModel: { content: "Annuler" },
          click: function () {
            _this.deleteReportMobileCancel();
          },
        },
        {
          buttonModel: {
            isPrimary: false,
            content: "Supprimer définitivement",
            cssClass: "e-danger",
          },
          click: function () {
            _this.deleteReportMobileConfirm(report);
          },
          isFlat: false,
        },
      ];
      this.dialogDeleteReportMobile.key++;
      this.$nextTick(() => {
        this.$refs.dialogDeleteReportMobile.show();
      });
    },
    async deleteReportMobileConfirm(report) {
      await this.deleteReportDetail({
        reportDetailIds: [report.id],
      });
      this.setReportDetailsMobile();
      this.$refs.dialogDeleteReportMobile.hide();
    },
    deleteReportMobileCancel: function () {
      this.$refs.dialogDeleteReportMobile.hide();
    },
    newEventType() {
      this.$router.push({ path: "/planning/settings/event-types" });
    },
    newCompany() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
    newAffair() {
      this.$router.push({
        name: "new-affair",
        params: {
          id: 0,
          companyId: this.dataFormEvent.company.id,
          title: "Nouvelle affaire",
          tips: "Nouvelle affaire",
          routeOrigine: "affairs",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-invalid .input-group,
.input-invalid .form-control,
.input-invalid .ejs-dropdownlist {
  border-color: #dc3545; // Couleur rouge pour les bordures d'input invalide
  background-color: #f8d7da; // Légère couleur de fond rouge
}

.input-invalid .form-label {
  color: #dc3545; // Texte rouge pour le label
}

.e-chip-list .e-chip:last-child {
  margin-right: 0px !important;
}

.no-padding-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.divider-full-width {
  // width: calc(100% + 2rem);
  // transform: translateX(-1rem);
}

.e-disabled {
  pointer-events: none;
}
</style>

<style lang="scss">
.error {
  border-color: #dc3545 !important;
}

.status-suggested {
  background-color: lightgrey !important;
}

.e-model-year {
  color: #fff !important;
}
</style>
