<template lang="pug">
  div.d-flex.flex-column
      HeaderGrid(:name="name" :uid="uid" :dateRanger="!schedulerMode == 'list' || switchIsValidation" userFilter="reportDetailFiltersByCollaborator" :planningOptionButton="schedulerMode == 'global' || schedulerMode == 'details'" :optionButton="schedulerMode == 'list'" :filterCollaborators="filterCollaborators"  :dateRangeDefault="dateRangeDefault" :schedulerMode="schedulerMode" :schedulerModeButton="schedulerModeButton" :addButton="addButton" :switchIsValidation="true" :isValidation="switchIsValidation" @updateIsValidation="setSwitchIsValidation" :addButton2="addButton2" :addButton2Icon="addButton2Icon" :searchButton="searchButton" :selectedRows="selectedRows" @setDates="setDates" @setCollaborators="setCollaborators" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="setSelectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :groupActionButton="groupActionButton" :groupActionButtonLabel="groupActionButtonLabel" :groupActionList="schedulerMode != 'global' ? groupActionList : []" @refreshRow="refreshRow" @clickActionGroup="clickActionGroup" @updateSchedulerMode="updateSchedulerMode" :indexSearch="indexSearch" :isMobile="isMobile" :homepageMode="homepageMode" :homepage="homepage")
      statCards.mb-0(v-if="switchIsValidation  && !isMobile")
        div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('all')")
            .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
                .card-list-stats-title
                    div
                        span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
                        span.card-list-stats-title-text TOUT
                    .card-list-stats-title-badge.bg-primary.text-white {{ getCountDataByStatus("all") }}
                .card-sum
                    | {{ getSumDataByStatus("all") }} 
                    span {{ getSumDataByStatus("all") > 0 ? ' Heures' : ' Heure' }}
        div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
            .card-list-stats(:class="[tabActive == status.value ? 'current' : '', 'border-'+status.class]")
                .card-list-stats-title
                    div
                        span.card-list-stats-title-badge.material-icons.mr-25(:class="'text-'+status.class") {{ status.icon }}
                        span.card-list-stats-title-text {{ status.label }}
                    .card-list-stats-title-badge.text-white(:class="'bg-'+status.class") {{ getCountDataByStatus(status.value) }}
                .card-sum
                    | {{ getSumDataByStatus(status.value) }}
                    span {{ getSumDataByStatus(status.value) > 0 ? ' Heures' : ' Heure' }}
      .w-100.d-flex.flex-column#scrollContent(style="overflow-x: scroll;" :style="'height: calc(100% - 58px);'")
          .text-center.flex-center.w-100(v-if='!headerLoaded || !dataLoaded')
              .loading-bg-inner
                  .loader
                      .outer
                      .middle
                      .inner
              .mt-5
                  br
                  br
                  br
                  | Chargement de la liste des reportings...
          view-report-details-scheduler(v-else-if="schedulerMode == 'global' || schedulerMode == 'details'" :schedulerMode="schedulerMode" :showDurations="showDurations" :showAllowances="showAllowances" :searchText="searchText" :isReportsByCollaborators="false" @setSelectedRows="setSelectedRows" :selectedRows="selectedRows" :collaboratorIds="collaboratorIds" :tabActive="tabActive.toString()" :switchIsValidation="switchIsValidation")
          sync-grid(v-else-if="schedulerMode == 'list' && switchIsValidation" ref="overviewgrid" :currentView="currentView" :currentDate="currentDate" :viewMode="viewMode" @changeCurrentView="changeCurrentView" @changeViewDates="changeViewDates" :gridData="dataFiltered" :collaboratorIds="collaboratorIds" :headerData="headerData" :allowFiltering="allowFiltering" @setSelectedRows="setSelectedRows" :groupSettings="groupSettings" :allowPaging="allowPaging")
          view-edit-report(v-else-if="schedulerMode == 'list' && !switchIsValidation" :isReportsByCollaborators="false" ref="overviewgrid" :uid="uid" :name="name" :headerData="headerData" :selectionSettings="selectionSettings" :editSettings="editSettings" :allowGrouping="allowGrouping" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :allowKeyboard="allowKeyboard" :allowFiltering="allowFiltering")
  </template>
<script>
import { mapGetters, mapActions } from "vuex";
import { reportingStatus } from "@/types/api-orisis/enums/enums";
import statCards from "@/components/global/grid/StatCards.vue";
import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import { isMobile as checkIfMobile } from "@/types/api-orisis/library/GlobalOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var weekday = require("dayjs/plugin/weekday");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
dayjs.extend(weekday);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import ViewReportDetailsScheduler from "@/components/report/ViewReportDetailsScheduler";
import ViewEditReport from "@/components/report/ViewEditReport.vue";

import DateRanger from "@/components/global/date-ranger/DateRanger.vue";
import { maximizeMinimizeClick } from "@syncfusion/ej2-vue-richtexteditor";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
    ViewReportDetailsScheduler,
    ViewEditReport,
    statCards,
    DateRanger,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      searchText: "",
      showAllowances: true,
      showDurations: true,
      switchIsValidation: false,
      indexSearch: 0,
      name: "report",
      uid: "myReports",
      statusList: reportingStatus.filter((el) => !el.notUse),
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      dateRangeDefault: [
        new Date(dayjs().startOf("week")),
        new Date(dayjs().endOf("week")),
      ],
      collaboratorIds: [],
      currentDate: new Date(),
      currentView: "TimelineWeek",
      viewMode: "compare",
      dateRanger: true,
      filterCollaborators: false,
      addButton: false,
      addButton2: false,
      addButton2Icon: "e-icons e-people",
      searchButton: true,
      tagguerButton: false,
      groupActionButton: true,
      groupActionButtonLabel: "Changer le statut",
      searchPlaceholder: "Rechercher un reporting",
      allowKeyboard: false,
      allowGrouping: true,
      allowFiltering: true,
      allowPaging: false,
      schedulerMode: "list",
      schedulerModeButton: true,
      dataLoaded: true,
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouveau reporting",
        titleEdit: "Modifier le reporting",
        titleField: "date",
      },
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
      groupSettings: {
        captionTemplate: "captionTemplate",
        columns: [],
      },
      isMobile: checkIfMobile(),
      newReportType: "single",
      dataFormInitialize: {
        dateRange: [
          new Date(dayjs().format("YYYY-MM-DD")),
          new Date(dayjs().format("YYYY-MM-DD")),
        ],
        dateRangeArray: [new Date().toISOString().slice(0, 10)],
        collaboratorIds: [],
        payrollEventId: 0,
        affairIds: [],
        missionId: 0,
        retrieveSchedule: true,
        // retrieveReportings: true,
      },
    };
  },
  async activated() {
    this.setHeaderData();
    this.indexSearch++;
    this.headerLoaded = true;
  },
  computed: {
    ...mapGetters([
      "collaboratorsList",
      "payrollEventsList",
      "reportDetailsMinifiedList",
      "reportDetailsList",
      "payAllowanceTypesList",
      "affairsList",
      "missionsList",
      "userParametersList",
      "eventTypesList",
      "companiesList",
    ]),
    groupActionList() {
      let groupAction = [];
      if (this.selectedRows.find((elem) => elem.status <= 1)) {
        groupAction.push({
          text:
            "Passer en validation (" +
            this.selectedRows.filter((elem) => elem.status <= 1).length +
            ")",
          iconCss: "e-icons e-send",
          css: "e-warning",
          value: 2,
        });
      }
      if (this.selectedRows.find((elem) => elem.status <= 2)) {
        groupAction.push({
          text:
            "Valider (" +
            this.selectedRows.filter((elem) => elem.status <= 2).length +
            ")",
          iconCss: "e-icons e-check",
          css: "e-success",
          value: 3,
        });
      }
      if (this.selectedRows.find((elem) => elem.status <= 2)) {
        groupAction.push({
          text:
            "Refuser (" +
            this.selectedRows.filter((elem) => elem.status <= 2).length +
            ")",
          iconCss: "e-icons e-circle-close",
          css: "e-danger",
          value: 4,
        });
      }
      if (this.selectedRows.find((elem) => elem.status <= 1)) {
        groupAction.push({
          text:
            "Supprimer (" +
            this.selectedRows.filter((elem) => elem.status <= 1).length +
            ")",
          iconCss: "e-icons e-trash",
          css: "e-danger",
          value: 5,
        });
      }
      return groupAction;
    },
    dataOrigine() {
      return this.$store.getters["reportDetailsListByCollaborator"];
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.collaboratorIds.length > 0) {
            return this.$store.getters["reportDetailsListByCollaborator"]
              .filter((elem) =>
                this.collaboratorIds.includes(elem.collaborator.id)
              )
              .map((el) => {
                el.collaborator
                  ? el.collaborator.firstName + " " + el.collaborator.lastName
                  : "Non affecté",
                  el.payAllowanceTypes?.forEach((allowance) => {
                    const allowanceKey = `allowance_${allowance.id}`;
                    el[allowanceKey] =
                      allowance.quantificationType === 1
                        ? allowance.quantity
                        : allowance.checkbox;
                  });

                return el;
              });
          } else {
            return this.$store.getters["reportDetailsListByCollaborator"].map(
              (el) => {
                el.collaborator
                  ? el.collaborator.firstName + " " + el.collaborator.lastName
                  : "Non affecté",
                  el.payAllowanceTypes?.forEach((allowance) => {
                    const allowanceKey = `allowance_${allowance.id}`;
                    el[allowanceKey] =
                      allowance.quantificationType === 1
                        ? allowance.quantity
                        : allowance.checkbox;
                  });

                return el;
              }
            );
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.collaboratorIds.length > 0) {
            return this.$store.getters["reportDetailsListByCollaborator"]
              .filter(
                (elem) =>
                  (statusInfos.query
                    ? statusInfos.query.includes(elem.status)
                    : elem.status === this.tabActive) &&
                  this.collaboratorIds.includes(elem.collaboratorId)
              )
              .map((el) => {
                el.collaborator
                  ? el.collaborator.firstName + " " + el.collaborator.lastName
                  : "Non affecté",
                  el.payAllowanceTypes?.forEach((allowance) => {
                    const allowanceKey = `allowance_${allowance.id}`;
                    el[allowanceKey] =
                      allowance.quantificationType === 1
                        ? allowance.quantity
                        : allowance.checkbox;
                  });

                return el;
              });
          } else {
            return this.$store.getters["reportDetailsListByCollaborator"]
              .filter((elem) =>
                statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive
              )
              .map((el) => {
                el.collaborator
                  ? el.collaborator.firstName + " " + el.collaborator.lastName
                  : "Non affecté",
                  el.payAllowanceTypes?.forEach((allowance) => {
                    const allowanceKey = `allowance_${allowance.id}`;
                    el[allowanceKey] =
                      allowance.quantificationType === 1
                        ? allowance.quantity
                        : allowance.checkbox;
                  });

                return el;
              });
          }
        }
      } else {
        return this.$store.getters["reportDetailsListByCollaborator"];
      }
    },
  },
  async created() {
    Promise.all([
      this.getPayrollEvents({}),
      this.getEventTypes({}),
      this.getPayAllowanceTypes({}),

      this.getAffairs({}),
      this.getMissions({}),
      this.getCollaborators({}),
      this.getCompanies({}),
    ]).then(() => {
      this.dataLoaded = true;
      this.headerLoaded = true;
    });
    if (this.isMobile) {
      this.schedulerMode = "list";
      this.switchIsValidation = false;
    } else {
      this.getUserParameterByKey({ key: "myReportingMode" }).then((res) => {
        this.schedulerMode = res.value ? res.value : "list";
      });

      this.getUserParameterByKey({ key: "myReportingIsValidation" }).then(
        (res) => {
          this.switchIsValidation = res.value ? res.value === "true" : false;
          if (this.switchIsValidation) {
            this.setDates({
              startDate: dayjs(this.dateRangeDefault[0]).format("YYYY-MM-DD"),
              endDate: dayjs(this.dateRangeDefault[1]).format("YYYY-MM-DD"),
            });
          }
        }
      );
    }
  },
  methods: {
    ...mapActions([
      "getUserParameterByKey",
      "createOrUpdateUserParameter",
      "getReportDetails",
      "getPayAllowanceTypes",
      "updateReport",
      "getCollaborators",
      "getCompanies",
      "getAffairs",
      "getMissions",
      "getEventTypes",
      "getPayrollEvents",
      "updateReportPayAllowanceType",
      "deleteReportDetail",
      "updateReportsStatus",
      "updateReportDetailsPayAllowanceType",
    ]),
    formatNumber,
    formatCurrency,
    setColumnsByObject,
    setDateRangeArray(args) {
      this.dataFormInitialize.dateRangeArray = [];
      let currentDate = dayjs(args.startDate);

      while (
        currentDate.isBefore(args.endDate) ||
        currentDate.isSame(args.endDate)
      ) {
        this.dataFormInitialize.dateRangeArray.push(
          currentDate.format("YYYY-MM-DD")
        );
        currentDate = currentDate.add(1, "day");
      }
    },
    async setSwitchIsValidation(args) {
      this.selectedRows = [];
      this.switchIsValidation = args;
      if (args) {
        let filter = this.userParametersList.find(
          (el) => el.key == "reportDetailFiltersByCollaborator"
        );
        if (filter) {
          if (JSON.parse(filter.value).dateRange) {
            this.setDates(JSON.parse(filter.value).dateRange);
          } else {
            this.setDates({
              startDate: this.dateRangeDefault[0],
              endDate: this.dateRangeDefault[1],
            });
          }
        } else {
          await this.setDates({
            startDate: this.dateRangeDefault[0],
            endDate: this.dateRangeDefault[1],
          });
        }
      }
    },
    changeViewDates(dates, refreshViewMode = false) {
      if (dates.force) {
        this.setDates(dates);
      } else if (refreshViewMode) {
        let startDate = this.dateRangeDefault[0];
        let endDate = this.dateRangeDefault[1];
        this.setDates({ startDate: startDate, endDate: endDate });
      } else {
        let startDate = this.dateRangeDefault[0];
        let endDate = this.dateRangeDefault[1];
        if (this.dateRangeDefault[0] > dates.startDate) {
          startDate = dates.startDate;
        }
        if (this.dateRangeDefault[1] < dates.endDate) {
          endDate = dates.endDate;
        }
        if (
          this.dateRangeDefault[0] !== startDate &&
          this.dateRangeDefault[1] !== endDate
        ) {
          this.setDates({ startDate: startDate, endDate: endDate });
        }
      }
    },
    changeCurrentView(view) {
      this.currentView = view;
      this.createOrUpdateUserParameter({
        label: "Vue des reportings planning par défaut",
        key: "reportsSchedulerCurrentView",
        value: view,
      });
    },
    setHeaderData() {
      let result = this.dataFiltered.some((data) => {
        const collaborator = this.collaboratorsList.find(
          (c) => c.id === data.collaboratorId
        );
        return collaborator ? collaborator.reportEntryMode !== 0 : false;
      });
      const staticColumns = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: true,
        },
        {
          type: "number",
          field: "id",
          headerText: "Id",
          width: 50,
          maxWidth: 50,
          visible: false,
          isPrimaryKey: true,
        },
        {
          type: "string",
          field: "status",
          headerText: "Statut",
          width: 100,
          maxWidth: 100,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          template: "statusTemplate",
          nature: "reportingStatus",
          edit: {
            type: "disabled",
            template: "statusTemplate",
          },
          filterTemplate: "statusFilterTemplate",
        },
        {
          type: "date",
          field: "reportDate",
          headerText: "Date",
          width: 95,
          maxWidth: 95,
          template: "dateYmdTemplate",
          allowFiltering: false,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          editType: "datepicker",
        },
        {
          type: "string",
          field: "collaboratorId",
          headerText: "Collaborateur",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown",
            name: "reportDropdownCollaboratorsList",
          },
          filterTemplate: "collaboratorFilterTemplate",
        },
        {
          type: "number",
          field: "payrollEventId",
          headerText: "Évènement",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          showFooterSum: false,
          edit: {
            source: this.payrollEventsList,
            fields: { text: "label", value: "id" },
            type: "dropdown",
            name: "dropdownPayrollEventsList",
          },
          filterTemplate: "payrollEventFilterTemplate",
        },
        {
          type: "number",
          field: "affairId",
          headerText: "Affaires",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.affairsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdowndependencies",
            name: "dropdownaffair",
            // target: "dropdownmission",
          },
          filterTemplate: "affairFilterTemplate",
        },
        {
          type: "number",
          field: "copyAffairId",
          headerText: "Zone de l'affaire",
          allowFiltering: false,
          allowSorting: false,
          allowEditing: false,
          showInColumnChooser: true,
          visible: false,
          valueAccessor: "dropdown",
          template: "affairZoneTemplate",
          availableExport: true,
          edit: {
            source: this.affairsList,
            fields: { text: "zone", value: "id" },
            type: "dropdowndependencies",
            name: "dropdownaffair",
            target: "dropdownmission",
            uid: "viewEditReportAffair",
          },
        },
        {
          type: "number",
          field: "missionId",
          headerText: "Tâche",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          showFooterSum: false,
          edit: {
            source: this.missionsList,
            fields: { text: "label", value: "id" },
            type: "dropdown",
            name: "dropdownPayrollEventsList",
          },
          filterTemplate: "missionFilterTemplate",
        },
        {
          type: "date",
          field: "startDate",
          headerText: "Début",
          template: "heureHmTemplate",
          width: 95,
          maxWidth: 95,
          textAlign: "center",
          allowFiltering: true,
          allowSorting: false,
          showInColumnChooser: true,
          visible: result,
          defaultValue: new Date(),
          edit: {
            type: "time",
            name: "reportStartDate",
          },
        },
        {
          type: "date",
          field: "endDate",
          headerText: "Fin",
          template: "heureHmTemplate",
          width: 95,
          maxWidth: 95,
          textAlign: "center",
          allowFiltering: true,
          allowSorting: false,
          showInColumnChooser: true,
          visible: result,
          defaultValue: new Date(),
          edit: {
            type: "time",
            name: "reportEndDate",
          },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "duration",
          headerText: "Durée réelle",
          allowAggregate: true,
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowFiltering: true,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: 1,
          edit: {
            params: {
              decimals: 2,
              min: 0,
              max: 24,
              placeholder: "Durée réelle",
              format: "##.## h",
              showSpinButton: false,
              step: 0.25,
            },
            type: "numericTextBox",
            name: "numericTextBoxDuration",
            target: "numericTextBoxDurationValue",
          },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "durationValue",
          headerText: "Durée valorisée",
          allowAggregate: true,
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowFiltering: true,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: 1,
          edit: {
            source: null,
            params: {
              decimals: 2,
              min: 0,
              max: 24,
              placeholder: "Durée valorisée",
              format: "##.## h",
              showSpinButton: false,
              step: 0.25,
            },
            type: "numericTextBox",
            name: "numericTextBoxDurationValue",
          },
        },
        {
          type: "string",
          field: "message",
          headerText: "Commentaire",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          edit: { type: "textarea", name: "message" },
          filter: { type: "Menu", operator: "contains" },
        },
      ];

      const allowanceTypeColumns = this.payAllowanceTypesList.map(
        (allowanceType) => {
          let columnConfig = {
            field: `allowance_${allowanceType.id}`,
            headerText: allowanceType.shortLabel,
            showInColumnChooser: true,
            textAlign: "center",
            visible: true,
            width: 100,
            maxWidth: 100,
          };

          if (allowanceType.quantificationType === 1) {
            columnConfig = {
              ...columnConfig,
              type: "number",
              allowFiltering: true,
              allowSorting: false,
              allowEditing: true,
              edit: {
                source: null,
                params: {
                  decimals: 1,
                  min: 0,
                  max: null,
                  placeholder: allowanceType.label,
                  format: "##.##",
                  showSpinButton: false,
                  step: 0.1,
                },
                type: "numericTextBox",
                name: "numericTextBoxAllowance_" + allowanceType.id,
              },
            };
          } else if (allowanceType.quantificationType === 0) {
            columnConfig = {
              ...columnConfig,
              type: "boolean",
              allowFiltering: true,
              allowSorting: false,
              allowEditing: true,
              valueAccessor: "boolean",
              editType: "booleanedit",
            };
          }
          return columnConfig;
        }
      );

      this.headerData = [...staticColumns, ...allowanceTypeColumns];

      if (this.hideDurationValue) {
        this.headerData.splice(
          this.headerData.findIndex((el) => el.field == "durationValue"),
          1
        );
      }
    },
    setCollaborators(collaboratorIds) {
      this.collaboratorIds = collaboratorIds;
    },
    async setDates(dates) {
      if (this.schedulerMode != "details") {
        if (dates.startDate) {
          let dateRange = {
            startDate: new Date(dates.startDate),
            endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
          };
          const collaborator = this.collaboratorsList.find(
            (el) => el.id == this.$store.getters["userCollaborator"].id
          );
          this.currentDate = new Date(
            dayjs(dates.startDate).startOf("month").format("YYYY-MM-DD")
          );
          this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
          await this.getReportDetails({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            collaboratorIds: collaborator ? [collaborator.id] : [],
          }).then(async (res) => {
            this.setHeaderData();
            if (res.length) {
              await this.setColumnsByObject(res[0], null, this);
              this.indexSearch++;
            }
            this.headerLoaded = true;
          });
        }
      }
    },
    updateSchedulerMode(val) {
      this.schedulerMode = val;
      this.selectedRows = [];
      if (val) {
        this.setDates({
          startDate: dayjs(this.dateRangeDefault[0])
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: dayjs(this.dateRangeDefault[0])
            .endOf("month")
            .format("YYYY-MM-DD"),
        });
      }
      if (this.schedulerMode == "list") {
        this.dateRange = true;
      } else {
        this.dateRange = false;
      }
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    onFilterTextBoxChanged(search) {
      if (this.schedulerMode == "list") {
        this.$refs.overviewgrid.onFilterTextBoxChanged(search);
      } else {
        this.searchText = search;
      }
    },
    clickAction() {
      this.$router.push({
        name: "new-report-team",
        params: {
          routeOrigine: "report/reports-by-collaborators",
        },
      });
    },
    async clickActionGroup(val) {
      let selectedRowIds = [];
      let obj = {};
      switch (val) {
        case 2:
          selectedRowIds = this.selectedRows
            .filter((el) => el.status <= 1)
            .map((row) => row.id);
          obj = selectedRowIds.reduce((acc, id) => {
            acc[id] = val;
            return acc;
          }, {});
          await this.updateReportsStatus({ data: obj });
          break;
        case 3:
        case 4:
          selectedRowIds = this.selectedRows
            .filter((el) => el.status <= 2)
            .map((row) => row.id);
          obj = selectedRowIds.reduce((acc, id) => {
            acc[id] = val;
            return acc;
          }, {});
          await this.updateReportsStatus({ data: obj });
          break;
        case 5:
          selectedRowIds = this.selectedRows
            .filter((el) => el.status != 3)
            .map((row) => row.id);
          await this.deleteReportDetail({ reportDetailIds: selectedRowIds });
          break;
      }
      this.selectedRows = [];
      if (this.$refs.overviewgrid) {
        this.$refs.overviewgrid.$refs.overviewgrid.clearSelection();
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
    },
    setSelectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "allowances") {
        this.showAllowances = !this.showAllowances;
      } else if (args.item.type == "durations") {
        this.showDurations = !this.showDurations;
      }
    },
    getCountDataByStatus(status) {
      if (this.schedulerMode == "list") {
        if (status == "all") {
          return this.dataOrigine.length;
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == status
          );
          return this.dataOrigine.filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          ).length;
        }
      } else if (this.schedulerMode == "details") {
        if (status == "all") {
          return this.reportDetailsMinifiedList.length;
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == status
          );
          return this.reportDetailsMinifiedList.filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          ).length;
        }
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (this.schedulerMode == "list") {
        if (status == "all") {
          this.dataOrigine.forEach((obj) => {
            sum += obj["duration"];
          });
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == status
          );
          this.dataOrigine
            .filter((elem) =>
              statusInfos.query
                ? statusInfos.query.includes(elem.status)
                : elem.status === status
            )
            .forEach((obj) => {
              sum += obj["duration"];
            });
        }
      } else if (this.schedulerMode == "details") {
        if (status == "all") {
          this.reportDetailsMinifiedList.forEach((obj) => {
            sum += obj["duration"];
          });
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == status
          );
          this.reportDetailsMinifiedList
            .filter((elem) =>
              statusInfos.query
                ? statusInfos.query.includes(elem.status)
                : elem.status === status
            )
            .forEach((obj) => {
              sum += obj["duration"];
            });
        }
      }

      return this.formatNumber(sum);
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
