<template lang="pug">
fieldset(:disabled="data.status == 3")
  v-style
    | .e-schedule-dialog .e-dlg-header-content {  {{(data.color ? 'background:'+data.color+'50  !important' : 'background:#f8f8f8  !important')}} }
  div(style="background-color: #f8f8f8;")
    div(:style="'background-color:'+(data.color ? data.color+'50' : '#f8f8f8')+' !important;'")
      b-row.content-scrollable-sticky.m-0.h-100.justify-content-center
        b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
          ul.d-flex
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'general' ? 'current' : ''" @click="tabActive = 'general'")
                span Général
            li(v-if="dataAllowances.length")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'allowances' ? 'current' : ''" @click="tabActive = 'allowances'")
                span Indemnités
  div 
    div.p-1(v-show="tabActive == 'general'")
      b-row.mb-50.mt-0
        b-col.py-0(cols='12')
          input(type="hidden" name="type" id="type" v-model="data.type")
          b-form-group(label='Type d\'évènement *' label-for='payrollEvent')
            ejs-dropdownlist(id="payrollEventId" name="payrollEvent" placeholder="Selectionnez un événement de paie" :itemTemplate="'itemTemplate'" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="payrollEventsList" :fields="{ text: 'label', value: 'id' }" v-model="data.payrollEventId" @change='changePayrollEvent' :showClearButton="true")
              template(v-slot:itemTemplate='{data}')
                .d-flex
                  div.pin-color(:style="'background-color:'+(data.color!=='' ? data.color : '#0c3571')+' !important'")
                  div {{data.label}}
              template(v-slot:footerTemplate)
                div.p-50
                  ejs-button(v-on:click.native="newEventType()" :content="'+ Nouveau type d’évènement'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
            div(id="payrollEventIdError")
      b-row.mb-50(v-show="(data.payrollEvent && data.payrollEvent.isValued)")
        b-col.py-0(cols='12')
          b-form-group(label='Client' label-for='companyId')
            ejs-dropdownlist( id="companyId" name="company" placeholder="Selectionnez un client" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="customersList" :fields="{ text: 'name', value: 'id' }" v-model="data.companyId" :showClearButton="true" @change='changeCompany')
              template(v-slot:footerTemplate)
                div.p-50
                  ejs-button(v-on:click.native="newCompany()" :content="'+ Nouveau client'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
      b-row.mb-50(v-show="(data.payrollEvent && data.payrollEvent.isValued)")
        b-col.py-0(cols='12')
          b-form-group(label='Affaire *' label-for='eventAffairId')
            ejs-dropdownlist(ref="dropdownlistAffairs" id="eventAffairId" name="affair" placeholder="Selectionnez une affaire" :itemTemplate="'itemTemplate'" :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="affairsList" :fields="{ text: 'displayLabel', value: 'id' }" v-model="data.affairId" :showClearButton="true" @change='changeAffair' @open="filterAffairsList")
              template(v-slot:itemTemplate='{data}')
                .d-flex(:id="'affair_'+data.id")
                  div.pin-color(:style="'background-color:'+(data.color!=='' ? data.color : '#0c3571')+' !important'")
                  div {{data.displayLabel}}
              template(v-slot:footerTemplate)
                div.p-50
                  ejs-button(v-on:click.native="newAffair()" :content="'+ Nouvelle affaire'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
            div(id="affairIdError")
      b-row.mb-50(v-show="(data.payrollEvent && data.payrollEvent.isValued) || data.companyId")
        b-col.py-0(cols='12')
          b-form-group(label='Mission' label-for='missionId')
            ejs-dropdowntree(v-if="loaded" :disabled="data.status == 3" id='missionId' popupHeight='300px' filterType='Contains' :allowFiltering='true' :fields="missionFields" v-model='data.missionId' @change='changeMission')
      b-row.mb-0(v-if="selectedCollaborator && selectedCollaborator.reportEntryMode == 1")
        b-col.py-0(md="6" style='padding-bottom:0px !important' )
          b-form-group(label='Heure de début *' label-for='startDate' style='flex:1')
              ejs-timepicker(v-if="loaded" locale='fr' id="startDate" :step="5" name="startDate" v-model="data.startDate" placeholder='Choisir une date' :format="'HH:mm'" :timeFormat="'HH:mm'" data-required-message="Renseigner la date" data-msg-containerid="startDateError" @change="changeDuration")
              div(id="startDateError")
        b-col.py-0(md="6" style='padding-bottom:0px !important')
          b-form-group(label='Heure de fin *' label-for='endDate' style='flex:1')
              ejs-timepicker(v-if="loaded" locale='fr' id="endDate" :step="5" name="endDate" v-model="data.endDate" placeholder='Choisir une date' :format="'HH:mm'" :timeFormat="'HH:mm'" data-required-message="Renseigner la date" data-msg-containerid="endDateError"  @change="changeDuration")
              div(id="endDateError")
      b-row.mb-0()
        b-col.py-0(md="6" style='padding-bottom:0px !important')
          b-form-group(label='Durée réelle *' label-for='duration' style='flex:1')
              ejs-numerictextbox(id="duration" :min="0" :max="maxInput" format="##.## h" v-model="data.duration" name="duration" @change="changeDuration" :disabled="selectedCollaborator && selectedCollaborator.reportEntryMode == 1" data-required-message="Renseigner la durée" data-msg-containerid="durationError")
              div(id="durationError")
        b-col.py-0(md="6" style='padding-bottom:0px !important')
          b-form-group(label='Durée valorisée' label-for='durationValue' style='flex:1')
              ejs-numerictextbox(id="durationValue" :min="0" format="##.## h" v-model="data.durationValue" name="durationValue" data-required-message="Renseigner la durée valorisée" data-msg-containerid="durationValueError")
              div(id="durationValueError")
      b-row.mb-0
        b-col.py-0(md="12" style='padding-bottom:0px !important')
          b-form-group(label='Commentaire' label-for='message' style='flex:1')
              ejs-textbox(id="message" v-model="data.message" name="message" :multiline="true")
        b-col.py-0(md="12" v-show="false" style='padding-bottom:0px !important')
          b-form-group(label='Date *' label-for='date' style='flex:1')
              ejs-datepicker(id="startDate" :format="'dd/MM/yyyy'" locale='fr' v-model="data.startDate" placeholder="JJ/MM/AAAA" )
        b-col.py-0(md="12" v-show="false" style='padding-bottom:0px !important')
          b-form-group(label='Collaborateur *' label-for='collaborator' style='flex:1')
              ejs-dropdownlist(id='collaboratorId' popupHeight='300px' filterType='Contains' :allowFiltering='true' :dataSource='collaboratorsList' :fields="{ text: 'displayLabel', value: 'id' }" v-model='data.collaboratorId')
    div.p-1(v-show="tabActive == 'allowances'")
      b-row.mb-50.mt-0(v-for="(allowance, indexAllowance) in dataAllowances" :key="indexAllowance")
        b-col.py-0(md="12" style='align-self: center; padding-bottom:0px !important')
          b-form-group(v-if="allowance.quantificationType == 0")
            b-form-checkbox.custom-control-primary(:id="'allowance'+dataValue+'_'+allowance.id" v-model="allowance.value") {{ allowance.label ? allowance.label : allowance.shortLabel }}
          b-form-group(v-else :label-for="'allowance'+dataValue+'_'+allowance.id" :label="allowance.label ? allowance.label : allowance.shortLabel")
            ejs-numerictextbox(:id="'allowance'+dataValue+'_'+allowance.id" :label="allowance.label" :placeholder="0" :format="allowance.quantificationType==2 ? '########.## €' : null" :showSpinButton="true" v-model="allowance.value")    
</template>

<script>
import { mapGetters, mapActions } from "vuex";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      tabActive: "general",
      loaded: true,
      startDate: null,
      endDate: null,
      dataAllowances: [],
      data: {
        startDate: null,
        endDate: null,
        duration: 0,
        durationValue: 0,
        message: "",
        collaboratorId: null,
        payrollEvent: null,
        payrollEventId: 0,
        affair: null,
        affairId: null,
        mission: null,
        missionId: null,
        company: null,
        companyId: null,
        color: "",
      },
      userColorAffair: false,
      totalHoursDay: 0,
      totalHoursWeek: 0,
      // collaborator: null,
    };
  },
  props: {
    dataValue: {
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      "eventTypesList",
      "companiesList",
      "affairsList",
      "payAllowanceTypesList",
      "collaboratorsList",
      "payrollEventsList",
      "userParametersList",
      "missionsList",
    ]),
    customersList() {
      return this.companiesList.filter(
        (company) => company.companyType.id == 5
      );
    },
    maxInput() {
      if (this.selectedCollaborator == null) return 0;
      const remainHoursDay =
        this.selectedCollaborator.contract.hoursDayLimit - this.totalHoursDay;
      const remainHoursWeek =
        this.selectedCollaborator.contract.hoursWeekLimit - this.totalHoursWeek;
      return Math.min(remainHoursWeek, remainHoursDay);
    },
    missionFields() {
      return {
        dataSource: this.missionsList.map((mission) => {
          return {
            ...mission,
            parentId: mission.parentId || null,
            hasChildren: this.$store.getters.missionsList.some(
              (m) => m.parentId == mission.id
            ),
          };
        }),
        value: "id",
        text: "label",
        parentValue: "parentId",
        hasChildren: "hasChildren",
      };
    },
    selectedCollaborator() {
      return this.data.collaboratorId
        ? this.collaboratorsList.find(
            (collaborator) => collaborator.id == this.data.collaboratorId
          )
        : null;
    },
  },
  async beforeMount() {
    this.data.startDate = this.data.startTime;
    // this.data.endDate = this.data.startTime;
    this.dataAllowances = this.payAllowanceTypesList.map((allowanceType) => {
      let allowanceValue = 0;
      if (
        this.data.allowances &&
        this.data.allowances.length > 0 &&
        this.data.allowances.find(
          (allowance) => allowance.id == allowanceType.id
        )
      ) {
        let dataAllowance = this.data.allowances.find(
          (allowance) => allowance.id == allowanceType.id
        );
        allowanceValue =
          allowanceType.quantificationType == 0
            ? dataAllowance.checkbox
            : allowanceType.quantificationType == 1
            ? dataAllowance.quantity
            : dataAllowance.amount;
      }
      return {
        ...allowanceType,
        value: allowanceValue,
      };
    });

    if (this.data.id) {
      this.loaded = false;
      if (this.data.status > -1) {
        const reportDetail = await this.getReportDetailById({
          reportDetailId: this.data.id,
        });
        let eventColor = this.payrollEventsList.find(
          (el) => el.id == reportDetail.payrollEventId
        );
        let eventAffair = this.affairsList.find(
          (el) => el.id == reportDetail.affairId
        );
        this.data = {
          ...this.data,
          ...reportDetail,
          payrollEvent: this.payrollEventsList.find(
            (el) => el.id == reportDetail.payrollEventId
          ),
          affair: this.affairsList.find((el) => el.id == reportDetail.affairId),
          missionId: reportDetail.missionId
            ? [reportDetail.missionId.toString()]
            : null,
          company:
            eventAffair && eventAffair.company ? eventAffair.company : null,
          companyId:
            eventAffair && eventAffair.company ? eventAffair.company.id : null,
          color:
            eventColor && eventColor.color ? eventColor.color.slice(0, 7) : "",
        };
      } else {
        let eventColor = this.payrollEventsList.find(
          (el) => el.id == this.data.payrollEventId
        );
        let eventAffair = this.affairsList.find(
          (el) => el.id == this.data.affairId
        );
        this.data = {
          ...this.data,
          payrollEvent: this.payrollEventsList.find(
            (el) => el.id == this.data.payrollEventId
          ),
          affairId: eventAffair ? eventAffair.id : null,
          affair: eventAffair,
          missionId: this.data.missionId
            ? [this.data.missionId.toString()]
            : null,
          company:
            eventAffair && eventAffair.company ? eventAffair.company : null,
          companyId:
            eventAffair && eventAffair.company ? eventAffair.company.id : null,
          color: eventColor ? eventColor.color.slice(0, 7) : "",
        };
      }

      this.loaded = true;
    }
  },
  async mounted() {
    if (this.data.startTime && this.data.endTime) {
      await this.getReportDetailsMinified({
        startDate: dayjs(this.data.startTime)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: dayjs(this.data.startTime)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        collaboratorIds: [this.selectedCollaborator.id],
        globalReport: false,
        updateState: false,
      }).then((res1) => {
        this.totalHoursDay = res1
          .filter(
            (el) =>
              this.payrollEventsList.find(
                (payrollEvent) => el.payrollEventId == payrollEvent.id
              )?.isValued == true
          )
          .reduce((total, objet) => {
            return total + (this.data.id == objet.id ? 0 : objet.duration);
          }, 0);

        this.getReportDetailsMinified({
          startDate: dayjs(this.data.startTime)
            .startOf("week")
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: dayjs(this.data.startTime)
            .endOf("week")
            .format("YYYY-MM-DD HH:mm:ss"),
          collaboratorIds: [this.selectedCollaborator.id],
          globalReport: false,
          updateState: false,
        }).then((res2) => {
          this.totalHoursWeek = res2
            .filter(
              (el) =>
                this.payrollEventsList.find(
                  (payrollEvent) => el.payrollEventId == payrollEvent.id
                )?.isValued == true
            )
            .reduce(
              (total, objet) =>
                total + (this.data.id == objet.id ? 0 : objet.duration),
              0
            );
        });
      });
    }
    if (this.data.id) {
      if (this.data.duration) {
        this.$nextTick(() => {
          const popupTimer = document.querySelector("#popupTimer");
          if (popupTimer) {
            popupTimer.innerHTML = this.data.duration + "h";
          }
        });
      }
      if (this.selectedCollaborator.reportEntryMode == 0) {
        this.$nextTick(() => {
          const popupTimeRanger = document.querySelector("#popupTimeRanger");
          if (popupTimeRanger) {
            popupTimeRanger.innerHTML = "";
          }
        });
      }
    }
  },
  methods: {
    ...mapActions(["getReportDetailById", "getReportDetailsMinified"]),
    filterAffairsList(args) {
      for (let index = 0; index < this.affairsList.length; index++) {
        const element = this.affairsList[index];
        if (
          !element.company ||
          (element.company.id !== this.data.companyId && this.data.companyId)
        ) {
          document.querySelector(
            "#affair_" + element.id
          ).parentElement.style.display = "none";
        } else {
          document.querySelector(
            "#affair_" + element.id
          ).parentElement.style.display = "flex";
        }
      }
    },
    changePayrollEvent(event) {
      if (event.itemData) {
        this.data.payrollEvent = event.itemData;
        this.data.payrollEventId = event.itemData.id;
        this.data.color = event.itemData.color
          ? event.itemData.color.slice(0, 7)
          : "";
        if (!event.itemData.isValued) {
          this.data.company = null;
          this.data.companyId = null;
          this.data.affair = null;
          this.data.affairId = null;
          this.data.mission = null;
          this.data.missionId = null;
        }
      }
      this.$forceUpdate();
    },
    changeCompany(event) {
      if (event.itemData) {
        if (this.data.company !== event.itemData && event.e) {
          this.data.mission = null;
          this.data.missionId = null;
        }
        let listFiltered = this.affairsList.filter(
          (el) => el.company && el.company.id == this.data.companyId
        );

        if (listFiltered.length == 1) {
          this.data.affair = listFiltered[0];
          this.data.affairId = listFiltered[0].id;
        } else if (
          !listFiltered.find((el) => el.id == this.data.affairId) ||
          !listFiltered.length > 1
        ) {
          this.data.affair = null;
          this.data.affairId = null;
        }
        this.data.company = event.itemData ? event.itemData : null;
      } else if (event.e) {
        this.data.company = null;
        this.data.companyId = null;
        this.data.affair = null;
        this.data.affairId = null;
        this.data.mission = null;
        this.data.missionId = null;
      }
    },
    changeAffair(event) {
      if (event.itemData) {
        this.data.affair = event.itemData ? event.itemData : null;
        this.data.companyId =
          event.itemData && event.itemData.company
            ? event.itemData.company.id
            : this.data.companyId
            ? this.data.companyId
            : null;
      } else if (event.e) {
        this.data.affair = null;
        this.data.affairId = null;
        this.data.mission = null;
        this.data.missionId = null;
      }
    },
    changeMission(event) {
      this.$forceUpdate();
    },
    changeDuration(event) {
      // 0 -> Mode Duration / 1 -> Mode Range
      if (
        this.selectedCollaborator &&
        this.selectedCollaborator.reportEntryMode == 1
      ) {
        if (!this.data.id) {
          this.data.startDate = dayjs(this.data.startDate)
            .set("date", dayjs(this.data.startTime).date())
            .set("month", dayjs(this.data.startTime).month())
            .set("year", dayjs(this.data.startTime).year())
            .toDate();
          this.data.endDate = dayjs(this.data.endDate)
            .set("date", dayjs(this.data.startTime).date())
            .set("month", dayjs(this.data.startTime).month())
            .set("year", dayjs(this.data.startTime).year())
            .toDate();
        }
        const diffOrigine =
          dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") /
          60;
        let newDiff = diffOrigine;

        if (diffOrigine < 0) {
          this.data.endDate = new Date(dayjs(this.data.endDate).add(1, "day"));
          newDiff =
            dayjs(this.data.endDate).diff(
              dayjs(this.data.startDate),
              "minutes"
            ) / 60;
        } else if (diffOrigine > 1440) {
          this.data.endDate = new Date(
            dayjs(this.data.endDate).subtract(1, "day")
          );
          newDiff =
            dayjs(this.data.endDate).diff(
              dayjs(this.data.startDate),
              "minutes"
            ) / 60;
        }
        this.data.duration = newDiff;
        this.data.durationValue = newDiff;
      } else {
        this.data.duration = event.value;
        this.data.durationValue = event.value;
      }

      this.data.duration = isNaN(this.data.duration) ? 0 : this.data.duration;
      const popupTimer = document.querySelector("#popupTimer");
      const logoTimer = document.querySelector("#timer");
      if (popupTimer) {
        popupTimer.innerHTML = this.data.duration + "h";
      }
      let findx = this.data.payrollEventId
        ? this.payrollEventsList.find((el) => el.id == this.data.payrollEventId)
        : 0;
      let findy = this.data.payrollEventId
        ? this.payrollEventsList.find((el) => el.id == this.data.payrollEventId)
        : 0;
      const x = this.totalHoursDay + (findx?.isValued ? this.data.duration : 0);
      const y =
        this.totalHoursWeek + (findy?.isValued ? this.data.duration : 0);
      if (this.selectedCollaborator) {
        // CONTRACT INFOS
        const hoursDayDefault =
          this.selectedCollaborator.contract.hoursDayDefault;
        const hoursDayWarning =
          this.selectedCollaborator.contract.hoursDayWarning;
        const hoursDayLimit = this.selectedCollaborator.contract.hoursDayLimit;
        const hoursWeekWarning =
          this.selectedCollaborator.contract.hoursWeekWarning;
        const hoursWeekLimit =
          this.selectedCollaborator.contract.hoursWeekLimit;
        if (y > hoursWeekLimit) {
          popupTimer.style.color = "red";
          logoTimer.style.color = "red";
          popupTimer.innerHTML =
            this.data.duration.toFixed(2) + "h" + " (> Limite de la semaine)";
        } else if (x > hoursDayLimit) {
          popupTimer.style.color = "red";
          logoTimer.style.color = "red";
          popupTimer.innerHTML =
            this.data.duration.toFixed(2) + "h" + " (> Limite du jour)";
        } else if (y >= hoursWeekWarning && y < hoursWeekLimit) {
          popupTimer.style.color = "orange";
          logoTimer.style.color = "orange";
          popupTimer.innerHTML =
            this.data.duration.toFixed(2) + "h" + " (>  Seuil de la semaine)";
        } else if (x >= hoursDayWarning && x < hoursDayLimit) {
          popupTimer.style.color = "orange";
          logoTimer.style.color = "orange";
          popupTimer.innerHTML =
            this.data.duration.toFixed(2) + "h" + " (> Seuil du jour)";
        } else {
          popupTimer.style.color = "black";
          logoTimer.style.color = "black";
          let remainingHours = hoursDayDefault - x;
          let formattedRemainingHours = Number.isInteger(remainingHours)
            ? remainingHours
            : remainingHours.toFixed(2);
          popupTimer.innerHTML =
            this.data.duration.toFixed(2) +
            "h" +
            " (Reste " +
            formattedRemainingHours +
            "h)";
        }

        if (y === hoursWeekLimit) {
          popupTimer.style.color = "red";
          logoTimer.style.color = "red";
          popupTimer.innerHTML =
            this.data.duration.toFixed(2) + "h" + " (Limite de la semaine)";
        } else if (x === hoursDayLimit) {
          popupTimer.style.color = "red";
          logoTimer.style.color = "red";
          popupTimer.innerHTML =
            this.data.duration.toFixed(2) + "h" + " (Limite du jour)";
        } else if (y === hoursWeekWarning) {
          popupTimer.style.color = "orange";
          logoTimer.style.color = "orange";
          popupTimer.innerHTML =
            this.data.duration.toFixed(2) + "h" + " (Seuil de la semaine)";
        } else if (x === hoursDayWarning) {
          popupTimer.style.color = "orange";
          logoTimer.style.color;
          popupTimer.innerHTML =
            this.data.duration.toFixed(2) + "h" + " (Seuil du jour)";
        }
      }
    },
    // changeTimeRange() {
    //   let diffOrigine = (
    //     dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") /
    //     60
    //   ).toFixed(2);
    //   let newDiff = diffOrigine;

    //   if (diffOrigine < 0) {
    //     this.data.endDate = new Date(dayjs(this.data.endDate).add(1, "day"));
    //     let newDiff = (
    //       dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") /
    //       60
    //     ).toFixed(2);
    //   } else if (diffOrigine > 1440) {
    //     this.data.endDate = new Date(
    //       dayjs(this.data.endDate).subtract(1, "day")
    //     );
    //     let newDiff = (
    //       dayjs(this.data.endDate).diff(dayjs(this.data.startDate), "minutes") /
    //       60
    //     ).toFixed(2);
    //   }

    //   // const popupTimer = document.querySelector("#popupTimer")
    //   // if(popupTimer){
    //   //   popupTimer.innerHTML = newDiff + "h"
    //   // }

    //   const popupTimeRanger = document.querySelector("#popupTimeRanger");
    //   if (popupTimeRanger) {
    //     if (this.selectedCollaborator.reportEntryMode == 1) {
    //       popupTimeRanger.innerHTML =
    //         "(" +
    //         dayjs(this.data.startDate).format("HH:mm") +
    //         " - " +
    //         dayjs(this.data.endDate).format("HH:mm") +
    //         ")";
    //     } else {
    //       popupTimeRanger.innerHTML = "";
    //     }
    //   }
    //   this.data.duration = newDiff;
    //   this.data.durationValue = newDiff;
    // },
    newEventType() {
      this.$router.push({ path: "/planning/settings/event-types" });
    },
    newCompany() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
    newAffair() {
      this.$router.push({
        name: "new-affair",
        params: {
          id: 0,
          companyId: this.data.company.id,
          title: "Nouvelle affaire",
          tips: "Nouvelle affaire",
          routeOrigine: "affairs",
        },
      });
    },
  },
};
</script>
<style lang="scss">
.e-schedule-dialog .e-dlg-header-content {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom: 0px !important;
}
.e-schedule-dialog .e-dlg-content {
  padding: 0px !important;
}
.e-schedule-dialog .e-footer-content {
  margin-top: 0px !important;
}
</style>
